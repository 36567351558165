<template>
  <component
    :is="tag"
    :to="to"
    :type="parsedType"
    :class="btnClass"
    :form="props.form"
  >
    <slot />
  </component>
</template>

<script setup lang="ts">
import {
  BtnSize,
  BtnColor,
  BtnVariant,
  BtnShape,
} from '~/components/btn/btn.constants';
import { computed } from 'vue';
import { RouteLocationRaw } from 'vue-router';
import { NuxtLink } from '#components';

type Props = {
  to?: RouteLocationRaw;
  type?: string;
  icon?: boolean;
  size?: BtnSize;
  variant?: BtnVariant;
  color?: BtnColor;
  shape?: BtnShape;
  form?: string;
};
const props = withDefaults(defineProps<Props>(), {
  size: BtnSize.MD,
  variant: BtnVariant.TEXT,
  shape: BtnShape.CIRCLE,
});

const btnClass = computed(() => {
  return [
    'btn',
    props.size,
    props.variant,
    props.color,
    props.shape,
    { icon: props.icon },
  ];
});

const tag = computed(() => {
  if (props.to !== undefined) {
    return NuxtLink;
  }

  return 'button';
});

const parsedType = computed<null | string>(() => {
  if (props.type) {
    return props.type;
  }
  if (props.to === undefined) {
    return 'button';
  }

  return null;
});
</script>

<style scoped>
.btn {
  @apply inline-flex items-center justify-center align-middle font-semibold px-6 transition duration-200 ease-in-out text-center;

  &:hover:not([disabled]) {
    background-image: linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1));
  }

  &:active:not([disabled]) {
    background-image: linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1));
    transform: scale(0.95);
  }

  &:focus-visible {
    outline: none;
    box-shadow: 0 0 0.6rem 0.06rem rgba(0, 0, 0, 0.1);
  }
}

.icon {
  @apply px-0;
}

.xs {
  @apply h-4 text-sm;
  &.icon {
    @apply w-4;
  }
}
.sm {
  @apply h-6 text-sm;
  &.icon {
    @apply w-6;
  }
}
.md {
  @apply h-8 text-sm;
  &.icon {
    @apply w-8;
  }
}
.lg {
  @apply h-10 text-sm;
  &.icon {
    @apply w-10;
  }
}

.text {
  @apply border-none;
}
.outlined {
  @apply border border-current;
}

.filled {
  &:focus-visible {
    outline: none;
    box-shadow: 0 0 0.3rem 0.06rem rgba(0, 0, 0, 0.3);
  }
}

.primary {
  @apply text-primary;

  &.filled {
    @apply text-on-primary bg-primary;
  }
}
.secondary {
  @apply text-secondary;

  &.filled {
    @apply text-on-secondary bg-secondary;
  }
}
.tertiary {
  @apply text-tertiary;

  &.filled {
    @apply text-on-tertiary bg-tertiary;
  }
}

.surface {
  @apply text-surface;

  &.filled {
    @apply text-on-surface bg-surface;
  }
}

.success {
  @apply text-success;

  &.filled {
    @apply text-[white] bg-success;
  }

  &.outlined {
    @apply text-success border-success;
  }
}
.btn:disabled {
  @apply cursor-default;

  &.filled {
    @apply opacity-50;
  }
}
.shape-rounded {
  @apply rounded;
}

.shape-circle {
  @apply rounded-full;
}
</style>
