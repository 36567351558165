
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as back_45to_45dashboard_45button7lltNeI9bykF1Tsxm3Drux_45gDhVxemRDXZQC19TYNgUMeta } from "/app/app/src/pages/_components/back-to-dashboard-button/back-to-dashboard-button.vue?macro=true";
import { default as indexq3qO3P_45ENb_voB00wYwCkeTrp15xlMAxiocw3uQlc_45kMeta } from "/app/app/src/pages/_components/back-to-dashboard-button/index.ts?macro=true";
import { default as BudgetSummaryYIOKjqxt7fLcXwYkyVEeIpPJmND8TF9tIWfeDeE2EzkMeta } from "/app/app/src/pages/_components/budget-summary/BudgetSummary.vue?macro=true";
import { default as indexgTzC7alBVQ7bG4PYvzNtYzkzgbtNAbykUjnvcUreKmgMeta } from "/app/app/src/pages/_components/budget-summary/index.ts?macro=true";
import { default as indexm2EI7pA_Mk_45GtTAvoqJWdz_z2DUZtatx8kiMANjpA2kMeta } from "/app/app/src/pages/_components/content-card/_components/launch-metric-chip/index.ts?macro=true";
import { default as launch_45metric_45chipLQQwVtMflumeYN1H0_45u7Ckv4bzIhEguBwbuwWTMxgfsMeta } from "/app/app/src/pages/_components/content-card/_components/launch-metric-chip/launch-metric-chip.vue?macro=true";
import { default as content_45cardvot5MNssp_0Vn6IrGzY38ZXT9W1abmBfJlcL1azPq_UMeta } from "/app/app/src/pages/_components/content-card/content-card.vue?macro=true";
import { default as indexr3hSZ4J_aKyrBYsV0Bp6PKFdV_ak2qnp4oOKaeQIACMMeta } from "/app/app/src/pages/_components/content-card/index.ts?macro=true";
import { default as complete_45content_45button2FcyerwN0i5_rj4uMYtuY7lwh4Xuflzh_D149_45dHOT4Meta } from "/app/app/src/pages/_components/content-details/_components/complete-content-button/complete-content-button.vue?macro=true";
import { default as index0u1kwywi0xeuVTMpSmajVUXnN_yrLp7wrojgbMOQb7gMeta } from "/app/app/src/pages/_components/content-details/_components/complete-content-button/index.ts?macro=true";
import { default as file_45previewGyLdviTHgTYkjbUNu8dPWxdmXPive6cfLhGIgjl7cfAMeta } from "/app/app/src/pages/_components/content-details/_components/files-area/file-preview/file-preview.vue?macro=true";
import { default as indexwIly_zNR28Y4zP9a51ZWwq6r_GXc7SwfIkuGzGhR5FQMeta } from "/app/app/src/pages/_components/content-details/_components/files-area/file-preview/index.ts?macro=true";
import { default as files_45areaFBTLJynJtlUlRCXXdIvNCX5tTVSKICDUCIot2wG7Y5cMeta } from "/app/app/src/pages/_components/content-details/_components/files-area/files-area.vue?macro=true";
import { default as indexclOHzCJOyPRC5XmslVFSw2COkicO36XdOPWHJUjmzv8Meta } from "/app/app/src/pages/_components/content-details/_components/files-area/index.ts?macro=true";
import { default as indexqJi4JMcSKOb8GuzT5lzcQXCTSvi4brhRy4azt0E_451nEMeta } from "/app/app/src/pages/_components/content-details/_components/launch-content-navigation/index.ts?macro=true";
import { default as launch_45content_45navigationqHHJ1n8i2bb6jQU1Wtg8jEzvSbrXiTBmLiH_0NNK1_YMeta } from "/app/app/src/pages/_components/content-details/_components/launch-content-navigation/launch-content-navigation.vue?macro=true";
import { default as indexAwFUgke57JEsi5wG0W_45oWdDYWfMTBr03np5MGeOorWYMeta } from "/app/app/src/pages/_components/content-details/_components/resource-area/index.ts?macro=true";
import { default as resource_45areawVzknHhKr4uQF6x06pHyi3rzCX7wMAQfOxMwJFHfDSoMeta } from "/app/app/src/pages/_components/content-details/_components/resource-area/resource-area.vue?macro=true";
import { default as index_4581k_45mpNgvQf_45kvO0Cc_gmzRAo3p6MwGjU2xLmqxdawMeta } from "/app/app/src/pages/_components/content-details/_components/send-content-modal/index.ts?macro=true";
import { default as send_45content_45modal0NWFR8SWP4WJjne4KdNY_IpA0HRisebbZQfbec9OHVoMeta } from "/app/app/src/pages/_components/content-details/_components/send-content-modal/send-content-modal.vue?macro=true";
import { default as carousel_45grid_45preview_45modalhJieZzxpNw5BmG8FCvH7hKKGC3GdztDh15Flfn0y4jMMeta } from "/app/app/src/pages/_components/content-details/_components/socials-area/_components/social-preview/_components/carousel-grid-preview-modal/carousel-grid-preview-modal.vue?macro=true";
import { default as indexLtsIBIyPhx_45Bn7lJi5aQr_45YTans_YqDQzm4OkQy33ccMeta } from "/app/app/src/pages/_components/content-details/_components/socials-area/_components/social-preview/_components/carousel-grid-preview-modal/index.ts?macro=true";
import { default as indexVi1haER7jCTkfCc0tRjpffTyC4EDJng5jx0CSSbPIM4Meta } from "/app/app/src/pages/_components/content-details/_components/socials-area/_components/social-preview/index.ts?macro=true";
import { default as social_45previewsDC3IuMu_hXOMUaOyIpjHbwgzQVKgsHty6uF4AD2b3MMeta } from "/app/app/src/pages/_components/content-details/_components/socials-area/_components/social-preview/social-preview.vue?macro=true";
import { default as indexHH04QGgateifvYRdtWqD2obB5g7R6pW39fbMk_mEaKkMeta } from "/app/app/src/pages/_components/content-details/_components/socials-area/index.ts?macro=true";
import { default as socials_45areaa2JafCilQAUB7CGgdIaf7h0OtB_v7Zv2QNRgjF3ZjoYMeta } from "/app/app/src/pages/_components/content-details/_components/socials-area/socials-area.vue?macro=true";
import { default as indexxPUpjrLb_g_oo6_45kMLXLSjjvFjNfvaTk4E7dKudovC4Meta } from "/app/app/src/pages/_components/content-details/_components/update-personalization-settings-banner/index.ts?macro=true";
import { default as update_45personalization_45settings_45bannerKsP_CjtFUrdVbRQF6rlrj6Y3uekkpQHzfVhw3k1rHt8Meta } from "/app/app/src/pages/_components/content-details/_components/update-personalization-settings-banner/update-personalization-settings-banner.vue?macro=true";
import { default as content_45detailsTjuAeqNyxxIsAKoC5RhQmLZlr8hm47f35TMI2P9xXs8Meta } from "/app/app/src/pages/_components/content-details/content-details.vue?macro=true";
import { default as content_46constants39FPy6jWik3GhCmoyn5Q6KeWAxjcbJtEsjizXxhfw1kMeta } from "/app/app/src/pages/_components/content-details/content.constants.ts?macro=true";
import { default as content_46utilshESnhmjFZi6_45_iK0Y6_Aev8BCjrEC0OmPMulTGHsP2YMeta } from "/app/app/src/pages/_components/content-details/content.utils.ts?macro=true";
import { default as indexHHW2wL4nHjcX06DXBZiK_45xz_t6pKdkuQRbOurWHKEIUMeta } from "/app/app/src/pages/_components/content-details/index.ts?macro=true";
import { default as description7GukFS83KSLZ4KwFoK4xOSZGGAmIAtDerTQ4W_45RlDHoMeta } from "/app/app/src/pages/_components/description/description.vue?macro=true";
import { default as indexw_45ANAbITmV0_453rS0RBObWEJfj__zqfZM5aVoVnlx2DsMeta } from "/app/app/src/pages/_components/description/index.ts?macro=true";
import { default as empty_45listPfxuMKhp1A5QRXAXk08eH4eTTvOuTRpbWfxaDn_45FcvIMeta } from "/app/app/src/pages/_components/empty-list/empty-list.vue?macro=true";
import { default as indexGAX9Ip3dycMwvBt11S7PXbBa61HQAPSeO_02LMDyTMMMeta } from "/app/app/src/pages/_components/empty-list/index.ts?macro=true";
import { default as index6t_45h4V4awsxGyngZhzNpBeZ7JzFCzbNsCrGABTz8BOAMeta } from "/app/app/src/pages/_components/hub-dashboard/_components/launch-hub-badge/index.ts?macro=true";
import { default as launch_45hub_45badge2SzIZeb9U9DGjboOnyY4WXh9q8SrgVLiJbBsGomstvAMeta } from "/app/app/src/pages/_components/hub-dashboard/_components/launch-hub-badge/launch-hub-badge.vue?macro=true";
import { default as hub_45dashboard_45V8YQ21K2jhHrQiV9yLa7o_moApH_3ccvixyy9sGrOwMeta } from "/app/app/src/pages/_components/hub-dashboard/hub-dashboard.vue?macro=true";
import { default as indexzctvmpnurvFwnza25hhZ6yGcVwesAfJeLVw_45CoYeSSoMeta } from "/app/app/src/pages/_components/hub-dashboard/index.ts?macro=true";
import { default as accept_45terms_45modal8IL8n4tWRSF3VS3AEU2rjjyxao13chvw3g7xv9Y9owQMeta } from "/app/app/src/pages/_components/hub-details/_components/accept-terms-modal/accept-terms-modal.vue?macro=true";
import { default as indexY3asPLyvLM8ZwtzOmCSa4MMbT6kpsP_45m_I7GAhJvekIMeta } from "/app/app/src/pages/_components/hub-details/_components/accept-terms-modal/index.ts?macro=true";
import { default as completed_45contents_45bannerI3mGq2zfeWBHT2_SVjkZYLonEW9x94ffdaJMzWxWlqkMeta } from "/app/app/src/pages/_components/hub-details/_components/completed-contents-banner/completed-contents-banner.vue?macro=true";
import { default as indexcrvxfHjQbQJsm2FF_457vIObYa0bIFAar38HoMP63VyA8Meta } from "/app/app/src/pages/_components/hub-details/_components/completed-contents-banner/index.ts?macro=true";
import { default as desktop_45filters8LiXfetENa1ckKxT3_qYkFaJjaVLatus2NdHpEy0PQgMeta } from "/app/app/src/pages/_components/hub-details/_components/filters-modal/_components/desktop-filters/desktop-filters.vue?macro=true";
import { default as indexegLT74fqJFPZ_451i5KZtE8MNqQu5zXHk_45yGiljxjOLIcMeta } from "/app/app/src/pages/_components/hub-details/_components/filters-modal/_components/desktop-filters/index.ts?macro=true";
import { default as filter_45btnLc4mF__tOnPPkPJYe8SNP4St_45K7pC5ImTrCm9ubwJawMeta } from "/app/app/src/pages/_components/hub-details/_components/filters-modal/_components/filter-btn/filter-btn.vue?macro=true";
import { default as indexy9WvZ9_iYZZDzdsd4dMXTdORd2b3k99WbTliZ2XM_jEMeta } from "/app/app/src/pages/_components/hub-details/_components/filters-modal/_components/filter-btn/index.ts?macro=true";
import { default as filter_45headerkEpK7psZ_45CCVt1jvSrVLYnj_4545lw0PSarZLsW4Kb9XQMeta } from "/app/app/src/pages/_components/hub-details/_components/filters-modal/_components/filter-header/filter-header.vue?macro=true";
import { default as index8xGWoTPKDLmnyeDua9lRBbVMnDV_45UzhHpp_IlMSWIf8Meta } from "/app/app/src/pages/_components/hub-details/_components/filters-modal/_components/filter-header/index.ts?macro=true";
import { default as active_45filtersfKPrQo_6hbXiu2gT9szGpJ_45NbslYC37erd8JJhIBlgwMeta } from "/app/app/src/pages/_components/hub-details/_components/filters-modal/_components/mobile-filters/_components/active-filters/active-filters.vue?macro=true";
import { default as indexTQonklpXgzw8iwfHlG_OjlC10l1hKgNTAfoCFZrd_45fYMeta } from "/app/app/src/pages/_components/hub-details/_components/filters-modal/_components/mobile-filters/_components/active-filters/index.ts?macro=true";
import { default as indexXH8nBYUvBhFh4Y_45t0zmcqSOChY6fp1IBaIAGMybt7iwMeta } from "/app/app/src/pages/_components/hub-details/_components/filters-modal/_components/mobile-filters/index.ts?macro=true";
import { default as mobile_45filtersAeVJm8tdKnlUnrNJMSblP_45_YJTIdtDBaNQDxeyjy4xEMeta } from "/app/app/src/pages/_components/hub-details/_components/filters-modal/_components/mobile-filters/mobile-filters.vue?macro=true";
import { default as indexTIgaOknNk7hsCC9Mw841n45b6fYKQuFcaHGv2KqoE_gMeta } from "/app/app/src/pages/_components/hub-details/_components/filters-modal/_constants/index.ts?macro=true";
import { default as filters_45modalqV4r7N6K7IaJzemv38TL75S6_45TnhKUtnt9ePeWFdNvYMeta } from "/app/app/src/pages/_components/hub-details/_components/filters-modal/filters-modal.vue?macro=true";
import { default as indexbwmSftbKEJoX6AlHXW1RvoOehv5N6kn35QUFfg_45eAbUMeta } from "/app/app/src/pages/_components/hub-details/_components/filters-modal/index.ts?macro=true";
import { default as filters_45wrapperYbpjBkj7zeZQTkiScUYZx71jewCG37xTeisSI8_45mVAcMeta } from "/app/app/src/pages/_components/hub-details/_components/filters-wrapper/filters-wrapper.vue?macro=true";
import { default as indexqPr99z71ys2GBemFm1mifEFO5egG0HvCOKxWZpbVsaoMeta } from "/app/app/src/pages/_components/hub-details/_components/filters-wrapper/index.ts?macro=true";
import { default as hub_45logot11fAqTO6ubluRdP1BcY4luCqV7adyrXzfySadaE9l4Meta } from "/app/app/src/pages/_components/hub-details/_components/hub-homepage/_components/hub-logo/hub-logo.vue?macro=true";
import { default as HubNextContentRrCgeC7r0j7v0TKnuPvJcsPOvciuq3s4C0ZQ3wg0Nu0Meta } from "/app/app/src/pages/_components/hub-details/_components/hub-homepage/_components/hub-next-content/HubNextContent.vue?macro=true";
import { default as RemainingActionsCounterM8LOOJA3XtoQevsDuGoeBg4MXtlrH0EXdfM8toz3NgoMeta } from "/app/app/src/pages/_components/hub-details/_components/hub-homepage/_components/remaining-actions-counter/RemainingActionsCounter.vue?macro=true";
import { default as UpcomingStepsEPs92zsnnIberir3YKIp7mKtdin7qM_d3ijfAVq37UgMeta } from "/app/app/src/pages/_components/hub-details/_components/hub-homepage/_components/upcoming-steps/UpcomingSteps.vue?macro=true";
import { default as HubHomepagehL1CkVdCVmLr7LkI7_45ZjDXHFtRpj40DeLFfyB_nlx9MMeta } from "/app/app/src/pages/_components/hub-details/_components/hub-homepage/HubHomepage.vue?macro=true";
import { default as hub_45infoX5UDX0DW17TQ3U3TrtGNY8f8qqP5IzmBliSjDxkpjh8Meta } from "/app/app/src/pages/_components/hub-details/_components/hub-info/hub-info.vue?macro=true";
import { default as indexFb3_FqI24c9_0aEGdbt1_FXBehludImME2LIyk1AjCMMeta } from "/app/app/src/pages/_components/hub-details/_components/hub-info/index.ts?macro=true";
import { default as hub_45logoCLsBYCJL1y83cncx3j9gyhwKgMDfWOQ3mgKj8vM_4540sMeta } from "/app/app/src/pages/_components/hub-details/_components/launchhub-homepage/_components/hub-logo/hub-logo.vue?macro=true";
import { default as HubNextContentf3cZYyLqsyllWE3tblUdQSsX6Vd428JvTXPAXJpflHQMeta } from "/app/app/src/pages/_components/hub-details/_components/launchhub-homepage/_components/hub-next-content/HubNextContent.vue?macro=true";
import { default as RemainingActionsCounteraoZpErvMpkH00qFM72pNTfBA_emEXqVadiGKBAGpyxcMeta } from "/app/app/src/pages/_components/hub-details/_components/launchhub-homepage/_components/remaining-actions-counter/RemainingActionsCounter.vue?macro=true";
import { default as UpcomingSteps3Auy4bnQ0jxkULx5WhYt3V8nxqeSAhV5Aj9HcjepS_450Meta } from "/app/app/src/pages/_components/hub-details/_components/launchhub-homepage/_components/upcoming-steps/UpcomingSteps.vue?macro=true";
import { default as LaunchHubHomepage36c5aeP1mzmeYvM_rTcTyaamJCgIwkdLn7uAY0091ZEMeta } from "/app/app/src/pages/_components/hub-details/_components/launchhub-homepage/LaunchHubHomepage.vue?macro=true";
import { default as use_45accepted_45hubs_45termsNRIm17w2qzXsLld9Paza_456PlLZccsH7fW4vE_45oWN4VwMeta } from "/app/app/src/pages/_components/hub-details/_composables/use-accepted-hubs-terms.ts?macro=true";
import { default as index4k8asT3S414v874SqcH46txhcnkxmWLCyGmWePWSEvQMeta } from "/app/app/src/pages/_components/hub-details/_types/index.ts?macro=true";
import { default as hub_45detailsE_45OVbzSUxIUNynJ6zvGHBqtJMTI27z8A_ZPQn0z_45UKAMeta } from "/app/app/src/pages/_components/hub-details/hub-details.vue?macro=true";
import { default as indexdgrvl7TBu6KDUl1pORtx1LRPI9kUMaFy8gadkHt6hWoMeta } from "/app/app/src/pages/_components/hub-details/index.ts?macro=true";
import { default as hub_45header4_45FdJsJpPaKlHiOTi7SGEml96kPOwOI5aNPSAxOFh_45UMeta } from "/app/app/src/pages/_components/hub-header/hub-header.vue?macro=true";
import { default as index7rshYhCKjddmJfPmVWtFFGp_45TNw9xBbnIgvMN_45YOXAEMeta } from "/app/app/src/pages/_components/hub-header/index.ts?macro=true";
import { default as indexz4IJcL4o4P0x4xDqDYL4K4zvnhOjzhmfx580uTLZ_45mEMeta } from "/app/app/src/pages/_components/onboarding/_components/onboarding-banner/index.ts?macro=true";
import { default as onboarding_45bannermZfakIXZi_VmmlJ0_45vhSA41IpkIh8IrHsrJKvfkUYuMMeta } from "/app/app/src/pages/_components/onboarding/_components/onboarding-banner/onboarding-banner.vue?macro=true";
import { default as indexxm4FEa3fQGuWHytIaXK6H_tIaC_tfxO_45jbF4JSkI_tcMeta } from "/app/app/src/pages/_components/onboarding/_components/onboarding-error/index.tsx?macro=true";
import { default as onboarding_45error_46constantsta4po3le3c_WcDeervZ3rJ4yDxNMGDnMJ9m36A91rLsMeta } from "/app/app/src/pages/_components/onboarding/_components/onboarding-error/onboarding-error.constants.ts?macro=true";
import { default as onboarding_45errorCES3QZ76x_mo7HXIMaNy2mtAhajI5KocWLnFwhR2R_gMeta } from "/app/app/src/pages/_components/onboarding/_components/onboarding-error/onboarding-error.vue?macro=true";
import { default as revanceSKF88JD3VpozHGnP3_VbFGM8WM8l4Ia7Sqw46XmuWiAMeta } from "/app/app/src/pages/_components/onboarding/_components/onboarding-get-started/_components/revance/revance.vue?macro=true";
import { default as rxsightL7up50glgjTey6AG_T8MQyfmgGaVKSbeTFli_Fe8BEsMeta } from "/app/app/src/pages/_components/onboarding/_components/onboarding-get-started/_components/rxsight/rxsight.vue?macro=true";
import { default as indexZnjVH2S2cBGPRhLhHGVV8_453EKDbSeOs2JNk5Cg_45lQmoMeta } from "/app/app/src/pages/_components/onboarding/_components/onboarding-get-started/index.ts?macro=true";
import { default as onboarding_45get_45startedSHBb7_45cW8BfSh8iBCElccxRT5bSM9i0awU2YMqd4sXcMeta } from "/app/app/src/pages/_components/onboarding/_components/onboarding-get-started/onboarding-get-started.vue?macro=true";
import { default as get_45started_45emitEM_45dnfp4C2vHtTD5bg2WCSELcmei7IeRVYoP1ExLuqoMeta } from "/app/app/src/pages/_components/onboarding/_components/onboarding-get-started/types/get-started-emit.ts?macro=true";
import { default as get_45started_45props4yLrC60cn2dhDihnCRxULJ4D3T0eSVGL1w3Nor3__45oMMeta } from "/app/app/src/pages/_components/onboarding/_components/onboarding-get-started/types/get-started-props.ts?macro=true";
import { default as indexZRCtYOKsjMc_4571M9VfTyZKEvo92ZQbZpfxX44R77pWgMeta } from "/app/app/src/pages/_components/onboarding/_components/onboarding-step-footer/index.ts?macro=true";
import { default as onboarding_45step_45footer0Js_y5id_T0DQDLrcruQeJgIhH6JarKU1qUikPXc9w8Meta } from "/app/app/src/pages/_components/onboarding/_components/onboarding-step-footer/onboarding-step-footer.vue?macro=true";
import { default as indexsn761NTcbE933kbBBxFqC8MefMY6CKwfYK8KoAtETiYMeta } from "/app/app/src/pages/_components/onboarding/_components/program-summary/_components/kickoff-meeting/index.ts?macro=true";
import { default as kickoff_45meetingmIvQc581DB4P4_S3TUPdEAHTIKTJs6DBy0oJIB2nNXsMeta } from "/app/app/src/pages/_components/onboarding/_components/program-summary/_components/kickoff-meeting/kickoff-meeting.vue?macro=true";
import { default as index7UgajWJiBplhfehWUtD_45rlZAJ9xD3WBvB4_45i43hYsVEMeta } from "/app/app/src/pages/_components/onboarding/_components/program-summary/_components/thank-you-block/index.tsx?macro=true";
import { default as thank_45you_45blockxPL5DK4370IOCeATY1AiYzRZr0Tl2BTyaAy9Cb94LSoMeta } from "/app/app/src/pages/_components/onboarding/_components/program-summary/_components/thank-you-block/thank-you-block.vue?macro=true";
import { default as indexvaCdcUrFhtWzS_lZq91yMVZnLxY1gpiJJRvvK4Rvn7IMeta } from "/app/app/src/pages/_components/onboarding/_components/program-summary/index.ts?macro=true";
import { default as program_45summaryyYyft6SJ5MKlkDNOKWA6zKXYMc7jBT9Q8eadt4anDtAMeta } from "/app/app/src/pages/_components/onboarding/_components/program-summary/program-summary.vue?macro=true";
import { default as use_45onboarding_45configiCp_45fN3j7s_YNwOJAnGN0nZp_o7TPv1XcfvHBDUh7pEMeta } from "/app/app/src/pages/_components/onboarding/_composable/use-onboarding-config.ts?macro=true";
import { default as use_45tier_45datarr5X7KsMheJy8wEQk0GvgG9Ab_vu3rr_45MOe059SAhHcMeta } from "/app/app/src/pages/_components/onboarding/_composable/use-tier-data.ts?macro=true";
import { default as ad_45selection_45step4UDuPfR3UZXjdeTqlEvnMcw2HnUX_va_c_gC2brG7pkMeta } from "/app/app/src/pages/_components/onboarding/_steps/ad-selection-step/ad-selection-step.vue?macro=true";
import { default as ad_45selection_46constantsI_45FG1dBNsRjLKVDEY7tk2KHGdkMyLm_DUWi8ukwzRYwMeta } from "/app/app/src/pages/_components/onboarding/_steps/ad-selection-step/ad-selection.constants.ts?macro=true";
import { default as indexIk_45aQ40d8bfXOfWlXUXfNITHkLDg6yggdXfajTA8JN4Meta } from "/app/app/src/pages/_components/onboarding/_steps/ad-selection-step/index.ts?macro=true";
import { default as authorize_45advertising_45stepEsOfcJ80cjEK8Y2TQiZIFkzNUkQwJXWWf3szhQXBV6gMeta } from "/app/app/src/pages/_components/onboarding/_steps/authorize-advertising-step/authorize-advertising-step.vue?macro=true";
import { default as authorize_45advertising_46constantsBdW2R7NMQ9IZhUOzUDFSzBFcM6Cvv7to9aGvUcFq0kkMeta } from "/app/app/src/pages/_components/onboarding/_steps/authorize-advertising-step/authorize-advertising.constants.ts?macro=true";
import { default as indexkEj994YvUzlVc_NpW0dx2DhjbfZLjgpxHq_45u4rMhxUMMeta } from "/app/app/src/pages/_components/onboarding/_steps/authorize-advertising-step/index.ts?macro=true";
import { default as audience_45targetingoKk9XnZEqWSbQAywQG37ymkFIMh76jVNZwQc0fJsFoAMeta } from "/app/app/src/pages/_components/onboarding/_steps/campagin-targeting-step/_components/audience-targeting/audience-targeting.vue?macro=true";
import { default as indexqTATgWSeEQTGXSBM8JI1f_45OPSp80K8D2AioxKxMuReEMeta } from "/app/app/src/pages/_components/onboarding/_steps/campagin-targeting-step/_components/audience-targeting/index.ts?macro=true";
import { default as average_45distanceq7J5o70u_458Dx0SONs8lotTgMGXujd36zi1T9M0Aej8MMeta } from "/app/app/src/pages/_components/onboarding/_steps/campagin-targeting-step/_components/average-distance/average-distance.vue?macro=true";
import { default as index3g2gIygoiwHYlqinnDNeIcJeeby_p_FbZIwY6GjSO0YMeta } from "/app/app/src/pages/_components/onboarding/_steps/campagin-targeting-step/_components/average-distance/index.ts?macro=true";
import { default as indexnFOb2_P1fwcRM7tItWTNvUyPyiLUKe2EhihNtkVFWMEMeta } from "/app/app/src/pages/_components/onboarding/_steps/campagin-targeting-step/_components/interests/index.ts?macro=true";
import { default as interestsIq_45L303UraMMo3LcbPHxHbZOrq_45WCOaPgBMeAuRGV3QMeta } from "/app/app/src/pages/_components/onboarding/_steps/campagin-targeting-step/_components/interests/interests.vue?macro=true";
import { default as campaign_45targeting_45stepuxmtqCSSYVkSJQoC_45IllHUCOTfNO5dzxB2ntcTdMpsYMeta } from "/app/app/src/pages/_components/onboarding/_steps/campagin-targeting-step/campaign-targeting-step.vue?macro=true";
import { default as indexjS41KrIDRhodgLc_45iohrPDk6btZVKj_45A9AolZ5OAsNIMeta } from "/app/app/src/pages/_components/onboarding/_steps/campagin-targeting-step/index.ts?macro=true";
import { default as address_45input6r4JXGOCZNIHGU359qu35honzonuINtWcvqkFCgz8EgMeta } from "/app/app/src/pages/_components/onboarding/_steps/data-collection-step/_components/address-input/address-input.vue?macro=true";
import { default as index5gBJ2TI7P7pfVXOGiL1DU_45A9TlhvhmIcyZIZzjcoKWQMeta } from "/app/app/src/pages/_components/onboarding/_steps/data-collection-step/_components/address-input/index.ts?macro=true";
import { default as indexVPZTaUm3D_45OTu0AUhUY_b7HBICykzTIXyuWTbjPBV6oMeta } from "/app/app/src/pages/_components/onboarding/_steps/data-collection-step/_constants/index.ts?macro=true";
import { default as data_45collection_45stepGD6KxtPjP9lZtRO18oStZSPXPEL7gMJH1MCvPP_r3VEMeta } from "/app/app/src/pages/_components/onboarding/_steps/data-collection-step/data-collection-step.vue?macro=true";
import { default as index0qMLshsL5dZbIYLNyQdKSW9D6B5V3vtq2MPDzYiH6rQMeta } from "/app/app/src/pages/_components/onboarding/_steps/data-collection-step/index.ts?macro=true";
import { default as indexLtUnph8uNS_76_45_ESyiv_pFib57WGfj1x1jT_45DRXZf8Meta } from "/app/app/src/pages/_components/onboarding/_steps/funding-step/components/payment/index.ts?macro=true";
import { default as Paymento7q0nVH_45y5jM21a_45Dew9CebqKcKBzaHS3EvcnHlcjpMMeta } from "/app/app/src/pages/_components/onboarding/_steps/funding-step/components/payment/Payment.vue?macro=true";
import { default as funding_45step01RSZ4szwE6QfH7QQfCAanpeSsGFPbebCAn2Gi_cvA0Meta } from "/app/app/src/pages/_components/onboarding/_steps/funding-step/funding-step.vue?macro=true";
import { default as indexRh9O4zdXt8v_45pO8lHOtHf70D5lbQxw_45PDcdKCiEj5z4Meta } from "/app/app/src/pages/_components/onboarding/_steps/funding-step/index.ts?macro=true";
import { default as index4bj8CGlJ07etTmcqK0eBjByAXzp5_Sqgz3rStsWtJ74Meta } from "/app/app/src/pages/_components/onboarding/_steps/landing-page-step/index.ts?macro=true";
import { default as landing_45page_45stepsmbzz29BipCPnnUNs7wFiDdk3qcnLtxWwnuImqYfpO0Meta } from "/app/app/src/pages/_components/onboarding/_steps/landing-page-step/landing-page-step.vue?macro=true";
import { default as index_45DlFIe2fakFM93sAjcnmHxE1sexTpH9Yptu55faXmcQMeta } from "/app/app/src/pages/_components/onboarding/_steps/logo-step/index.ts?macro=true";
import { default as logo_45stepaCRLYXYQaOqK1yJ459Zce663iZ8jd4pbkLSxf5IzHfYMeta } from "/app/app/src/pages/_components/onboarding/_steps/logo-step/logo-step.vue?macro=true";
import { default as indexrisSTpMfzd3H8t8rcmDsSSq_Lv_45SBX1IAgTGaBht2V8Meta } from "/app/app/src/pages/_components/onboarding/_utils/index.ts?macro=true";
import { default as indexPUWGZdXB5yDdHj90KqC_45ojbuuc3JNJks49YdbZAyhoIMeta } from "/app/app/src/pages/_components/onboarding/index.ts?macro=true";
import { default as onboarding_46constants_45pD1Ju2QwBnhfmfDkE5FkyJLozaGyEQ9Nsdaa4x49AMMeta } from "/app/app/src/pages/_components/onboarding/onboarding.constants.ts?macro=true";
import { default as onboardingZCPs7vKW0pOZBKfbp01ix4REYbwq_oH3zoulhQ85UisMeta } from "/app/app/src/pages/_components/onboarding/onboarding.vue?macro=true";
import { default as indexd5nOMw3bYLy23ogmeN_45bETHoXkwP5sdVt91_BjhVnB8Meta } from "/app/app/src/pages/_components/organization-dashboard/index.ts?macro=true";
import { default as organization_45dashboardY2GqXdB3tHKUkiMg3Czf_453IhP9Ty8ox0jjgG_WODLuEMeta } from "/app/app/src/pages/_components/organization-dashboard/organization-dashboard.vue?macro=true";
import { default as indexWPk6fSr1_45YwdMwOcE172xAnf7kCWfrtrtuj7aCPUr0sMeta } from "/app/app/src/pages/_components/send-modal/index.ts?macro=true";
import { default as send_45modal_1rmWzmYdNLdb66QOKClzYUgcpjPsns7jgKiyjEplREMeta } from "/app/app/src/pages/_components/send-modal/send-modal.vue?macro=true";
import { default as indexrhPC1_45FHYxfmA9W11UNEO0I63apCxI2htQIGWZcUjo4Meta } from "/app/app/src/pages/_components/social-type-info/index.ts?macro=true";
import { default as social_45type_45info_46constants7fjnzCNu5Hq_45wSlNwmleUIXBHT2XMIrog55O_45Yn5TPEMeta } from "/app/app/src/pages/_components/social-type-info/social-type-info.constants.ts?macro=true";
import { default as social_45type_45infoTZfETUJ1LMUWZqIZyf7N4_jLDerJYk5o_45x_xYTKHbtEMeta } from "/app/app/src/pages/_components/social-type-info/social-type-info.vue?macro=true";
import { default as indexkGcMrAMXIomNotm145N3yPfwpQZtwTCM6xznqtQpZ0YMeta } from "/app/app/src/pages/_components/variable-chip/index.ts?macro=true";
import { default as variable_45chipqL5_tn2vBnguTTU8mJp8jnKFPHoY_8A9l2uijsXXIooMeta } from "/app/app/src/pages/_components/variable-chip/variable-chip.vue?macro=true";
import { default as index8t_t2OZB6l1OxpJhILahAuyjmXnDg0aICmd4b_45u6ZF4Meta } from "/app/app/src/pages/_components/variables-modal/index.ts?macro=true";
import { default as variables_45modal4Qp_AVtWshBFVb4W9rLNu7LQb_JFk5goyWNrpc299XwMeta } from "/app/app/src/pages/_components/variables-modal/variables-modal.vue?macro=true";
import { default as indexJl6rUMf0eIliyVgD3g5UWM4sun8J5x6Xh8x7HSuhrTMMeta } from "/app/app/src/pages/[hub-slug]/[content-slug]/index.vue?macro=true";
import { default as indexhxcdHq449pQFd7zmCzbpmjTkPn7BjTj7XT5bswkjD1gMeta } from "/app/app/src/pages/[hub-slug]/index.vue?macro=true";
import { default as external_45auth_45providers_45BgByvZ4atTcqXE26luRezoeO1W3Zk3_45xZR_453JzbE3UMeta } from "/app/app/src/pages/auth/_components/external-auth-providers/external-auth-providers.vue?macro=true";
import { default as indexMw5Hi9R8DBLsMyT83JVeopXu6kVD4xtrCFE6S_4FG_wMeta } from "/app/app/src/pages/auth/_components/external-auth-providers/index.ts?macro=true";
import { default as indexP5mmnFVoZ_453mL53hbYft2KRuR9gtuHX5P5ud1EForVYMeta } from "/app/app/src/pages/auth/facebook/index.vue?macro=true";
import { default as indexoovepY_quEKVJOiXuVio_45_45jbn3rnjLWYbhYwRxTnoBIMeta } from "/app/app/src/pages/auth/instagram/index.vue?macro=true";
import { default as indexkYZWQpNmOd487X0U9BAEhL9b8qflduvbwljTA08u_45q4Meta } from "/app/app/src/pages/auth/linkedin/index.vue?macro=true";
import { default as enter_45passwordokYmzPJqmxnz5YZFMcsxLkJcX3oi5v5vLtUt3qcEfuUMeta } from "/app/app/src/pages/auth/login/_components/enter-password/enter-password.vue?macro=true";
import { default as index5L6Zioco3k0AknjLkdJsbDz89_45QUe_EbTyzhK6cUiPwMeta } from "/app/app/src/pages/auth/login/_components/enter-password/index.ts?macro=true";
import { default as indexs_45NA9GqoDEVvnotCezx5pdiedtWT8RbKls544k3ph8kMeta } from "/app/app/src/pages/auth/login/_components/register-yourself/index.ts?macro=true";
import { default as register_45yourselfGstfuV_mQbJVA_45qHe7HXW9pw_45PTCFVLVO2IaXvFFaCUMeta } from "/app/app/src/pages/auth/login/_components/register-yourself/register-yourself.vue?macro=true";
import { default as indexIPJKJPNkNenT5hoWG_4517_45WgdQ6zE_TiWa5oDWYZli8gMeta } from "/app/app/src/pages/auth/login/_components/request-invite/index.ts?macro=true";
import { default as request_45inviteJjgbqVjHhoH5y157PSxAG5weLh7eNF9qy3xKaP8b_bwMeta } from "/app/app/src/pages/auth/login/_components/request-invite/request-invite.vue?macro=true";
import { default as indexkv9Va1YIj7SA84MaLuAnInXIZP5LLap93CHS4Xdt_V8Meta } from "/app/app/src/pages/auth/login/_components/resend-invite/index.vue?macro=true";
import { default as index4b1LFs6gFZK_45HMpr9nlWy2AT8jHbHFqN_45taj_45AS2xt0Meta } from "/app/app/src/pages/auth/login/_components/resend-invite/index.ts?macro=true";
import { default as resend_45confirm_45inviteqGmxUwmp4Z_n1lblYsp2Em9DEM_r7iwE7rJ6PB3KiuYMeta } from "/app/app/src/pages/auth/login/_components/resend-invite/resend-confirm-invite.vue?macro=true";
import { default as resend_45welcome_45inviter7rJwKu5SbZ8Ai1GqCOnohZSMU06YMeyRiex5fhNBWUMeta } from "/app/app/src/pages/auth/login/_components/resend-invite/resend-welcome-invite.vue?macro=true";
import { default as index6on6o2zDxMGWlo7lfmKAaO9lmZ9B8vX_45crcOzqtxPbwMeta } from "/app/app/src/pages/auth/login/_components/sign-in/index.ts?macro=true";
import { default as sign_45inbXCsj_VqpeQCz6kS8_7Fjyd1FU372wPEOVg0_F4rKqYMeta } from "/app/app/src/pages/auth/login/_components/sign-in/sign-in.vue?macro=true";
import { default as indexZdy_45DIlK5vK8_45F1W_0lwt5HGl7BEFunwd94AIoL6aggMeta } from "/app/app/src/pages/auth/login/index.vue?macro=true";
import { default as indexP_45qr4yaQBlyu_Cj3H_JBUPwFwb3aPZbrBdUDYOTkJVcMeta } from "/app/app/src/pages/auth/logout/index.vue?macro=true";
import { default as index0Bb7jxHWwC_Ya_45ub2MZCNMdG0Fxv2gb5MWIdYj6BuzEMeta } from "/app/app/src/pages/auth/register/index.vue?macro=true";
import { default as indexkG0sbVjbSDwUfT5Fba9PjyPDwnuoJgXfBGCSB98TiXoMeta } from "/app/app/src/pages/auth/resend-welcome-email/index.vue?macro=true";
import { default as indexIV9ZZL_LqCHEdCkk7rJLx9meZIriWW3H9U8UMeq2a5MMeta } from "/app/app/src/pages/auth/reset-password/index.vue?macro=true";
import { default as indexzZbf1L5JGePgLRGFuwm7WRTtnS2B1reMD3pR6UKGGF4Meta } from "/app/app/src/pages/auth/set-new-password/index.vue?macro=true";
import { default as indexgl0uLLhhARpfaIohgzJIUWG3BqwhWHvvOjUbZGrFKgEMeta } from "/app/app/src/pages/auth/twitter/index.vue?macro=true";
import { default as ActivityValuer_45VphBklNmjXXhC_95udhVsjC0I3oE2dtHBQemqQrMQMeta } from "/app/app/src/pages/budgets/_components/budget-activity/_components/activity-value/ActivityValue.vue?macro=true";
import { default as index26SBuNAOu9KX69OKzvOGR4IF7DBq4GaA1l8dP_b2FDEMeta } from "/app/app/src/pages/budgets/_components/budget-activity/_components/activity-value/index.ts?macro=true";
import { default as BudgetActivityFO2NesoAt14gsn_FrFVTG9cyNOOECIUSkJVv8M4tPBAMeta } from "/app/app/src/pages/budgets/_components/budget-activity/BudgetActivity.vue?macro=true";
import { default as BudgetsHeaderWoqUwHc3nz_0Hw1YuA7JTWt8CrLEeaPpPUB1Aw1xsA8Meta } from "/app/app/src/pages/budgets/_components/budgets-header/BudgetsHeader.vue?macro=true";
import { default as index2zlQwoY6mZqN2hywVHRTpDtXN_oMASrkVpp1K0fpewIMeta } from "/app/app/src/pages/budgets/_components/budgets-header/index.ts?macro=true";
import { default as indexNrQfad0c6_45JfHL72Jlkkshw4fID8YmzsOTSwR08VIVkMeta } from "/app/app/src/pages/budgets/index.vue?macro=true";
import { default as indexHHw5KToABuI6LCIboRTBvMPU4pxVIusF4W9pWZ9eoP8Meta } from "/app/app/src/pages/business-selection/index.vue?macro=true";
import { default as indexygdjXJ_45bj6gz1Q11O8_90sU3laCyo_45nI032pYVC9q6gMeta } from "/app/app/src/pages/checkout/index.vue?macro=true";
import { default as customizable_45content_45filters_46typesaDEQ09L2SpZziENAgw7PvSeyHymKaw2d4RePOR3e3W8Meta } from "/app/app/src/pages/customizable-content/_components/customizable-content-filters/customizable-content-filters.types.ts?macro=true";
import { default as customizable_45content_45filtersf_fxZTolqz6M1No6eTE1Nm2_tOyPxO6y2eCUZJsR7EYMeta } from "/app/app/src/pages/customizable-content/_components/customizable-content-filters/customizable-content-filters.vue?macro=true";
import { default as indexlBIRJaw8uw_45SxAVoz1wDRD_45bWq7XxRx3Y4OGLJ4SeUEMeta } from "/app/app/src/pages/customizable-content/_components/customizable-content-filters/index.ts?macro=true";
import { default as indexu85JPgedKao9aQW3m3HpRUokjMORAvF_45A_fD4bAkLfwMeta } from "/app/app/src/pages/customizable-content/[content-slug]/index.vue?macro=true";
import { default as index_nsRgH339kv7U0SFMcT0E4qq6huaKSEtwaaU_456b361UMeta } from "/app/app/src/pages/customizable-content/index.vue?macro=true";
import { default as indexC90y7SG1T2BsIMkVnsLrCEhogkQB5J8_45zwakFSGGEdIMeta } from "/app/app/src/pages/index.vue?macro=true";
import { default as content_45social_45draft_45cardFSZPB87ZlQNublxxi3DrPNtrg6XqEzuQ5ZPdQIMnupsMeta } from "/app/app/src/pages/my-customized-content/_components/content-social-draft-card/content-social-draft-card.vue?macro=true";
import { default as indexMoEObSj99DwEvE3G_yXSEPKw_nlfh_t_452ta4K0Kb4AIMeta } from "/app/app/src/pages/my-customized-content/_components/content-social-draft-card/index.ts?macro=true";
import { default as draft_45preview_45modalHGOcUWVCXg29gH0wB0Z4C74Or5xbpH4yebTaj0igwroMeta } from "/app/app/src/pages/my-customized-content/_components/draft-preview-modal/draft-preview-modal.vue?macro=true";
import { default as indexuMstBHikF35QqT0C_29sCWvyRaWYGEwizS1zu0E25TYMeta } from "/app/app/src/pages/my-customized-content/_components/draft-preview-modal/index.ts?macro=true";
import { default as index4OkuZDk8rVdLwkywImoUpF0bWDLhNY7fYvykXS_45QqUgMeta } from "/app/app/src/pages/my-customized-content/_components/my-customized-content-filters/index.ts?macro=true";
import { default as my_45customized_45content_45filters_46typesWhU7bQAYcwayReU16XQDevvME2d3uKzOuV9p0FgMgEQMeta } from "/app/app/src/pages/my-customized-content/_components/my-customized-content-filters/my-customized-content-filters.types.ts?macro=true";
import { default as my_45customized_45content_45filterst4FtOu40wIZfO8aVj5Z1IbUzKeF7dmqlnZC2yWpXtJsMeta } from "/app/app/src/pages/my-customized-content/_components/my-customized-content-filters/my-customized-content-filters.vue?macro=true";
import { default as indexHfcDBjwsfLHlB2GJSvtM9_45J30b8FKm4Gw6zw8AG8J2UMeta } from "/app/app/src/pages/my-customized-content/_components/post-social-content-draft-button/index.ts?macro=true";
import { default as post_45social_45content_45draft_45buttonvTd7DLzpZw0Z2h5fVPXEHZvaBLC9KINMhNSQXbEea2wMeta } from "/app/app/src/pages/my-customized-content/_components/post-social-content-draft-button/post-social-content-draft-button.vue?macro=true";
import { default as indexbdyZwzu6OLmvCL75KVLPd0wgeN_2MYX4DhGBRrrjGX4Meta } from "/app/app/src/pages/my-customized-content/index.vue?macro=true";
import { default as indexoSQC13D9UEw_dLDeE_lq2GIcBoppampurMQQ7kFLFTAMeta } from "/app/app/src/pages/my-posts/_components/add-logo-content/_components/remove-logo-confirm-modal/index.ts?macro=true";
import { default as remove_45logo_45confirm_45modal5Mj7xeFJaAlBKE9_c_45z26TaN8ha28o1ofRC41wFyaKIMeta } from "/app/app/src/pages/my-posts/_components/add-logo-content/_components/remove-logo-confirm-modal/remove-logo-confirm-modal.vue?macro=true";
import { default as add_45logo_45contenttI_45VYpHtlgq55ZSHtnP6S_45FLyY5QaKCSBYOmY1CWeX0Meta } from "/app/app/src/pages/my-posts/_components/add-logo-content/add-logo-content.vue?macro=true";
import { default as add_45logo_46content_46constantsDMzXtdpK2Deu_hwhDjpx8U9LlDlkLYPpQKUH5zjrhmoMeta } from "/app/app/src/pages/my-posts/_components/add-logo-content/add-logo.content.constants.ts?macro=true";
import { default as indexbcYsJt55FRm0nO8ZQjrYYaVBYzQn4ho9JVuLWLIfLUIMeta } from "/app/app/src/pages/my-posts/_components/add-logo-content/index.ts?macro=true";
import { default as create_45post_45modalAhFnSQDkVt2U4TbBvlq1zxEOeoRDCp2qJPZ8fKJmzd8Meta } from "/app/app/src/pages/my-posts/_components/create-post-modal/create-post-modal.vue?macro=true";
import { default as index4pqL_ULWNtVA87GqrEMIj7yhDE4wCg1FnSueoAIhDJgMeta } from "/app/app/src/pages/my-posts/_components/create-post-modal/index.ts?macro=true";
import { default as indexXTkGMSvlTP6AbXrqZtMKXPEaUg5qlbkeoqkzANn_WLYMeta } from "/app/app/src/pages/my-posts/_components/edit-post-modal/_components/save-as-draft-modal/index.ts?macro=true";
import { default as save_45as_45draft_45modalPy8BktB1EVaGqb7kn_45SYLZT_cind2krOzoOBB9Y5up4Meta } from "/app/app/src/pages/my-posts/_components/edit-post-modal/_components/save-as-draft-modal/save-as-draft-modal.vue?macro=true";
import { default as edit_45post_45modal4vK9rNBMtwOkOsffWzKrltGGfiW2_45NFIV42S9ceOTBgMeta } from "/app/app/src/pages/my-posts/_components/edit-post-modal/edit-post-modal.vue?macro=true";
import { default as indexufUMHWTa3o5w9rHg2KZSh1IGAAPVISl5i1EDpEcH3MkMeta } from "/app/app/src/pages/my-posts/_components/edit-post-modal/index.ts?macro=true";
import { default as EditCaptionAndFileLGPVU7XjyRVMqZQ3IZHGxZqA1mKPn5JpnSXBVCgOoCQMeta } from "/app/app/src/pages/my-posts/_components/post-modal-content/_components/media-manager/_components/social-post-files-modal/_comonents/edit-caption-and-file/EditCaptionAndFile.vue?macro=true";
import { default as indexL2_45L936LED_CUZQaI17_fPI7ok_CMN29IlVU8eTX1_YMeta } from "/app/app/src/pages/my-posts/_components/post-modal-content/_components/media-manager/_components/social-post-files-modal/_comonents/edit-caption-and-file/index.ts?macro=true";
import { default as index0MI0GBIoLan6iNNg60X_458_45oQWRn364zpGPf_JdcnJtoMeta } from "/app/app/src/pages/my-posts/_components/post-modal-content/_components/media-manager/_components/social-post-files-modal/_comonents/index.ts?macro=true";
import { default as indexWujo1wqAJA621EDymvsZVR0JB3YAI1hcoFezJCJC8OsMeta } from "/app/app/src/pages/my-posts/_components/post-modal-content/_components/media-manager/_components/social-post-files-modal/_comonents/social-file-list/index.ts?macro=true";
import { default as SocialFileListp8uchLgDpCXBIoZmlINdZW7qVC32oUX1F_3JdaUJCq0Meta } from "/app/app/src/pages/my-posts/_components/post-modal-content/_components/media-manager/_components/social-post-files-modal/_comonents/social-file-list/SocialFileList.vue?macro=true";
import { default as indexQBGteUzZqS38nykqhgD2wY9TOFqyWE2Xrk_450mxwGJNQMeta } from "/app/app/src/pages/my-posts/_components/post-modal-content/_components/media-manager/_components/social-post-files-modal/index.ts?macro=true";
import { default as SocialPostFilesModal6oVR62FH_45NuH1kHF0gkGSrvmxTkVb1qjhJi9SywXP2kMeta } from "/app/app/src/pages/my-posts/_components/post-modal-content/_components/media-manager/_components/social-post-files-modal/SocialPostFilesModal.vue?macro=true";
import { default as indexBmBqRLcbi_45vYmUXl6aDRx7zBNF31JAvi377Vtkn4tRIMeta } from "/app/app/src/pages/my-posts/_components/post-modal-content/_components/media-manager/index.ts?macro=true";
import { default as media_45manager_46constants7qyQI_2T1hoYII9k5_45QU5qBTJn_45F1UyDRSFlMpzUHQkMeta } from "/app/app/src/pages/my-posts/_components/post-modal-content/_components/media-manager/media-manager.constants.ts?macro=true";
import { default as MediaManagerLvX2onpKbW9KFOELoVm7EWw1tQfBm0afZF1NA6re1xcMeta } from "/app/app/src/pages/my-posts/_components/post-modal-content/_components/media-manager/MediaManager.vue?macro=true";
import { default as indexa50fTAoADm4Rgf2Rccn_45_45eCrcKwQMmzHmbzDFTIpQNoMeta } from "/app/app/src/pages/my-posts/_components/post-modal-content/index.ts?macro=true";
import { default as post_45modal_45content_46typeszX5Saycg3xQxJmKtM_y9dmUuDp4mwji3dgdHodCuHvIMeta } from "/app/app/src/pages/my-posts/_components/post-modal-content/post-modal-content.types.ts?macro=true";
import { default as post_45modal_45content_457L828nZg4ZdxE_mQNdDKMqdGqe74exs2g8dnPppeYsMeta } from "/app/app/src/pages/my-posts/_components/post-modal-content/post-modal-content.vue?macro=true";
import { default as delete_45post_45modal_SZsUOzcEDyh4VSaC39xBMstbrtGTLuqRN8eDYVmtAgMeta } from "/app/app/src/pages/my-posts/_components/posts-list/_components/delete-post-modal/delete-post-modal.vue?macro=true";
import { default as indexwaYLVLQJIYVx6566DIK1SGnoH67MUgqdU0oA1OEnOYsMeta } from "/app/app/src/pages/my-posts/_components/posts-list/_components/delete-post-modal/index.ts?macro=true";
import { default as indexfKFDB3isZQ9_QMfGcJ30_xBm9YLEmNkEvIof9nN1hvIMeta } from "/app/app/src/pages/my-posts/_components/posts-list/index.ts?macro=true";
import { default as posts_45list3wdBnfdfxraPkKwE_6asb0HcWIALP0ZDRzuqKjfI6kAMeta } from "/app/app/src/pages/my-posts/_components/posts-list/posts-list.vue?macro=true";
import { default as indexKaT80m4IpMAIHNrKB1E1C7nRQVLlfL7HSmxyXM_ixuQMeta } from "/app/app/src/pages/my-posts/_components/quadrants-overlay/index.ts?macro=true";
import { default as quadrants_45overlaybOvRP1X8y4D59OYbZudOdaCDYSyTgmo8I5xJpTI9348Meta } from "/app/app/src/pages/my-posts/_components/quadrants-overlay/quadrants-overlay.vue?macro=true";
import { default as indexY_45HtHdLPqxqfBgwiPuDg2KYVUWhGL_45H5nY6DXTm1FYEMeta } from "/app/app/src/pages/my-posts/_components/select-logo-modal/index.ts?macro=true";
import { default as select_45logo_45modaly4Pof0YWeEl2_DfkXWjtB0KTJNzZ3g2p3m_45XEJqRSI8Meta } from "/app/app/src/pages/my-posts/_components/select-logo-modal/select-logo-modal.vue?macro=true";
import { default as index8Oms7PADQ6lqyPLM1Ao_450VNfK1Y4EgPSc9hpzlxJMnMMeta } from "/app/app/src/pages/my-posts/_components/social-post-preview/components/social-post-description/index.ts?macro=true";
import { default as social_45post_45descriptionwYW4AsPtJtsI7aFC3o9wf8UG_45t5oi2elALT_XaK3VrgMeta } from "/app/app/src/pages/my-posts/_components/social-post-preview/components/social-post-description/social-post-description.vue?macro=true";
import { default as index4zYvevd2u2cN0mbIP6s5kcWZEQ2h37TEz9PJ6M_45_XgQMeta } from "/app/app/src/pages/my-posts/_components/social-post-preview/index.ts?macro=true";
import { default as social_45post_45previewPxs0WmX59uEdekC8uFLiR7JkFBcNcSSL82flkeJfJi0Meta } from "/app/app/src/pages/my-posts/_components/social-post-preview/social-post-preview.vue?macro=true";
import { default as indexDKjtHMbsYEgX0zVfhlVVCEW9Kr_45NdBN6B1ISi8_45a9nIMeta } from "/app/app/src/pages/my-posts/index.vue?macro=true";
import { default as my_45posts_46constantsZnV6ykp536hPXgg2EIAnzQ_45kKpq5LpTANeHzquF3pWUMeta } from "/app/app/src/pages/my-posts/my-posts.constants.ts?macro=true";
import { default as auth_46utilsEs8mmayvyjETKrOEyDxa9WnLOlNZIc5AdSG0hC1Y47QMeta } from "/app/app/src/pages/oauth2/auth.utils.ts?macro=true";
import { default as index9OsRO8CoBF1zGw_45or6mGzgBBAc2r5LwsTCdmGspcGaAMeta } from "/app/app/src/pages/oauth2/facebook/index.vue?macro=true";
import { default as indexoXc4fE9SlofTjxV55ZNHXoEQTYQ5yYdOP4y5BhWuYioMeta } from "/app/app/src/pages/oauth2/instagram/index.vue?macro=true";
import { default as indexB1uDevV_45gQZWEormi7NAv_7MbSI_457mN8T14Mg2If5ksMeta } from "/app/app/src/pages/oauth2/linkedin/index.vue?macro=true";
import { default as indexLfzdDPNSUtJXSZqnjrSyCosLS_xUjXoBSnD6zAKxI68Meta } from "/app/app/src/pages/oauth2/twitter/index.vue?macro=true";
import { default as indexk_45icfvWp0fSYdR38wz3GjapvPZh9Btt64talr8Q91o4Meta } from "/app/app/src/pages/onboarding/index.vue?macro=true";
import { default as indexkOfNKEAeKmTMRPhad9XikwslpngurLPmRCu6vSzhX1AMeta } from "/app/app/src/pages/org/[organization-slug]/[hub-slug]/[content-slug]/index.vue?macro=true";
import { default as indexwQpnoig1_45HX1qvYmuORAYfUaeR7hrqvkwoRSJ5n_PpoMeta } from "/app/app/src/pages/org/[organization-slug]/[hub-slug]/index.vue?macro=true";
import { default as indexEwHKqsFms037KWhkPr7Fjxn5MAqGrZDqFNfSJ9LFrMkMeta } from "/app/app/src/pages/org/[organization-slug]/index.vue?macro=true";
import { default as filters_45modalQZnqIYrxm7tyZ_45rdxk8DfyULnHQQ0GNJVsSf5E5ew9AMeta } from "/app/app/src/pages/print/_components/filters-modal/filters-modal.vue?macro=true";
import { default as indexhYQ4WC17LnGwBy6TFfj4sn3XfNFL_L1w7IdeJuOtcA0Meta } from "/app/app/src/pages/print/_components/filters-modal/index.ts?macro=true";
import { default as indexCqxxD_QZl9ZF_XlYBO9UOUIOo1EsXzsFrX9Iif_sfK0Meta } from "/app/app/src/pages/print/_components/print-content-card/_components/print-preview-modal/index.ts?macro=true";
import { default as print_45preview_45modal1tQOb8yKv_45ykrRKUXZ0sV59PXECX8_45qDZvzZ9RQHATsMeta } from "/app/app/src/pages/print/_components/print-content-card/_components/print-preview-modal/print-preview-modal.vue?macro=true";
import { default as indexSWPP0rJBDFdprxmZxHUFTN_45wjZVHNcCh1h77_Srm7KgMeta } from "/app/app/src/pages/print/_components/print-content-card/index.ts?macro=true";
import { default as print_45content_45cardHN6uRMcFQtcQnnX1AY9Ey9C4jUW5xBqN8m7sOeNp7loMeta } from "/app/app/src/pages/print/_components/print-content-card/print-content-card.vue?macro=true";
import { default as indexDuA_JwvvicPbOnqoIpTypTX1jnEXfyvNrX53QoxsoEAMeta } from "/app/app/src/pages/print/_components/print-contents-list/index.ts?macro=true";
import { default as print_45contents_45listOhdhfHs_45qhYm2ga_45HdTnjilDkHGCa4_45sDlcy3nTNLsMMeta } from "/app/app/src/pages/print/_components/print-contents-list/print-contents-list.vue?macro=true";
import { default as indexFjFeMSl0JRpvpKHNBSIDDoYa0vddzziTJjhyHX8M9gYMeta } from "/app/app/src/pages/print/_components/print-filters/index.ts?macro=true";
import { default as print_45filtersqm3NBSWXXnmMP8SkVCox8kyTazvtcRDCNoNaWCVKVRcMeta } from "/app/app/src/pages/print/_components/print-filters/print-filters.vue?macro=true";
import { default as indexjJLvbpPrSL6fL_45rsbHXwh1vA1BpzsX4ULcfs8XrEDp4Meta } from "/app/app/src/pages/print/_components/print-history-list/index.ts?macro=true";
import { default as print_45history_45listT1f8vlvRaE5TlDrZSqXLpaizxT3NVVnfY0O1RFVVnQUMeta } from "/app/app/src/pages/print/_components/print-history-list/print-history-list.vue?macro=true";
import { default as index26EluWV80b2cA0v9UH63YOWrB2AqNVdeKuHT8DH4Ab4Meta } from "/app/app/src/pages/print/_types/index.ts?macro=true";
import { default as delivery_45stepF1LdYpIBa_45SHDqQ1pLsi_6Ec97lS0nEz4kcF0s_45hJqIMeta } from "/app/app/src/pages/print/checkout/_steps/delivery-step.vue?macro=true";
import { default as payment_45step3TYK7t41sHKukBxHe0nNCA9dauCKNqeecMcr_k18_4554Meta } from "/app/app/src/pages/print/checkout/_steps/payment-step.vue?macro=true";
import { default as shipping_45stepmZpeHPAtVCNwefrMOKrPW9qF2Ulq8b9ekD5wY6EUM3QMeta } from "/app/app/src/pages/print/checkout/_steps/shipping-step.vue?macro=true";
import { default as checkoutOS6PE2_45B2IM0yaoodTWSthE7Qdh7vVtnix56_45ii_45mrgMeta } from "/app/app/src/pages/print/checkout/checkout.vue?macro=true";
import { default as indexjKUz8pKHvy3JSyRHLQi0xoyYgBLh_iFbu7abMZTFHmIMeta } from "/app/app/src/pages/print/checkout/index.ts?macro=true";
import { default as indexM6bkInLp_452pHbnCl7FNt9Kko7hh7IqIPg4_45Z7JlqzCsMeta } from "/app/app/src/pages/print/index.vue?macro=true";
import { default as indexhYQRJU7UtmCSyCuiMFF0RWe6_oyp2GkblSqlsdmGi_YMeta } from "/app/app/src/pages/print/index.ts?macro=true";
import { default as printBBXlEd3r58up8YTNj2HQbOztOz_45ombUQQtoANEZTYqMMeta } from "/app/app/src/pages/print/print.vue?macro=true";
import { default as customization_45tabQ3grDvBOOHFxdKD3v90p3oicr6okt7iDPFaCuldN8AYMeta } from "/app/app/src/pages/profile/_components/customization-tab/customization-tab.vue?macro=true";
import { default as index_45PA4F0w0uHw_45E4WcZzDpPPgMGovp4F7Wd4yLlVV2Vl8Meta } from "/app/app/src/pages/profile/_components/customization-tab/index.ts?macro=true";
import { default as details_45tabSvBHg2yyhyfdwHT6_45W3KoIud5IYEgEqqV_6mujf8NDkMeta } from "/app/app/src/pages/profile/_components/details-tab/details-tab.vue?macro=true";
import { default as indexRxLNhEDjx4NQwBa_45JqHm3CNLi4xKbzzo2HPQurcxWrMMeta } from "/app/app/src/pages/profile/_components/details-tab/index.ts?macro=true";
import { default as index3RqUIaYRVaSXEhoQMHjiKLEcMw94wslf1Qsou05i_45nIMeta } from "/app/app/src/pages/profile/_components/security-tab/index.ts?macro=true";
import { default as security_45tabv01jW8sGGs4X6n994bip2ClN_w979DvVrwFNu9ALNlgMeta } from "/app/app/src/pages/profile/_components/security-tab/security-tab.vue?macro=true";
import { default as index2IySFmSD6BcGqdNrxHe_B2qnss6T7M6o6BXd_4skN9cMeta } from "/app/app/src/pages/profile/index.vue?macro=true";
import { default as PromoteBreadcrumbssOD_UEQeEzV0cm2DUb5s9VoyYYBWjRnNoBez4JgmC8QMeta } from "/app/app/src/pages/promote/_components/promote-breadcrumbs/PromoteBreadcrumbs.vue?macro=true";
import { default as indexRXrOnjeBLR3lCZCUsorAFAi_45g1gPdUVU4vyrG4veNBUMeta } from "/app/app/src/pages/promote/_components/promote-details-table/index.ts?macro=true";
import { default as PromoteDetailsTableS9_EB41YFre3VlapNrEhQNZLS2aafxiNJrn3RXYLPfcMeta } from "/app/app/src/pages/promote/_components/promote-details-table/PromoteDetailsTable.vue?macro=true";
import { default as index9PxdlusvSFLhePZ48PB1vybxcDwK_45xValnerwrxe8EAMeta } from "/app/app/src/pages/promote/_components/promote-header/index.ts?macro=true";
import { default as PromoteHeaderVI330btGYSFacbrzTbjZgvCzq2M5unt_kcATENpUom4Meta } from "/app/app/src/pages/promote/_components/promote-header/PromoteHeader.vue?macro=true";
import { default as index_yh2GIpYS6m8kQlzPkoKB0W9M_7r8SQvB_nUjxXZAAAMeta } from "/app/app/src/pages/promote/_components/promote-stat-tile/index.ts?macro=true";
import { default as PromoteStatTileNJiuAe48jtTV94SyfHcKggUqKhx5mbnSQw_dmjUUYmoMeta } from "/app/app/src/pages/promote/_components/promote-stat-tile/PromoteStatTile.vue?macro=true";
import { default as index6fhJwtU47J8K94k76sh4HrX4CQ6MVAtP8RK_45RzoFqLsMeta } from "/app/app/src/pages/promote/_constants/index.ts?macro=true";
import { default as indexU3GRnBfmwKudFO_QCpaqdlEo0PtFUbUqEllVKhvaE8IMeta } from "/app/app/src/pages/promote/_types/index.ts?macro=true";
import { default as indextTdzb8GnalZ41NYh0X6_L7WzERlzEdNDaZdf_agvKfIMeta } from "/app/app/src/pages/promote/index.vue?macro=true";
import { default as cepathilfmRCDqPKov936edmqm7EEsq31Xx2SVGseIbf2f2LbN0Meta } from "/app/app/src/pages/welcome-screen/_components/cepathil/cepathil.vue?macro=true";
import { default as lightforceydMnBLQu_H_bJH8pAg_5AQ6rpsufHjKbGyyZsfqrt8wMeta } from "/app/app/src/pages/welcome-screen/_components/lightforce/lightforce.vue?macro=true";
import { default as indexf_457sJSTg38okeeVbMbCk7QbUzZnCyHwweENxuBQUcMwMeta } from "/app/app/src/pages/welcome-screen/index.vue?macro=true";
export default [
  {
    name: "_components-back-to-dashboard-button-back-to-dashboard-button",
    path: "/_components/back-to-dashboard-button/back-to-dashboard-button",
    component: () => import("/app/app/src/pages/_components/back-to-dashboard-button/back-to-dashboard-button.vue")
  },
  {
    name: "_components-back-to-dashboard-button",
    path: "/_components/back-to-dashboard-button",
    component: () => import("/app/app/src/pages/_components/back-to-dashboard-button/index.ts")
  },
  {
    name: "_components-budget-summary-BudgetSummary",
    path: "/_components/budget-summary/BudgetSummary",
    component: () => import("/app/app/src/pages/_components/budget-summary/BudgetSummary.vue")
  },
  {
    name: "_components-budget-summary",
    path: "/_components/budget-summary",
    component: () => import("/app/app/src/pages/_components/budget-summary/index.ts")
  },
  {
    name: "_components-content-card-_components-launch-metric-chip",
    path: "/_components/content-card/_components/launch-metric-chip",
    component: () => import("/app/app/src/pages/_components/content-card/_components/launch-metric-chip/index.ts")
  },
  {
    name: "_components-content-card-_components-launch-metric-chip-launch-metric-chip",
    path: "/_components/content-card/_components/launch-metric-chip/launch-metric-chip",
    component: () => import("/app/app/src/pages/_components/content-card/_components/launch-metric-chip/launch-metric-chip.vue")
  },
  {
    name: "_components-content-card-content-card",
    path: "/_components/content-card/content-card",
    component: () => import("/app/app/src/pages/_components/content-card/content-card.vue")
  },
  {
    name: "_components-content-card",
    path: "/_components/content-card",
    component: () => import("/app/app/src/pages/_components/content-card/index.ts")
  },
  {
    name: "_components-content-details-_components-complete-content-button-complete-content-button",
    path: "/_components/content-details/_components/complete-content-button/complete-content-button",
    component: () => import("/app/app/src/pages/_components/content-details/_components/complete-content-button/complete-content-button.vue")
  },
  {
    name: "_components-content-details-_components-complete-content-button",
    path: "/_components/content-details/_components/complete-content-button",
    component: () => import("/app/app/src/pages/_components/content-details/_components/complete-content-button/index.ts")
  },
  {
    name: "_components-content-details-_components-files-area-file-preview-file-preview",
    path: "/_components/content-details/_components/files-area/file-preview/file-preview",
    component: () => import("/app/app/src/pages/_components/content-details/_components/files-area/file-preview/file-preview.vue")
  },
  {
    name: "_components-content-details-_components-files-area-file-preview",
    path: "/_components/content-details/_components/files-area/file-preview",
    component: () => import("/app/app/src/pages/_components/content-details/_components/files-area/file-preview/index.ts")
  },
  {
    name: "_components-content-details-_components-files-area-files-area",
    path: "/_components/content-details/_components/files-area/files-area",
    component: () => import("/app/app/src/pages/_components/content-details/_components/files-area/files-area.vue")
  },
  {
    name: "_components-content-details-_components-files-area",
    path: "/_components/content-details/_components/files-area",
    component: () => import("/app/app/src/pages/_components/content-details/_components/files-area/index.ts")
  },
  {
    name: "_components-content-details-_components-launch-content-navigation",
    path: "/_components/content-details/_components/launch-content-navigation",
    component: () => import("/app/app/src/pages/_components/content-details/_components/launch-content-navigation/index.ts")
  },
  {
    name: "_components-content-details-_components-launch-content-navigation-launch-content-navigation",
    path: "/_components/content-details/_components/launch-content-navigation/launch-content-navigation",
    component: () => import("/app/app/src/pages/_components/content-details/_components/launch-content-navigation/launch-content-navigation.vue")
  },
  {
    name: "_components-content-details-_components-resource-area",
    path: "/_components/content-details/_components/resource-area",
    component: () => import("/app/app/src/pages/_components/content-details/_components/resource-area/index.ts")
  },
  {
    name: "_components-content-details-_components-resource-area-resource-area",
    path: "/_components/content-details/_components/resource-area/resource-area",
    component: () => import("/app/app/src/pages/_components/content-details/_components/resource-area/resource-area.vue")
  },
  {
    name: "_components-content-details-_components-send-content-modal",
    path: "/_components/content-details/_components/send-content-modal",
    component: () => import("/app/app/src/pages/_components/content-details/_components/send-content-modal/index.ts")
  },
  {
    name: "_components-content-details-_components-send-content-modal-send-content-modal",
    path: "/_components/content-details/_components/send-content-modal/send-content-modal",
    component: () => import("/app/app/src/pages/_components/content-details/_components/send-content-modal/send-content-modal.vue")
  },
  {
    name: "_components-content-details-_components-socials-area-_components-social-preview-_components-carousel-grid-preview-modal-carousel-grid-preview-modal",
    path: "/_components/content-details/_components/socials-area/_components/social-preview/_components/carousel-grid-preview-modal/carousel-grid-preview-modal",
    component: () => import("/app/app/src/pages/_components/content-details/_components/socials-area/_components/social-preview/_components/carousel-grid-preview-modal/carousel-grid-preview-modal.vue")
  },
  {
    name: "_components-content-details-_components-socials-area-_components-social-preview-_components-carousel-grid-preview-modal",
    path: "/_components/content-details/_components/socials-area/_components/social-preview/_components/carousel-grid-preview-modal",
    component: () => import("/app/app/src/pages/_components/content-details/_components/socials-area/_components/social-preview/_components/carousel-grid-preview-modal/index.ts")
  },
  {
    name: "_components-content-details-_components-socials-area-_components-social-preview",
    path: "/_components/content-details/_components/socials-area/_components/social-preview",
    component: () => import("/app/app/src/pages/_components/content-details/_components/socials-area/_components/social-preview/index.ts")
  },
  {
    name: "_components-content-details-_components-socials-area-_components-social-preview-social-preview",
    path: "/_components/content-details/_components/socials-area/_components/social-preview/social-preview",
    component: () => import("/app/app/src/pages/_components/content-details/_components/socials-area/_components/social-preview/social-preview.vue")
  },
  {
    name: "_components-content-details-_components-socials-area",
    path: "/_components/content-details/_components/socials-area",
    component: () => import("/app/app/src/pages/_components/content-details/_components/socials-area/index.ts")
  },
  {
    name: "_components-content-details-_components-socials-area-socials-area",
    path: "/_components/content-details/_components/socials-area/socials-area",
    component: () => import("/app/app/src/pages/_components/content-details/_components/socials-area/socials-area.vue")
  },
  {
    name: "_components-content-details-_components-update-personalization-settings-banner",
    path: "/_components/content-details/_components/update-personalization-settings-banner",
    component: () => import("/app/app/src/pages/_components/content-details/_components/update-personalization-settings-banner/index.ts")
  },
  {
    name: "_components-content-details-_components-update-personalization-settings-banner-update-personalization-settings-banner",
    path: "/_components/content-details/_components/update-personalization-settings-banner/update-personalization-settings-banner",
    component: () => import("/app/app/src/pages/_components/content-details/_components/update-personalization-settings-banner/update-personalization-settings-banner.vue")
  },
  {
    name: "_components-content-details-content-details",
    path: "/_components/content-details/content-details",
    component: () => import("/app/app/src/pages/_components/content-details/content-details.vue")
  },
  {
    name: "_components-content-details-content.constants",
    path: "/_components/content-details/content.constants",
    component: () => import("/app/app/src/pages/_components/content-details/content.constants.ts")
  },
  {
    name: "_components-content-details-content.utils",
    path: "/_components/content-details/content.utils",
    component: () => import("/app/app/src/pages/_components/content-details/content.utils.ts")
  },
  {
    name: "_components-content-details",
    path: "/_components/content-details",
    component: () => import("/app/app/src/pages/_components/content-details/index.ts")
  },
  {
    name: "_components-description-description",
    path: "/_components/description/description",
    component: () => import("/app/app/src/pages/_components/description/description.vue")
  },
  {
    name: "_components-description",
    path: "/_components/description",
    component: () => import("/app/app/src/pages/_components/description/index.ts")
  },
  {
    name: "_components-empty-list-empty-list",
    path: "/_components/empty-list/empty-list",
    component: () => import("/app/app/src/pages/_components/empty-list/empty-list.vue")
  },
  {
    name: "_components-empty-list",
    path: "/_components/empty-list",
    component: () => import("/app/app/src/pages/_components/empty-list/index.ts")
  },
  {
    name: "_components-hub-dashboard-_components-launch-hub-badge",
    path: "/_components/hub-dashboard/_components/launch-hub-badge",
    component: () => import("/app/app/src/pages/_components/hub-dashboard/_components/launch-hub-badge/index.ts")
  },
  {
    name: "_components-hub-dashboard-_components-launch-hub-badge-launch-hub-badge",
    path: "/_components/hub-dashboard/_components/launch-hub-badge/launch-hub-badge",
    component: () => import("/app/app/src/pages/_components/hub-dashboard/_components/launch-hub-badge/launch-hub-badge.vue")
  },
  {
    name: "_components-hub-dashboard-hub-dashboard",
    path: "/_components/hub-dashboard/hub-dashboard",
    component: () => import("/app/app/src/pages/_components/hub-dashboard/hub-dashboard.vue")
  },
  {
    name: "_components-hub-dashboard",
    path: "/_components/hub-dashboard",
    component: () => import("/app/app/src/pages/_components/hub-dashboard/index.ts")
  },
  {
    name: "_components-hub-details-_components-accept-terms-modal-accept-terms-modal",
    path: "/_components/hub-details/_components/accept-terms-modal/accept-terms-modal",
    component: () => import("/app/app/src/pages/_components/hub-details/_components/accept-terms-modal/accept-terms-modal.vue")
  },
  {
    name: "_components-hub-details-_components-accept-terms-modal",
    path: "/_components/hub-details/_components/accept-terms-modal",
    component: () => import("/app/app/src/pages/_components/hub-details/_components/accept-terms-modal/index.ts")
  },
  {
    name: "_components-hub-details-_components-completed-contents-banner-completed-contents-banner",
    path: "/_components/hub-details/_components/completed-contents-banner/completed-contents-banner",
    component: () => import("/app/app/src/pages/_components/hub-details/_components/completed-contents-banner/completed-contents-banner.vue")
  },
  {
    name: "_components-hub-details-_components-completed-contents-banner",
    path: "/_components/hub-details/_components/completed-contents-banner",
    component: () => import("/app/app/src/pages/_components/hub-details/_components/completed-contents-banner/index.ts")
  },
  {
    name: "_components-hub-details-_components-filters-modal-_components-desktop-filters-desktop-filters",
    path: "/_components/hub-details/_components/filters-modal/_components/desktop-filters/desktop-filters",
    component: () => import("/app/app/src/pages/_components/hub-details/_components/filters-modal/_components/desktop-filters/desktop-filters.vue")
  },
  {
    name: "_components-hub-details-_components-filters-modal-_components-desktop-filters",
    path: "/_components/hub-details/_components/filters-modal/_components/desktop-filters",
    component: () => import("/app/app/src/pages/_components/hub-details/_components/filters-modal/_components/desktop-filters/index.ts")
  },
  {
    name: "_components-hub-details-_components-filters-modal-_components-filter-btn-filter-btn",
    path: "/_components/hub-details/_components/filters-modal/_components/filter-btn/filter-btn",
    component: () => import("/app/app/src/pages/_components/hub-details/_components/filters-modal/_components/filter-btn/filter-btn.vue")
  },
  {
    name: "_components-hub-details-_components-filters-modal-_components-filter-btn",
    path: "/_components/hub-details/_components/filters-modal/_components/filter-btn",
    component: () => import("/app/app/src/pages/_components/hub-details/_components/filters-modal/_components/filter-btn/index.ts")
  },
  {
    name: "_components-hub-details-_components-filters-modal-_components-filter-header-filter-header",
    path: "/_components/hub-details/_components/filters-modal/_components/filter-header/filter-header",
    component: () => import("/app/app/src/pages/_components/hub-details/_components/filters-modal/_components/filter-header/filter-header.vue")
  },
  {
    name: "_components-hub-details-_components-filters-modal-_components-filter-header",
    path: "/_components/hub-details/_components/filters-modal/_components/filter-header",
    component: () => import("/app/app/src/pages/_components/hub-details/_components/filters-modal/_components/filter-header/index.ts")
  },
  {
    name: "_components-hub-details-_components-filters-modal-_components-mobile-filters-_components-active-filters-active-filters",
    path: "/_components/hub-details/_components/filters-modal/_components/mobile-filters/_components/active-filters/active-filters",
    component: () => import("/app/app/src/pages/_components/hub-details/_components/filters-modal/_components/mobile-filters/_components/active-filters/active-filters.vue")
  },
  {
    name: "_components-hub-details-_components-filters-modal-_components-mobile-filters-_components-active-filters",
    path: "/_components/hub-details/_components/filters-modal/_components/mobile-filters/_components/active-filters",
    component: () => import("/app/app/src/pages/_components/hub-details/_components/filters-modal/_components/mobile-filters/_components/active-filters/index.ts")
  },
  {
    name: "_components-hub-details-_components-filters-modal-_components-mobile-filters",
    path: "/_components/hub-details/_components/filters-modal/_components/mobile-filters",
    component: () => import("/app/app/src/pages/_components/hub-details/_components/filters-modal/_components/mobile-filters/index.ts")
  },
  {
    name: "_components-hub-details-_components-filters-modal-_components-mobile-filters-mobile-filters",
    path: "/_components/hub-details/_components/filters-modal/_components/mobile-filters/mobile-filters",
    component: () => import("/app/app/src/pages/_components/hub-details/_components/filters-modal/_components/mobile-filters/mobile-filters.vue")
  },
  {
    name: "_components-hub-details-_components-filters-modal-_constants",
    path: "/_components/hub-details/_components/filters-modal/_constants",
    component: () => import("/app/app/src/pages/_components/hub-details/_components/filters-modal/_constants/index.ts")
  },
  {
    name: "_components-hub-details-_components-filters-modal-filters-modal",
    path: "/_components/hub-details/_components/filters-modal/filters-modal",
    component: () => import("/app/app/src/pages/_components/hub-details/_components/filters-modal/filters-modal.vue")
  },
  {
    name: "_components-hub-details-_components-filters-modal",
    path: "/_components/hub-details/_components/filters-modal",
    component: () => import("/app/app/src/pages/_components/hub-details/_components/filters-modal/index.ts")
  },
  {
    name: "_components-hub-details-_components-filters-wrapper-filters-wrapper",
    path: "/_components/hub-details/_components/filters-wrapper/filters-wrapper",
    component: () => import("/app/app/src/pages/_components/hub-details/_components/filters-wrapper/filters-wrapper.vue")
  },
  {
    name: "_components-hub-details-_components-filters-wrapper",
    path: "/_components/hub-details/_components/filters-wrapper",
    component: () => import("/app/app/src/pages/_components/hub-details/_components/filters-wrapper/index.ts")
  },
  {
    name: "_components-hub-details-_components-hub-homepage-_components-hub-logo-hub-logo",
    path: "/_components/hub-details/_components/hub-homepage/_components/hub-logo/hub-logo",
    component: () => import("/app/app/src/pages/_components/hub-details/_components/hub-homepage/_components/hub-logo/hub-logo.vue")
  },
  {
    name: "_components-hub-details-_components-hub-homepage-_components-hub-next-content-HubNextContent",
    path: "/_components/hub-details/_components/hub-homepage/_components/hub-next-content/HubNextContent",
    component: () => import("/app/app/src/pages/_components/hub-details/_components/hub-homepage/_components/hub-next-content/HubNextContent.vue")
  },
  {
    name: "_components-hub-details-_components-hub-homepage-_components-remaining-actions-counter-RemainingActionsCounter",
    path: "/_components/hub-details/_components/hub-homepage/_components/remaining-actions-counter/RemainingActionsCounter",
    component: () => import("/app/app/src/pages/_components/hub-details/_components/hub-homepage/_components/remaining-actions-counter/RemainingActionsCounter.vue")
  },
  {
    name: "_components-hub-details-_components-hub-homepage-_components-upcoming-steps-UpcomingSteps",
    path: "/_components/hub-details/_components/hub-homepage/_components/upcoming-steps/UpcomingSteps",
    component: () => import("/app/app/src/pages/_components/hub-details/_components/hub-homepage/_components/upcoming-steps/UpcomingSteps.vue")
  },
  {
    name: "_components-hub-details-_components-hub-homepage-HubHomepage",
    path: "/_components/hub-details/_components/hub-homepage/HubHomepage",
    component: () => import("/app/app/src/pages/_components/hub-details/_components/hub-homepage/HubHomepage.vue")
  },
  {
    name: "_components-hub-details-_components-hub-info-hub-info",
    path: "/_components/hub-details/_components/hub-info/hub-info",
    component: () => import("/app/app/src/pages/_components/hub-details/_components/hub-info/hub-info.vue")
  },
  {
    name: "_components-hub-details-_components-hub-info",
    path: "/_components/hub-details/_components/hub-info",
    component: () => import("/app/app/src/pages/_components/hub-details/_components/hub-info/index.ts")
  },
  {
    name: "_components-hub-details-_components-launchhub-homepage-_components-hub-logo-hub-logo",
    path: "/_components/hub-details/_components/launchhub-homepage/_components/hub-logo/hub-logo",
    component: () => import("/app/app/src/pages/_components/hub-details/_components/launchhub-homepage/_components/hub-logo/hub-logo.vue")
  },
  {
    name: "_components-hub-details-_components-launchhub-homepage-_components-hub-next-content-HubNextContent",
    path: "/_components/hub-details/_components/launchhub-homepage/_components/hub-next-content/HubNextContent",
    component: () => import("/app/app/src/pages/_components/hub-details/_components/launchhub-homepage/_components/hub-next-content/HubNextContent.vue")
  },
  {
    name: "_components-hub-details-_components-launchhub-homepage-_components-remaining-actions-counter-RemainingActionsCounter",
    path: "/_components/hub-details/_components/launchhub-homepage/_components/remaining-actions-counter/RemainingActionsCounter",
    component: () => import("/app/app/src/pages/_components/hub-details/_components/launchhub-homepage/_components/remaining-actions-counter/RemainingActionsCounter.vue")
  },
  {
    name: "_components-hub-details-_components-launchhub-homepage-_components-upcoming-steps-UpcomingSteps",
    path: "/_components/hub-details/_components/launchhub-homepage/_components/upcoming-steps/UpcomingSteps",
    component: () => import("/app/app/src/pages/_components/hub-details/_components/launchhub-homepage/_components/upcoming-steps/UpcomingSteps.vue")
  },
  {
    name: "_components-hub-details-_components-launchhub-homepage-LaunchHubHomepage",
    path: "/_components/hub-details/_components/launchhub-homepage/LaunchHubHomepage",
    component: () => import("/app/app/src/pages/_components/hub-details/_components/launchhub-homepage/LaunchHubHomepage.vue")
  },
  {
    name: "_components-hub-details-_composables-use-accepted-hubs-terms",
    path: "/_components/hub-details/_composables/use-accepted-hubs-terms",
    component: () => import("/app/app/src/pages/_components/hub-details/_composables/use-accepted-hubs-terms.ts")
  },
  {
    name: "_components-hub-details-_types",
    path: "/_components/hub-details/_types",
    component: () => import("/app/app/src/pages/_components/hub-details/_types/index.ts")
  },
  {
    name: "_components-hub-details-hub-details",
    path: "/_components/hub-details/hub-details",
    component: () => import("/app/app/src/pages/_components/hub-details/hub-details.vue")
  },
  {
    name: "_components-hub-details",
    path: "/_components/hub-details",
    component: () => import("/app/app/src/pages/_components/hub-details/index.ts")
  },
  {
    name: "_components-hub-header-hub-header",
    path: "/_components/hub-header/hub-header",
    component: () => import("/app/app/src/pages/_components/hub-header/hub-header.vue")
  },
  {
    name: "_components-hub-header",
    path: "/_components/hub-header",
    component: () => import("/app/app/src/pages/_components/hub-header/index.ts")
  },
  {
    name: "_components-onboarding-_components-onboarding-banner",
    path: "/_components/onboarding/_components/onboarding-banner",
    component: () => import("/app/app/src/pages/_components/onboarding/_components/onboarding-banner/index.ts")
  },
  {
    name: "_components-onboarding-_components-onboarding-banner-onboarding-banner",
    path: "/_components/onboarding/_components/onboarding-banner/onboarding-banner",
    component: () => import("/app/app/src/pages/_components/onboarding/_components/onboarding-banner/onboarding-banner.vue")
  },
  {
    name: "_components-onboarding-_components-onboarding-error",
    path: "/_components/onboarding/_components/onboarding-error",
    component: () => import("/app/app/src/pages/_components/onboarding/_components/onboarding-error/index.tsx")
  },
  {
    name: "_components-onboarding-_components-onboarding-error-onboarding-error.constants",
    path: "/_components/onboarding/_components/onboarding-error/onboarding-error.constants",
    component: () => import("/app/app/src/pages/_components/onboarding/_components/onboarding-error/onboarding-error.constants.ts")
  },
  {
    name: "_components-onboarding-_components-onboarding-error-onboarding-error",
    path: "/_components/onboarding/_components/onboarding-error/onboarding-error",
    component: () => import("/app/app/src/pages/_components/onboarding/_components/onboarding-error/onboarding-error.vue")
  },
  {
    name: "_components-onboarding-_components-onboarding-get-started-_components-revance-revance",
    path: "/_components/onboarding/_components/onboarding-get-started/_components/revance/revance",
    component: () => import("/app/app/src/pages/_components/onboarding/_components/onboarding-get-started/_components/revance/revance.vue")
  },
  {
    name: "_components-onboarding-_components-onboarding-get-started-_components-rxsight-rxsight",
    path: "/_components/onboarding/_components/onboarding-get-started/_components/rxsight/rxsight",
    component: () => import("/app/app/src/pages/_components/onboarding/_components/onboarding-get-started/_components/rxsight/rxsight.vue")
  },
  {
    name: "_components-onboarding-_components-onboarding-get-started",
    path: "/_components/onboarding/_components/onboarding-get-started",
    component: () => import("/app/app/src/pages/_components/onboarding/_components/onboarding-get-started/index.ts")
  },
  {
    name: "_components-onboarding-_components-onboarding-get-started-onboarding-get-started",
    path: "/_components/onboarding/_components/onboarding-get-started/onboarding-get-started",
    component: () => import("/app/app/src/pages/_components/onboarding/_components/onboarding-get-started/onboarding-get-started.vue")
  },
  {
    name: "_components-onboarding-_components-onboarding-get-started-types-get-started-emit",
    path: "/_components/onboarding/_components/onboarding-get-started/types/get-started-emit",
    component: () => import("/app/app/src/pages/_components/onboarding/_components/onboarding-get-started/types/get-started-emit.ts")
  },
  {
    name: "_components-onboarding-_components-onboarding-get-started-types-get-started-props",
    path: "/_components/onboarding/_components/onboarding-get-started/types/get-started-props",
    component: () => import("/app/app/src/pages/_components/onboarding/_components/onboarding-get-started/types/get-started-props.ts")
  },
  {
    name: "_components-onboarding-_components-onboarding-step-footer",
    path: "/_components/onboarding/_components/onboarding-step-footer",
    component: () => import("/app/app/src/pages/_components/onboarding/_components/onboarding-step-footer/index.ts")
  },
  {
    name: "_components-onboarding-_components-onboarding-step-footer-onboarding-step-footer",
    path: "/_components/onboarding/_components/onboarding-step-footer/onboarding-step-footer",
    component: () => import("/app/app/src/pages/_components/onboarding/_components/onboarding-step-footer/onboarding-step-footer.vue")
  },
  {
    name: "_components-onboarding-_components-program-summary-_components-kickoff-meeting",
    path: "/_components/onboarding/_components/program-summary/_components/kickoff-meeting",
    component: () => import("/app/app/src/pages/_components/onboarding/_components/program-summary/_components/kickoff-meeting/index.ts")
  },
  {
    name: "_components-onboarding-_components-program-summary-_components-kickoff-meeting-kickoff-meeting",
    path: "/_components/onboarding/_components/program-summary/_components/kickoff-meeting/kickoff-meeting",
    component: () => import("/app/app/src/pages/_components/onboarding/_components/program-summary/_components/kickoff-meeting/kickoff-meeting.vue")
  },
  {
    name: "_components-onboarding-_components-program-summary-_components-thank-you-block",
    path: "/_components/onboarding/_components/program-summary/_components/thank-you-block",
    component: () => import("/app/app/src/pages/_components/onboarding/_components/program-summary/_components/thank-you-block/index.tsx")
  },
  {
    name: "_components-onboarding-_components-program-summary-_components-thank-you-block-thank-you-block",
    path: "/_components/onboarding/_components/program-summary/_components/thank-you-block/thank-you-block",
    component: () => import("/app/app/src/pages/_components/onboarding/_components/program-summary/_components/thank-you-block/thank-you-block.vue")
  },
  {
    name: "_components-onboarding-_components-program-summary",
    path: "/_components/onboarding/_components/program-summary",
    component: () => import("/app/app/src/pages/_components/onboarding/_components/program-summary/index.ts")
  },
  {
    name: "_components-onboarding-_components-program-summary-program-summary",
    path: "/_components/onboarding/_components/program-summary/program-summary",
    component: () => import("/app/app/src/pages/_components/onboarding/_components/program-summary/program-summary.vue")
  },
  {
    name: "_components-onboarding-_composable-use-onboarding-config",
    path: "/_components/onboarding/_composable/use-onboarding-config",
    component: () => import("/app/app/src/pages/_components/onboarding/_composable/use-onboarding-config.ts")
  },
  {
    name: "_components-onboarding-_composable-use-tier-data",
    path: "/_components/onboarding/_composable/use-tier-data",
    component: () => import("/app/app/src/pages/_components/onboarding/_composable/use-tier-data.ts")
  },
  {
    name: "_components-onboarding-_steps-ad-selection-step-ad-selection-step",
    path: "/_components/onboarding/_steps/ad-selection-step/ad-selection-step",
    component: () => import("/app/app/src/pages/_components/onboarding/_steps/ad-selection-step/ad-selection-step.vue")
  },
  {
    name: "_components-onboarding-_steps-ad-selection-step-ad-selection.constants",
    path: "/_components/onboarding/_steps/ad-selection-step/ad-selection.constants",
    component: () => import("/app/app/src/pages/_components/onboarding/_steps/ad-selection-step/ad-selection.constants.ts")
  },
  {
    name: "_components-onboarding-_steps-ad-selection-step",
    path: "/_components/onboarding/_steps/ad-selection-step",
    component: () => import("/app/app/src/pages/_components/onboarding/_steps/ad-selection-step/index.ts")
  },
  {
    name: "_components-onboarding-_steps-authorize-advertising-step-authorize-advertising-step",
    path: "/_components/onboarding/_steps/authorize-advertising-step/authorize-advertising-step",
    component: () => import("/app/app/src/pages/_components/onboarding/_steps/authorize-advertising-step/authorize-advertising-step.vue")
  },
  {
    name: "_components-onboarding-_steps-authorize-advertising-step-authorize-advertising.constants",
    path: "/_components/onboarding/_steps/authorize-advertising-step/authorize-advertising.constants",
    component: () => import("/app/app/src/pages/_components/onboarding/_steps/authorize-advertising-step/authorize-advertising.constants.ts")
  },
  {
    name: "_components-onboarding-_steps-authorize-advertising-step",
    path: "/_components/onboarding/_steps/authorize-advertising-step",
    component: () => import("/app/app/src/pages/_components/onboarding/_steps/authorize-advertising-step/index.ts")
  },
  {
    name: "_components-onboarding-_steps-campagin-targeting-step-_components-audience-targeting-audience-targeting",
    path: "/_components/onboarding/_steps/campagin-targeting-step/_components/audience-targeting/audience-targeting",
    component: () => import("/app/app/src/pages/_components/onboarding/_steps/campagin-targeting-step/_components/audience-targeting/audience-targeting.vue")
  },
  {
    name: "_components-onboarding-_steps-campagin-targeting-step-_components-audience-targeting",
    path: "/_components/onboarding/_steps/campagin-targeting-step/_components/audience-targeting",
    component: () => import("/app/app/src/pages/_components/onboarding/_steps/campagin-targeting-step/_components/audience-targeting/index.ts")
  },
  {
    name: "_components-onboarding-_steps-campagin-targeting-step-_components-average-distance-average-distance",
    path: "/_components/onboarding/_steps/campagin-targeting-step/_components/average-distance/average-distance",
    component: () => import("/app/app/src/pages/_components/onboarding/_steps/campagin-targeting-step/_components/average-distance/average-distance.vue")
  },
  {
    name: "_components-onboarding-_steps-campagin-targeting-step-_components-average-distance",
    path: "/_components/onboarding/_steps/campagin-targeting-step/_components/average-distance",
    component: () => import("/app/app/src/pages/_components/onboarding/_steps/campagin-targeting-step/_components/average-distance/index.ts")
  },
  {
    name: "_components-onboarding-_steps-campagin-targeting-step-_components-interests",
    path: "/_components/onboarding/_steps/campagin-targeting-step/_components/interests",
    component: () => import("/app/app/src/pages/_components/onboarding/_steps/campagin-targeting-step/_components/interests/index.ts")
  },
  {
    name: "_components-onboarding-_steps-campagin-targeting-step-_components-interests-interests",
    path: "/_components/onboarding/_steps/campagin-targeting-step/_components/interests/interests",
    component: () => import("/app/app/src/pages/_components/onboarding/_steps/campagin-targeting-step/_components/interests/interests.vue")
  },
  {
    name: "_components-onboarding-_steps-campagin-targeting-step-campaign-targeting-step",
    path: "/_components/onboarding/_steps/campagin-targeting-step/campaign-targeting-step",
    component: () => import("/app/app/src/pages/_components/onboarding/_steps/campagin-targeting-step/campaign-targeting-step.vue")
  },
  {
    name: "_components-onboarding-_steps-campagin-targeting-step",
    path: "/_components/onboarding/_steps/campagin-targeting-step",
    component: () => import("/app/app/src/pages/_components/onboarding/_steps/campagin-targeting-step/index.ts")
  },
  {
    name: "_components-onboarding-_steps-data-collection-step-_components-address-input-address-input",
    path: "/_components/onboarding/_steps/data-collection-step/_components/address-input/address-input",
    component: () => import("/app/app/src/pages/_components/onboarding/_steps/data-collection-step/_components/address-input/address-input.vue")
  },
  {
    name: "_components-onboarding-_steps-data-collection-step-_components-address-input",
    path: "/_components/onboarding/_steps/data-collection-step/_components/address-input",
    component: () => import("/app/app/src/pages/_components/onboarding/_steps/data-collection-step/_components/address-input/index.ts")
  },
  {
    name: "_components-onboarding-_steps-data-collection-step-_constants",
    path: "/_components/onboarding/_steps/data-collection-step/_constants",
    component: () => import("/app/app/src/pages/_components/onboarding/_steps/data-collection-step/_constants/index.ts")
  },
  {
    name: "_components-onboarding-_steps-data-collection-step-data-collection-step",
    path: "/_components/onboarding/_steps/data-collection-step/data-collection-step",
    component: () => import("/app/app/src/pages/_components/onboarding/_steps/data-collection-step/data-collection-step.vue")
  },
  {
    name: "_components-onboarding-_steps-data-collection-step",
    path: "/_components/onboarding/_steps/data-collection-step",
    component: () => import("/app/app/src/pages/_components/onboarding/_steps/data-collection-step/index.ts")
  },
  {
    name: "_components-onboarding-_steps-funding-step-components-payment",
    path: "/_components/onboarding/_steps/funding-step/components/payment",
    component: () => import("/app/app/src/pages/_components/onboarding/_steps/funding-step/components/payment/index.ts")
  },
  {
    name: "_components-onboarding-_steps-funding-step-components-payment-Payment",
    path: "/_components/onboarding/_steps/funding-step/components/payment/Payment",
    component: () => import("/app/app/src/pages/_components/onboarding/_steps/funding-step/components/payment/Payment.vue")
  },
  {
    name: "_components-onboarding-_steps-funding-step-funding-step",
    path: "/_components/onboarding/_steps/funding-step/funding-step",
    component: () => import("/app/app/src/pages/_components/onboarding/_steps/funding-step/funding-step.vue")
  },
  {
    name: "_components-onboarding-_steps-funding-step",
    path: "/_components/onboarding/_steps/funding-step",
    component: () => import("/app/app/src/pages/_components/onboarding/_steps/funding-step/index.ts")
  },
  {
    name: "_components-onboarding-_steps-landing-page-step",
    path: "/_components/onboarding/_steps/landing-page-step",
    component: () => import("/app/app/src/pages/_components/onboarding/_steps/landing-page-step/index.ts")
  },
  {
    name: "_components-onboarding-_steps-landing-page-step-landing-page-step",
    path: "/_components/onboarding/_steps/landing-page-step/landing-page-step",
    component: () => import("/app/app/src/pages/_components/onboarding/_steps/landing-page-step/landing-page-step.vue")
  },
  {
    name: "_components-onboarding-_steps-logo-step",
    path: "/_components/onboarding/_steps/logo-step",
    component: () => import("/app/app/src/pages/_components/onboarding/_steps/logo-step/index.ts")
  },
  {
    name: "_components-onboarding-_steps-logo-step-logo-step",
    path: "/_components/onboarding/_steps/logo-step/logo-step",
    component: () => import("/app/app/src/pages/_components/onboarding/_steps/logo-step/logo-step.vue")
  },
  {
    name: "_components-onboarding-_utils",
    path: "/_components/onboarding/_utils",
    component: () => import("/app/app/src/pages/_components/onboarding/_utils/index.ts")
  },
  {
    name: "_components-onboarding",
    path: "/_components/onboarding",
    component: () => import("/app/app/src/pages/_components/onboarding/index.ts")
  },
  {
    name: "_components-onboarding-onboarding.constants",
    path: "/_components/onboarding/onboarding.constants",
    component: () => import("/app/app/src/pages/_components/onboarding/onboarding.constants.ts")
  },
  {
    name: "_components-onboarding-onboarding",
    path: "/_components/onboarding/onboarding",
    meta: onboardingZCPs7vKW0pOZBKfbp01ix4REYbwq_oH3zoulhQ85UisMeta || {},
    component: () => import("/app/app/src/pages/_components/onboarding/onboarding.vue")
  },
  {
    name: "_components-organization-dashboard",
    path: "/_components/organization-dashboard",
    component: () => import("/app/app/src/pages/_components/organization-dashboard/index.ts")
  },
  {
    name: "_components-organization-dashboard-organization-dashboard",
    path: "/_components/organization-dashboard/organization-dashboard",
    component: () => import("/app/app/src/pages/_components/organization-dashboard/organization-dashboard.vue")
  },
  {
    name: "_components-send-modal",
    path: "/_components/send-modal",
    component: () => import("/app/app/src/pages/_components/send-modal/index.ts")
  },
  {
    name: "_components-send-modal-send-modal",
    path: "/_components/send-modal/send-modal",
    component: () => import("/app/app/src/pages/_components/send-modal/send-modal.vue")
  },
  {
    name: "_components-social-type-info",
    path: "/_components/social-type-info",
    component: () => import("/app/app/src/pages/_components/social-type-info/index.ts")
  },
  {
    name: "_components-social-type-info-social-type-info.constants",
    path: "/_components/social-type-info/social-type-info.constants",
    component: () => import("/app/app/src/pages/_components/social-type-info/social-type-info.constants.ts")
  },
  {
    name: "_components-social-type-info-social-type-info",
    path: "/_components/social-type-info/social-type-info",
    component: () => import("/app/app/src/pages/_components/social-type-info/social-type-info.vue")
  },
  {
    name: "_components-variable-chip",
    path: "/_components/variable-chip",
    component: () => import("/app/app/src/pages/_components/variable-chip/index.ts")
  },
  {
    name: "_components-variable-chip-variable-chip",
    path: "/_components/variable-chip/variable-chip",
    component: () => import("/app/app/src/pages/_components/variable-chip/variable-chip.vue")
  },
  {
    name: "_components-variables-modal",
    path: "/_components/variables-modal",
    component: () => import("/app/app/src/pages/_components/variables-modal/index.ts")
  },
  {
    name: "_components-variables-modal-variables-modal",
    path: "/_components/variables-modal/variables-modal",
    component: () => import("/app/app/src/pages/_components/variables-modal/variables-modal.vue")
  },
  {
    name: "hubslug-contentslug",
    path: "/:hubslug()/:contentslug()",
    component: () => import("/app/app/src/pages/[hub-slug]/[content-slug]/index.vue")
  },
  {
    name: "hubslug",
    path: "/:hubslug()",
    component: () => import("/app/app/src/pages/[hub-slug]/index.vue")
  },
  {
    name: "auth-_components-external-auth-providers-external-auth-providers",
    path: "/auth/_components/external-auth-providers/external-auth-providers",
    component: () => import("/app/app/src/pages/auth/_components/external-auth-providers/external-auth-providers.vue")
  },
  {
    name: "auth-_components-external-auth-providers",
    path: "/auth/_components/external-auth-providers",
    component: () => import("/app/app/src/pages/auth/_components/external-auth-providers/index.ts")
  },
  {
    name: "auth-facebook",
    path: "/auth/facebook",
    meta: indexP5mmnFVoZ_453mL53hbYft2KRuR9gtuHX5P5ud1EForVYMeta || {},
    component: () => import("/app/app/src/pages/auth/facebook/index.vue")
  },
  {
    name: "auth-instagram",
    path: "/auth/instagram",
    meta: indexoovepY_quEKVJOiXuVio_45_45jbn3rnjLWYbhYwRxTnoBIMeta || {},
    component: () => import("/app/app/src/pages/auth/instagram/index.vue")
  },
  {
    name: "auth-linkedin",
    path: "/auth/linkedin",
    meta: indexkYZWQpNmOd487X0U9BAEhL9b8qflduvbwljTA08u_45q4Meta || {},
    component: () => import("/app/app/src/pages/auth/linkedin/index.vue")
  },
  {
    name: "auth-login-_components-enter-password-enter-password",
    path: "/auth/login/_components/enter-password/enter-password",
    component: () => import("/app/app/src/pages/auth/login/_components/enter-password/enter-password.vue")
  },
  {
    name: "auth-login-_components-enter-password",
    path: "/auth/login/_components/enter-password",
    component: () => import("/app/app/src/pages/auth/login/_components/enter-password/index.ts")
  },
  {
    name: "auth-login-_components-register-yourself",
    path: "/auth/login/_components/register-yourself",
    component: () => import("/app/app/src/pages/auth/login/_components/register-yourself/index.ts")
  },
  {
    name: "auth-login-_components-register-yourself-register-yourself",
    path: "/auth/login/_components/register-yourself/register-yourself",
    meta: register_45yourselfGstfuV_mQbJVA_45qHe7HXW9pw_45PTCFVLVO2IaXvFFaCUMeta || {},
    component: () => import("/app/app/src/pages/auth/login/_components/register-yourself/register-yourself.vue")
  },
  {
    name: "auth-login-_components-request-invite",
    path: "/auth/login/_components/request-invite",
    component: () => import("/app/app/src/pages/auth/login/_components/request-invite/index.ts")
  },
  {
    name: "auth-login-_components-request-invite-request-invite",
    path: "/auth/login/_components/request-invite/request-invite",
    component: () => import("/app/app/src/pages/auth/login/_components/request-invite/request-invite.vue")
  },
  {
    name: index4b1LFs6gFZK_45HMpr9nlWy2AT8jHbHFqN_45taj_45AS2xt0Meta?.name,
    path: "/auth/login/_components/resend-invite",
    component: () => import("/app/app/src/pages/auth/login/_components/resend-invite/index.ts"),
    children: [
  {
    name: "auth-login-_components-resend-invite",
    path: "",
    component: () => import("/app/app/src/pages/auth/login/_components/resend-invite/index.vue")
  }
]
  },
  {
    name: "auth-login-_components-resend-invite-resend-confirm-invite",
    path: "/auth/login/_components/resend-invite/resend-confirm-invite",
    component: () => import("/app/app/src/pages/auth/login/_components/resend-invite/resend-confirm-invite.vue")
  },
  {
    name: "auth-login-_components-resend-invite-resend-welcome-invite",
    path: "/auth/login/_components/resend-invite/resend-welcome-invite",
    component: () => import("/app/app/src/pages/auth/login/_components/resend-invite/resend-welcome-invite.vue")
  },
  {
    name: "auth-login-_components-sign-in",
    path: "/auth/login/_components/sign-in",
    component: () => import("/app/app/src/pages/auth/login/_components/sign-in/index.ts")
  },
  {
    name: "auth-login-_components-sign-in-sign-in",
    path: "/auth/login/_components/sign-in/sign-in",
    component: () => import("/app/app/src/pages/auth/login/_components/sign-in/sign-in.vue")
  },
  {
    name: "auth-login",
    path: "/auth/login",
    meta: indexZdy_45DIlK5vK8_45F1W_0lwt5HGl7BEFunwd94AIoL6aggMeta || {},
    component: () => import("/app/app/src/pages/auth/login/index.vue")
  },
  {
    name: "auth-logout",
    path: "/auth/logout",
    meta: indexP_45qr4yaQBlyu_Cj3H_JBUPwFwb3aPZbrBdUDYOTkJVcMeta || {},
    component: () => import("/app/app/src/pages/auth/logout/index.vue")
  },
  {
    name: "auth-register",
    path: "/auth/register",
    meta: index0Bb7jxHWwC_Ya_45ub2MZCNMdG0Fxv2gb5MWIdYj6BuzEMeta || {},
    component: () => import("/app/app/src/pages/auth/register/index.vue")
  },
  {
    name: "auth-resend-welcome-email",
    path: "/auth/resend-welcome-email",
    meta: indexkG0sbVjbSDwUfT5Fba9PjyPDwnuoJgXfBGCSB98TiXoMeta || {},
    component: () => import("/app/app/src/pages/auth/resend-welcome-email/index.vue")
  },
  {
    name: "auth-reset-password",
    path: "/auth/reset-password",
    meta: indexIV9ZZL_LqCHEdCkk7rJLx9meZIriWW3H9U8UMeq2a5MMeta || {},
    component: () => import("/app/app/src/pages/auth/reset-password/index.vue")
  },
  {
    name: "auth-set-new-password",
    path: "/auth/set-new-password",
    meta: indexzZbf1L5JGePgLRGFuwm7WRTtnS2B1reMD3pR6UKGGF4Meta || {},
    component: () => import("/app/app/src/pages/auth/set-new-password/index.vue")
  },
  {
    name: "auth-twitter",
    path: "/auth/twitter",
    meta: indexgl0uLLhhARpfaIohgzJIUWG3BqwhWHvvOjUbZGrFKgEMeta || {},
    component: () => import("/app/app/src/pages/auth/twitter/index.vue")
  },
  {
    name: "budgets-_components-budget-activity-_components-activity-value-ActivityValue",
    path: "/budgets/_components/budget-activity/_components/activity-value/ActivityValue",
    component: () => import("/app/app/src/pages/budgets/_components/budget-activity/_components/activity-value/ActivityValue.vue")
  },
  {
    name: "budgets-_components-budget-activity-_components-activity-value",
    path: "/budgets/_components/budget-activity/_components/activity-value",
    component: () => import("/app/app/src/pages/budgets/_components/budget-activity/_components/activity-value/index.ts")
  },
  {
    name: "budgets-_components-budget-activity-BudgetActivity",
    path: "/budgets/_components/budget-activity/BudgetActivity",
    component: () => import("/app/app/src/pages/budgets/_components/budget-activity/BudgetActivity.vue")
  },
  {
    name: "budgets-_components-budgets-header-BudgetsHeader",
    path: "/budgets/_components/budgets-header/BudgetsHeader",
    component: () => import("/app/app/src/pages/budgets/_components/budgets-header/BudgetsHeader.vue")
  },
  {
    name: "budgets-_components-budgets-header",
    path: "/budgets/_components/budgets-header",
    component: () => import("/app/app/src/pages/budgets/_components/budgets-header/index.ts")
  },
  {
    name: "budgets",
    path: "/budgets",
    component: () => import("/app/app/src/pages/budgets/index.vue")
  },
  {
    name: "business-selection",
    path: "/business-selection",
    meta: indexHHw5KToABuI6LCIboRTBvMPU4pxVIusF4W9pWZ9eoP8Meta || {},
    component: () => import("/app/app/src/pages/business-selection/index.vue")
  },
  {
    name: "checkout",
    path: "/checkout",
    meta: indexygdjXJ_45bj6gz1Q11O8_90sU3laCyo_45nI032pYVC9q6gMeta || {},
    component: () => import("/app/app/src/pages/checkout/index.vue")
  },
  {
    name: "customizable-content-_components-customizable-content-filters-customizable-content-filters.types",
    path: "/customizable-content/_components/customizable-content-filters/customizable-content-filters.types",
    component: () => import("/app/app/src/pages/customizable-content/_components/customizable-content-filters/customizable-content-filters.types.ts")
  },
  {
    name: "customizable-content-_components-customizable-content-filters-customizable-content-filters",
    path: "/customizable-content/_components/customizable-content-filters/customizable-content-filters",
    component: () => import("/app/app/src/pages/customizable-content/_components/customizable-content-filters/customizable-content-filters.vue")
  },
  {
    name: "customizable-content-_components-customizable-content-filters",
    path: "/customizable-content/_components/customizable-content-filters",
    component: () => import("/app/app/src/pages/customizable-content/_components/customizable-content-filters/index.ts")
  },
  {
    name: "customizable-content-contentslug",
    path: "/customizable-content/:contentslug()",
    component: () => import("/app/app/src/pages/customizable-content/[content-slug]/index.vue")
  },
  {
    name: "customizable-content",
    path: "/customizable-content",
    component: () => import("/app/app/src/pages/customizable-content/index.vue")
  },
  {
    name: "index",
    path: "/",
    meta: indexC90y7SG1T2BsIMkVnsLrCEhogkQB5J8_45zwakFSGGEdIMeta || {},
    component: () => import("/app/app/src/pages/index.vue")
  },
  {
    name: "my-customized-content-_components-content-social-draft-card-content-social-draft-card",
    path: "/my-customized-content/_components/content-social-draft-card/content-social-draft-card",
    component: () => import("/app/app/src/pages/my-customized-content/_components/content-social-draft-card/content-social-draft-card.vue")
  },
  {
    name: "my-customized-content-_components-content-social-draft-card",
    path: "/my-customized-content/_components/content-social-draft-card",
    component: () => import("/app/app/src/pages/my-customized-content/_components/content-social-draft-card/index.ts")
  },
  {
    name: "my-customized-content-_components-draft-preview-modal-draft-preview-modal",
    path: "/my-customized-content/_components/draft-preview-modal/draft-preview-modal",
    component: () => import("/app/app/src/pages/my-customized-content/_components/draft-preview-modal/draft-preview-modal.vue")
  },
  {
    name: "my-customized-content-_components-draft-preview-modal",
    path: "/my-customized-content/_components/draft-preview-modal",
    component: () => import("/app/app/src/pages/my-customized-content/_components/draft-preview-modal/index.ts")
  },
  {
    name: "my-customized-content-_components-my-customized-content-filters",
    path: "/my-customized-content/_components/my-customized-content-filters",
    component: () => import("/app/app/src/pages/my-customized-content/_components/my-customized-content-filters/index.ts")
  },
  {
    name: "my-customized-content-_components-my-customized-content-filters-my-customized-content-filters.types",
    path: "/my-customized-content/_components/my-customized-content-filters/my-customized-content-filters.types",
    component: () => import("/app/app/src/pages/my-customized-content/_components/my-customized-content-filters/my-customized-content-filters.types.ts")
  },
  {
    name: "my-customized-content-_components-my-customized-content-filters-my-customized-content-filters",
    path: "/my-customized-content/_components/my-customized-content-filters/my-customized-content-filters",
    component: () => import("/app/app/src/pages/my-customized-content/_components/my-customized-content-filters/my-customized-content-filters.vue")
  },
  {
    name: "my-customized-content-_components-post-social-content-draft-button",
    path: "/my-customized-content/_components/post-social-content-draft-button",
    component: () => import("/app/app/src/pages/my-customized-content/_components/post-social-content-draft-button/index.ts")
  },
  {
    name: "my-customized-content-_components-post-social-content-draft-button-post-social-content-draft-button",
    path: "/my-customized-content/_components/post-social-content-draft-button/post-social-content-draft-button",
    component: () => import("/app/app/src/pages/my-customized-content/_components/post-social-content-draft-button/post-social-content-draft-button.vue")
  },
  {
    name: "my-customized-content",
    path: "/my-customized-content",
    component: () => import("/app/app/src/pages/my-customized-content/index.vue")
  },
  {
    name: "my-posts-_components-add-logo-content-_components-remove-logo-confirm-modal",
    path: "/my-posts/_components/add-logo-content/_components/remove-logo-confirm-modal",
    component: () => import("/app/app/src/pages/my-posts/_components/add-logo-content/_components/remove-logo-confirm-modal/index.ts")
  },
  {
    name: "my-posts-_components-add-logo-content-_components-remove-logo-confirm-modal-remove-logo-confirm-modal",
    path: "/my-posts/_components/add-logo-content/_components/remove-logo-confirm-modal/remove-logo-confirm-modal",
    component: () => import("/app/app/src/pages/my-posts/_components/add-logo-content/_components/remove-logo-confirm-modal/remove-logo-confirm-modal.vue")
  },
  {
    name: "my-posts-_components-add-logo-content-add-logo-content",
    path: "/my-posts/_components/add-logo-content/add-logo-content",
    component: () => import("/app/app/src/pages/my-posts/_components/add-logo-content/add-logo-content.vue")
  },
  {
    name: "my-posts-_components-add-logo-content-add-logo.content.constants",
    path: "/my-posts/_components/add-logo-content/add-logo.content.constants",
    component: () => import("/app/app/src/pages/my-posts/_components/add-logo-content/add-logo.content.constants.ts")
  },
  {
    name: "my-posts-_components-add-logo-content",
    path: "/my-posts/_components/add-logo-content",
    component: () => import("/app/app/src/pages/my-posts/_components/add-logo-content/index.ts")
  },
  {
    name: "my-posts-_components-create-post-modal-create-post-modal",
    path: "/my-posts/_components/create-post-modal/create-post-modal",
    component: () => import("/app/app/src/pages/my-posts/_components/create-post-modal/create-post-modal.vue")
  },
  {
    name: "my-posts-_components-create-post-modal",
    path: "/my-posts/_components/create-post-modal",
    component: () => import("/app/app/src/pages/my-posts/_components/create-post-modal/index.ts")
  },
  {
    name: "my-posts-_components-edit-post-modal-_components-save-as-draft-modal",
    path: "/my-posts/_components/edit-post-modal/_components/save-as-draft-modal",
    component: () => import("/app/app/src/pages/my-posts/_components/edit-post-modal/_components/save-as-draft-modal/index.ts")
  },
  {
    name: "my-posts-_components-edit-post-modal-_components-save-as-draft-modal-save-as-draft-modal",
    path: "/my-posts/_components/edit-post-modal/_components/save-as-draft-modal/save-as-draft-modal",
    component: () => import("/app/app/src/pages/my-posts/_components/edit-post-modal/_components/save-as-draft-modal/save-as-draft-modal.vue")
  },
  {
    name: "my-posts-_components-edit-post-modal-edit-post-modal",
    path: "/my-posts/_components/edit-post-modal/edit-post-modal",
    component: () => import("/app/app/src/pages/my-posts/_components/edit-post-modal/edit-post-modal.vue")
  },
  {
    name: "my-posts-_components-edit-post-modal",
    path: "/my-posts/_components/edit-post-modal",
    component: () => import("/app/app/src/pages/my-posts/_components/edit-post-modal/index.ts")
  },
  {
    name: "my-posts-_components-post-modal-content-_components-media-manager-_components-social-post-files-modal-_comonents-edit-caption-and-file-EditCaptionAndFile",
    path: "/my-posts/_components/post-modal-content/_components/media-manager/_components/social-post-files-modal/_comonents/edit-caption-and-file/EditCaptionAndFile",
    component: () => import("/app/app/src/pages/my-posts/_components/post-modal-content/_components/media-manager/_components/social-post-files-modal/_comonents/edit-caption-and-file/EditCaptionAndFile.vue")
  },
  {
    name: "my-posts-_components-post-modal-content-_components-media-manager-_components-social-post-files-modal-_comonents-edit-caption-and-file",
    path: "/my-posts/_components/post-modal-content/_components/media-manager/_components/social-post-files-modal/_comonents/edit-caption-and-file",
    component: () => import("/app/app/src/pages/my-posts/_components/post-modal-content/_components/media-manager/_components/social-post-files-modal/_comonents/edit-caption-and-file/index.ts")
  },
  {
    name: "my-posts-_components-post-modal-content-_components-media-manager-_components-social-post-files-modal-_comonents",
    path: "/my-posts/_components/post-modal-content/_components/media-manager/_components/social-post-files-modal/_comonents",
    component: () => import("/app/app/src/pages/my-posts/_components/post-modal-content/_components/media-manager/_components/social-post-files-modal/_comonents/index.ts")
  },
  {
    name: "my-posts-_components-post-modal-content-_components-media-manager-_components-social-post-files-modal-_comonents-social-file-list",
    path: "/my-posts/_components/post-modal-content/_components/media-manager/_components/social-post-files-modal/_comonents/social-file-list",
    component: () => import("/app/app/src/pages/my-posts/_components/post-modal-content/_components/media-manager/_components/social-post-files-modal/_comonents/social-file-list/index.ts")
  },
  {
    name: "my-posts-_components-post-modal-content-_components-media-manager-_components-social-post-files-modal-_comonents-social-file-list-SocialFileList",
    path: "/my-posts/_components/post-modal-content/_components/media-manager/_components/social-post-files-modal/_comonents/social-file-list/SocialFileList",
    component: () => import("/app/app/src/pages/my-posts/_components/post-modal-content/_components/media-manager/_components/social-post-files-modal/_comonents/social-file-list/SocialFileList.vue")
  },
  {
    name: "my-posts-_components-post-modal-content-_components-media-manager-_components-social-post-files-modal",
    path: "/my-posts/_components/post-modal-content/_components/media-manager/_components/social-post-files-modal",
    component: () => import("/app/app/src/pages/my-posts/_components/post-modal-content/_components/media-manager/_components/social-post-files-modal/index.ts")
  },
  {
    name: "my-posts-_components-post-modal-content-_components-media-manager-_components-social-post-files-modal-SocialPostFilesModal",
    path: "/my-posts/_components/post-modal-content/_components/media-manager/_components/social-post-files-modal/SocialPostFilesModal",
    component: () => import("/app/app/src/pages/my-posts/_components/post-modal-content/_components/media-manager/_components/social-post-files-modal/SocialPostFilesModal.vue")
  },
  {
    name: "my-posts-_components-post-modal-content-_components-media-manager",
    path: "/my-posts/_components/post-modal-content/_components/media-manager",
    component: () => import("/app/app/src/pages/my-posts/_components/post-modal-content/_components/media-manager/index.ts")
  },
  {
    name: "my-posts-_components-post-modal-content-_components-media-manager-media-manager.constants",
    path: "/my-posts/_components/post-modal-content/_components/media-manager/media-manager.constants",
    component: () => import("/app/app/src/pages/my-posts/_components/post-modal-content/_components/media-manager/media-manager.constants.ts")
  },
  {
    name: "my-posts-_components-post-modal-content-_components-media-manager-MediaManager",
    path: "/my-posts/_components/post-modal-content/_components/media-manager/MediaManager",
    component: () => import("/app/app/src/pages/my-posts/_components/post-modal-content/_components/media-manager/MediaManager.vue")
  },
  {
    name: "my-posts-_components-post-modal-content",
    path: "/my-posts/_components/post-modal-content",
    component: () => import("/app/app/src/pages/my-posts/_components/post-modal-content/index.ts")
  },
  {
    name: "my-posts-_components-post-modal-content-post-modal-content.types",
    path: "/my-posts/_components/post-modal-content/post-modal-content.types",
    component: () => import("/app/app/src/pages/my-posts/_components/post-modal-content/post-modal-content.types.ts")
  },
  {
    name: "my-posts-_components-post-modal-content-post-modal-content",
    path: "/my-posts/_components/post-modal-content/post-modal-content",
    component: () => import("/app/app/src/pages/my-posts/_components/post-modal-content/post-modal-content.vue")
  },
  {
    name: "my-posts-_components-posts-list-_components-delete-post-modal-delete-post-modal",
    path: "/my-posts/_components/posts-list/_components/delete-post-modal/delete-post-modal",
    component: () => import("/app/app/src/pages/my-posts/_components/posts-list/_components/delete-post-modal/delete-post-modal.vue")
  },
  {
    name: "my-posts-_components-posts-list-_components-delete-post-modal",
    path: "/my-posts/_components/posts-list/_components/delete-post-modal",
    component: () => import("/app/app/src/pages/my-posts/_components/posts-list/_components/delete-post-modal/index.ts")
  },
  {
    name: "my-posts-_components-posts-list",
    path: "/my-posts/_components/posts-list",
    component: () => import("/app/app/src/pages/my-posts/_components/posts-list/index.ts")
  },
  {
    name: "my-posts-_components-posts-list-posts-list",
    path: "/my-posts/_components/posts-list/posts-list",
    component: () => import("/app/app/src/pages/my-posts/_components/posts-list/posts-list.vue")
  },
  {
    name: "my-posts-_components-quadrants-overlay",
    path: "/my-posts/_components/quadrants-overlay",
    component: () => import("/app/app/src/pages/my-posts/_components/quadrants-overlay/index.ts")
  },
  {
    name: "my-posts-_components-quadrants-overlay-quadrants-overlay",
    path: "/my-posts/_components/quadrants-overlay/quadrants-overlay",
    component: () => import("/app/app/src/pages/my-posts/_components/quadrants-overlay/quadrants-overlay.vue")
  },
  {
    name: "my-posts-_components-select-logo-modal",
    path: "/my-posts/_components/select-logo-modal",
    component: () => import("/app/app/src/pages/my-posts/_components/select-logo-modal/index.ts")
  },
  {
    name: "my-posts-_components-select-logo-modal-select-logo-modal",
    path: "/my-posts/_components/select-logo-modal/select-logo-modal",
    component: () => import("/app/app/src/pages/my-posts/_components/select-logo-modal/select-logo-modal.vue")
  },
  {
    name: "my-posts-_components-social-post-preview-components-social-post-description",
    path: "/my-posts/_components/social-post-preview/components/social-post-description",
    component: () => import("/app/app/src/pages/my-posts/_components/social-post-preview/components/social-post-description/index.ts")
  },
  {
    name: "my-posts-_components-social-post-preview-components-social-post-description-social-post-description",
    path: "/my-posts/_components/social-post-preview/components/social-post-description/social-post-description",
    component: () => import("/app/app/src/pages/my-posts/_components/social-post-preview/components/social-post-description/social-post-description.vue")
  },
  {
    name: "my-posts-_components-social-post-preview",
    path: "/my-posts/_components/social-post-preview",
    component: () => import("/app/app/src/pages/my-posts/_components/social-post-preview/index.ts")
  },
  {
    name: "my-posts-_components-social-post-preview-social-post-preview",
    path: "/my-posts/_components/social-post-preview/social-post-preview",
    component: () => import("/app/app/src/pages/my-posts/_components/social-post-preview/social-post-preview.vue")
  },
  {
    name: "my-posts",
    path: "/my-posts",
    meta: indexDKjtHMbsYEgX0zVfhlVVCEW9Kr_45NdBN6B1ISi8_45a9nIMeta || {},
    component: () => import("/app/app/src/pages/my-posts/index.vue")
  },
  {
    name: "my-posts-my-posts.constants",
    path: "/my-posts/my-posts.constants",
    component: () => import("/app/app/src/pages/my-posts/my-posts.constants.ts")
  },
  {
    name: "oauth2-auth.utils",
    path: "/oauth2/auth.utils",
    component: () => import("/app/app/src/pages/oauth2/auth.utils.ts")
  },
  {
    name: "oauth2-facebook",
    path: "/oauth2/facebook",
    meta: index9OsRO8CoBF1zGw_45or6mGzgBBAc2r5LwsTCdmGspcGaAMeta || {},
    component: () => import("/app/app/src/pages/oauth2/facebook/index.vue")
  },
  {
    name: "oauth2-instagram",
    path: "/oauth2/instagram",
    meta: indexoXc4fE9SlofTjxV55ZNHXoEQTYQ5yYdOP4y5BhWuYioMeta || {},
    component: () => import("/app/app/src/pages/oauth2/instagram/index.vue")
  },
  {
    name: "oauth2-linkedin",
    path: "/oauth2/linkedin",
    meta: indexB1uDevV_45gQZWEormi7NAv_7MbSI_457mN8T14Mg2If5ksMeta || {},
    component: () => import("/app/app/src/pages/oauth2/linkedin/index.vue")
  },
  {
    name: "oauth2-twitter",
    path: "/oauth2/twitter",
    meta: indexLfzdDPNSUtJXSZqnjrSyCosLS_xUjXoBSnD6zAKxI68Meta || {},
    component: () => import("/app/app/src/pages/oauth2/twitter/index.vue")
  },
  {
    name: "onboarding",
    path: "/onboarding",
    meta: indexk_45icfvWp0fSYdR38wz3GjapvPZh9Btt64talr8Q91o4Meta || {},
    component: () => import("/app/app/src/pages/onboarding/index.vue")
  },
  {
    name: "org-organizationslug-hubslug-contentslug",
    path: "/org/:organizationslug()/:hubslug()/:contentslug()",
    meta: indexkOfNKEAeKmTMRPhad9XikwslpngurLPmRCu6vSzhX1AMeta || {},
    component: () => import("/app/app/src/pages/org/[organization-slug]/[hub-slug]/[content-slug]/index.vue")
  },
  {
    name: "org-organizationslug-hubslug",
    path: "/org/:organizationslug()/:hubslug()",
    meta: indexwQpnoig1_45HX1qvYmuORAYfUaeR7hrqvkwoRSJ5n_PpoMeta || {},
    component: () => import("/app/app/src/pages/org/[organization-slug]/[hub-slug]/index.vue")
  },
  {
    name: "org-organizationslug",
    path: "/org/:organizationslug()",
    meta: indexEwHKqsFms037KWhkPr7Fjxn5MAqGrZDqFNfSJ9LFrMkMeta || {},
    component: () => import("/app/app/src/pages/org/[organization-slug]/index.vue")
  },
  {
    name: "print-_components-filters-modal-filters-modal",
    path: "/print/_components/filters-modal/filters-modal",
    component: () => import("/app/app/src/pages/print/_components/filters-modal/filters-modal.vue")
  },
  {
    name: "print-_components-filters-modal",
    path: "/print/_components/filters-modal",
    component: () => import("/app/app/src/pages/print/_components/filters-modal/index.ts")
  },
  {
    name: "print-_components-print-content-card-_components-print-preview-modal",
    path: "/print/_components/print-content-card/_components/print-preview-modal",
    component: () => import("/app/app/src/pages/print/_components/print-content-card/_components/print-preview-modal/index.ts")
  },
  {
    name: "print-_components-print-content-card-_components-print-preview-modal-print-preview-modal",
    path: "/print/_components/print-content-card/_components/print-preview-modal/print-preview-modal",
    component: () => import("/app/app/src/pages/print/_components/print-content-card/_components/print-preview-modal/print-preview-modal.vue")
  },
  {
    name: "print-_components-print-content-card",
    path: "/print/_components/print-content-card",
    component: () => import("/app/app/src/pages/print/_components/print-content-card/index.ts")
  },
  {
    name: "print-_components-print-content-card-print-content-card",
    path: "/print/_components/print-content-card/print-content-card",
    component: () => import("/app/app/src/pages/print/_components/print-content-card/print-content-card.vue")
  },
  {
    name: "print-_components-print-contents-list",
    path: "/print/_components/print-contents-list",
    component: () => import("/app/app/src/pages/print/_components/print-contents-list/index.ts")
  },
  {
    name: "print-_components-print-contents-list-print-contents-list",
    path: "/print/_components/print-contents-list/print-contents-list",
    component: () => import("/app/app/src/pages/print/_components/print-contents-list/print-contents-list.vue")
  },
  {
    name: "print-_components-print-filters",
    path: "/print/_components/print-filters",
    component: () => import("/app/app/src/pages/print/_components/print-filters/index.ts")
  },
  {
    name: "print-_components-print-filters-print-filters",
    path: "/print/_components/print-filters/print-filters",
    component: () => import("/app/app/src/pages/print/_components/print-filters/print-filters.vue")
  },
  {
    name: "print-_components-print-history-list",
    path: "/print/_components/print-history-list",
    component: () => import("/app/app/src/pages/print/_components/print-history-list/index.ts")
  },
  {
    name: "print-_components-print-history-list-print-history-list",
    path: "/print/_components/print-history-list/print-history-list",
    component: () => import("/app/app/src/pages/print/_components/print-history-list/print-history-list.vue")
  },
  {
    name: "print-_types",
    path: "/print/_types",
    component: () => import("/app/app/src/pages/print/_types/index.ts")
  },
  {
    name: "print-checkout-_steps-delivery-step",
    path: "/print/checkout/_steps/delivery-step",
    component: () => import("/app/app/src/pages/print/checkout/_steps/delivery-step.vue")
  },
  {
    name: "print-checkout-_steps-payment-step",
    path: "/print/checkout/_steps/payment-step",
    component: () => import("/app/app/src/pages/print/checkout/_steps/payment-step.vue")
  },
  {
    name: "print-checkout-_steps-shipping-step",
    path: "/print/checkout/_steps/shipping-step",
    component: () => import("/app/app/src/pages/print/checkout/_steps/shipping-step.vue")
  },
  {
    name: "print-checkout-checkout",
    path: "/print/checkout/checkout",
    component: () => import("/app/app/src/pages/print/checkout/checkout.vue")
  },
  {
    name: "print-checkout",
    path: "/print/checkout",
    component: () => import("/app/app/src/pages/print/checkout/index.ts")
  },
  {
    name: indexhYQRJU7UtmCSyCuiMFF0RWe6_oyp2GkblSqlsdmGi_YMeta?.name,
    path: "/print",
    component: () => import("/app/app/src/pages/print/index.ts"),
    children: [
  {
    name: "print",
    path: "",
    meta: indexM6bkInLp_452pHbnCl7FNt9Kko7hh7IqIPg4_45Z7JlqzCsMeta || {},
    component: () => import("/app/app/src/pages/print/index.vue")
  }
]
  },
  {
    name: "print-print",
    path: "/print/print",
    component: () => import("/app/app/src/pages/print/print.vue")
  },
  {
    name: "profile-_components-customization-tab-customization-tab",
    path: "/profile/_components/customization-tab/customization-tab",
    component: () => import("/app/app/src/pages/profile/_components/customization-tab/customization-tab.vue")
  },
  {
    name: "profile-_components-customization-tab",
    path: "/profile/_components/customization-tab",
    component: () => import("/app/app/src/pages/profile/_components/customization-tab/index.ts")
  },
  {
    name: "profile-_components-details-tab-details-tab",
    path: "/profile/_components/details-tab/details-tab",
    component: () => import("/app/app/src/pages/profile/_components/details-tab/details-tab.vue")
  },
  {
    name: "profile-_components-details-tab",
    path: "/profile/_components/details-tab",
    component: () => import("/app/app/src/pages/profile/_components/details-tab/index.ts")
  },
  {
    name: "profile-_components-security-tab",
    path: "/profile/_components/security-tab",
    component: () => import("/app/app/src/pages/profile/_components/security-tab/index.ts")
  },
  {
    name: "profile-_components-security-tab-security-tab",
    path: "/profile/_components/security-tab/security-tab",
    component: () => import("/app/app/src/pages/profile/_components/security-tab/security-tab.vue")
  },
  {
    name: "profile",
    path: "/profile",
    meta: index2IySFmSD6BcGqdNrxHe_B2qnss6T7M6o6BXd_4skN9cMeta || {},
    component: () => import("/app/app/src/pages/profile/index.vue")
  },
  {
    name: "promote-_components-promote-breadcrumbs-PromoteBreadcrumbs",
    path: "/promote/_components/promote-breadcrumbs/PromoteBreadcrumbs",
    component: () => import("/app/app/src/pages/promote/_components/promote-breadcrumbs/PromoteBreadcrumbs.vue")
  },
  {
    name: "promote-_components-promote-details-table",
    path: "/promote/_components/promote-details-table",
    component: () => import("/app/app/src/pages/promote/_components/promote-details-table/index.ts")
  },
  {
    name: "promote-_components-promote-details-table-PromoteDetailsTable",
    path: "/promote/_components/promote-details-table/PromoteDetailsTable",
    component: () => import("/app/app/src/pages/promote/_components/promote-details-table/PromoteDetailsTable.vue")
  },
  {
    name: "promote-_components-promote-header",
    path: "/promote/_components/promote-header",
    component: () => import("/app/app/src/pages/promote/_components/promote-header/index.ts")
  },
  {
    name: "promote-_components-promote-header-PromoteHeader",
    path: "/promote/_components/promote-header/PromoteHeader",
    component: () => import("/app/app/src/pages/promote/_components/promote-header/PromoteHeader.vue")
  },
  {
    name: "promote-_components-promote-stat-tile",
    path: "/promote/_components/promote-stat-tile",
    component: () => import("/app/app/src/pages/promote/_components/promote-stat-tile/index.ts")
  },
  {
    name: "promote-_components-promote-stat-tile-PromoteStatTile",
    path: "/promote/_components/promote-stat-tile/PromoteStatTile",
    component: () => import("/app/app/src/pages/promote/_components/promote-stat-tile/PromoteStatTile.vue")
  },
  {
    name: "promote-_constants",
    path: "/promote/_constants",
    component: () => import("/app/app/src/pages/promote/_constants/index.ts")
  },
  {
    name: "promote-_types",
    path: "/promote/_types",
    component: () => import("/app/app/src/pages/promote/_types/index.ts")
  },
  {
    name: "promote",
    path: "/promote",
    component: () => import("/app/app/src/pages/promote/index.vue")
  },
  {
    name: "welcome-screen-_components-cepathil-cepathil",
    path: "/welcome-screen/_components/cepathil/cepathil",
    component: () => import("/app/app/src/pages/welcome-screen/_components/cepathil/cepathil.vue")
  },
  {
    name: "welcome-screen-_components-lightforce-lightforce",
    path: "/welcome-screen/_components/lightforce/lightforce",
    component: () => import("/app/app/src/pages/welcome-screen/_components/lightforce/lightforce.vue")
  },
  {
    name: "welcome-screen",
    path: "/welcome-screen",
    meta: indexf_457sJSTg38okeeVbMbCk7QbUzZnCyHwweENxuBQUcMwMeta || {},
    component: () => import("/app/app/src/pages/welcome-screen/index.vue")
  }
]