import { useApi } from '~/api/index';
import { components, operations } from '~~/api-types';

export type CoOpOnboardingResponseDto =
  components['schemas']['CoOpOnboardingResponseDto'];

export type CoOpTierConfigDto =
  components['schemas']['CoOpTierConfigResponseDto'];

export type UpdateCoOpOnboardingBodyDto =
  components['schemas']['UpdateCoOpOnboardingBodyDto'];

export type PaginatedBusinessesResponseDto =
  components['schemas']['PaginatedBusinessesResponseDto'];

export type BusinessResponseDto = components['schemas']['BusinessResponseDto'];

export type GetBusinessesListQueryParams =
  operations['GetAuthUserBusinessesEndpoint_getUserBusinesses']['parameters']['query'];

export enum TierType {
  TIER_1 = 'tier-1',
  TIER_2 = 'tier-2',
  TIER_3 = 'tier-3',
  TIER_4 = 'tier-4',
  TIER_5 = 'tier-5',
}

export const apiGetTierConfig = (): Promise<CoOpTierConfigDto> => {
  const api = useApi();

  return api('/app/businesses/tiers/configs', {
    method: 'GET',
  });
};

export const apiCreateCoOpOnboarding =
  (): Promise<CoOpOnboardingResponseDto> => {
    const api = useApi();

    return api(`/app/businesses/onboarding`, {
      method: 'POST',
    });
  };

export const apiUpdateCoOpOnboarding = (
  body: UpdateCoOpOnboardingBodyDto,
): Promise<CoOpOnboardingResponseDto> => {
  const api = useApi();

  return api(`/app/businesses/onboarding`, {
    method: 'PATCH',
    body,
  });
};

export const apiGetCoOpOnboarding = (): Promise<CoOpOnboardingResponseDto> => {
  const api = useApi();

  return api('/app/businesses/onboarding', {
    method: 'GET',
  });
};

export const selectActiveBusiness = (businessId: string | number) => {
  const api = useApi();

  return api(`/businesses/${businessId}/select`, {
    method: 'POST',
  });
};

export const getBusinessList = (
  params?: GetBusinessesListQueryParams,
): Promise<PaginatedBusinessesResponseDto> => {
  const api = useApi();

  return api('/businesses', {
    method: 'GET',
    params,
  });
};
