<template>
  <slot name="activator" :activator-props="activatorProps" />

  <Teleport v-if="isOpen" to="#teleports">
    <div
      class="fixed inset-0 z-modal cursor-default bg-[#000000]/20"
      aria-hidden="true"
      @click="closeModal"
    />

    <div
      :id="id"
      ref="dialogRef"
      role="dialog"
      :class="modalClass"
      @keydown.esc="closeModal"
    >
      <slot name="content" :close-modal="closeModal" />
    </div>
  </Teleport>
</template>

<script setup lang="ts">
import { computed, onBeforeUnmount, ref, watch, nextTick } from 'vue';
import { useFocusTrap } from '@vueuse/integrations/useFocusTrap';
import { lock, unlock } from 'tua-body-scroll-lock';
import { getUid } from '@/utils/common';
import { ModalPosition, ModalShape, ModalSize } from '../modal/modal.constants';

type Props = {
  size?: ModalSize;
  shape?: ModalShape;
  position?: ModalPosition;
};

const props = withDefaults(defineProps<Props>(), {
  size: ModalSize.XL,
  shape: ModalShape.ROUNDED,
  position: ModalPosition.CENTER,
});

const isOpen = defineModel<boolean>('open', { default: false });

const dialogRef = ref();
const focusTrap = useFocusTrap(dialogRef, {
  allowOutsideClick: true,
});

const id = `preview-modal-${getUid()}`;

const activatorProps = computed(() => ({
  'aria-haspopup': 'true',
  'aria-expanded': isOpen.value,
  'aria-controls': id,
  onClick() {
    isOpen.value = true;
  },
}));

const classByModalPosition: Record<ModalPosition, string> = {
  [ModalPosition.LEFT]: 'left-0',
  [ModalPosition.CENTER]: 'left-1/2 -translate-x-1/2',
  [ModalPosition.RIGHT]: 'right-0',
};

const classByModalSize: Record<ModalSize, string> = {
  [ModalSize.SM]: 'w-[27.5rem]',
  [ModalSize.MD]: 'w-[36rem]',
  [ModalSize.LG]: 'w-[58.5rem]',
  [ModalSize.XL]: 'w-[70rem]',
};

const classByModalShape: Record<ModalShape, string> = {
  [ModalShape.ROUNDED]: 'rounded-none md:rounded-2xl',
  [ModalShape.SQUARE]: 'rounded-none',
};

const modalClass = computed(() => [
  'fixed z-modal top-1/2 -translate-y-1/2 bg-surface overflow-hidden max-w-full md:max-w-[calc(100%-32px)]',
  classByModalSize[props.size],
  classByModalPosition[props.position],
  classByModalShape[props.shape],
]);

const closeModal = (): void => {
  isOpen.value = false;
};

onBeforeUnmount(() => {
  unlock(dialogRef.value);
  focusTrap.deactivate();
});

watch(
  isOpen,
  () => {
    if (isOpen.value) {
      nextTick(() => {
        lock(dialogRef.value);
        focusTrap.activate();
      });
    } else if (dialogRef.value) {
      unlock(dialogRef.value);
      focusTrap.deactivate();
    }
  },
  { immediate: true },
);
</script>
