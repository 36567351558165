<template>
  <Maintenance v-if="config.public.isMaintenanceEnabled" />
  <template v-else>
    <NuxtLayout>
      <Notifications
        v-for="(notificationType, index) in notificationTypes"
        :key="index"
        width="25rem"
        :group="notificationType"
      >
        <template #body="{ item, close }">
          <Notification
            :type="notificationType"
            :notification="item"
            @close="close"
          />
        </template>
      </Notifications>
      <NuxtLoadingIndicator />
      <NuxtPage />
      <Backdrop v-if="isLoading" class="z-[200]">
        <CircularSpinner />
      </Backdrop>
      <VariablesModal />
    </NuxtLayout>
  </template>
</template>

<script setup lang="ts">
import { useRuntimeConfig } from '#imports';
import { Notification, NotificationType } from '~/components/notification';
import Maintenance from './maintenance.vue';
import { useFavicon } from '@vueuse/core';
import { useOrganizationStore } from '~/store/organization';
import { VariablesModal } from '~/pages/_components/variables-modal';
import { useHubStore } from '~/store/hub';
import { Backdrop } from '~/components/backdrop';
import { useLoaderStore } from '~/store/loader';
import { CircularSpinner } from '~/components/loaders';
const loaderStore = useLoaderStore();
const { isLoading } = storeToRefs(loaderStore);

const notificationTypes = Object.values(NotificationType);
const config = useRuntimeConfig();

const domain = useOrganizationDomain();
const { organization } = storeToRefs(useOrganizationStore());
const { hub } = storeToRefs(useHubStore());

useHead({
  title: organization.value?.name,
});

const rootColorVariables = computed<string>(() => {
  const theme = hub.value ?? organization.value;

  const themeColors = [
    {
      name: '--background-color',
      value: theme === null ? '#F2F5FA' : theme.backgroundColor,
    },
    {
      name: '--on-background-color',
      value: theme === null ? '#042957' : theme.onBackgroundColor,
    },
    {
      name: '--on-background-variant-color',
      value: theme === null ? '#7086A4' : theme.onBackgroundVariantColor,
    },
    {
      name: '--surface-color',
      value: theme === null ? '#FFFFFF' : theme.surfaceColor,
    },
    {
      name: '--on-surface-color',
      value: theme === null ? '#042957' : theme.onSurfaceColor,
    },
    {
      name: '--on-surface-variant-color',
      value: theme === null ? '#7086A4' : theme.onSurfaceVariantColor,
    },
    {
      name: '--help-box-color',
      value: theme === null ? '#042957' : theme.helpBoxColor,
    },
    {
      name: '--on-help-box-color',
      value: theme === null ? '#FFFFFF' : theme.onHelpBoxColor,
    },
    {
      name: '--primary-color',
      value: theme === null ? '#427DFF' : theme.primaryColor,
    },
    {
      name: '--on-primary-color',
      value: theme === null ? '#FFFFFF' : theme.onPrimaryColor,
    },
    {
      name: '--secondary-color',
      value: theme === null ? '#042957' : theme.secondaryColor,
    },
    {
      name: '--on-secondary-color',
      value: theme === null ? '#FFFFFF' : theme.onSecondaryColor,
    },
    {
      name: '--tertiary-color',
      value: theme === null ? '#042957' : theme.tertiaryColor,
    },
    {
      name: '--on-tertiary-color',
      value: theme === null ? '#FFFFFF' : theme.onTertiaryColor,
    },
    {
      name: '--error-color',
      value: theme === null ? '#F62B2B' : theme.errorColor,
    },
    {
      name: '--success-color',
      value: theme === null ? '#34E3A8' : theme.successColor,
    },
    {
      name: '--info-color',
      value: theme === null ? '#3E6FED' : theme.infoColor,
    },
    {
      name: '--warning-color',
      value: theme === null ? '#F6B22B' : theme.warningColor,
    },
    {
      name: '--line-color',
      value: theme === null ? '#CFD9E4' : theme.lineColor,
    },
    {
      name: '--disabled-color',
      value: theme === null ? '#ECF0F4' : theme.disabledColor,
    },
  ];

  const parsedVars = themeColors
    .map(({ name, value }) => `${name}: ${value};`)
    .join('\n');

  return `:root {\n${parsedVars}\n}`;
});

useHead({
  style: [{ textContent: rootColorVariables }],
});

if (
  domain !== null &&
  organization.value?.customDomain === domain &&
  organization.value?.iconUrl
) {
  useFavicon(organization.value.iconUrl);
}
</script>
