import { defineAsyncComponent } from 'vue'
export default {
  "components-left-sidebar-components-sidebar-bottom": defineAsyncComponent(() => import("/app/app/src/layouts/_components/left-sidebar/_components/sidebar-bottom/index.ts").then(m => m.default || m)),
  "components-left-sidebar-components-sidebar-bottom-sidebar-bottom": defineAsyncComponent(() => import("/app/app/src/layouts/_components/left-sidebar/_components/sidebar-bottom/sidebar-bottom.vue").then(m => m.default || m)),
  "components-left-sidebar-components-sidebar-option-sidebar-option": defineAsyncComponent(() => import("/app/app/src/layouts/_components/left-sidebar/_components/sidebar-option/sidebar-option.vue").then(m => m.default || m)),
  "components-left-sidebar": defineAsyncComponent(() => import("/app/app/src/layouts/_components/left-sidebar/index.ts").then(m => m.default || m)),
  "components-left-left-sidebar": defineAsyncComponent(() => import("/app/app/src/layouts/_components/left-sidebar/left-sidebar.vue").then(m => m.default || m)),
  "components-maintenance-banner": defineAsyncComponent(() => import("/app/app/src/layouts/_components/maintenance-banner/index.ts").then(m => m.default || m)),
  "components-maintenance-maintenance-banner-constants": defineAsyncComponent(() => import("/app/app/src/layouts/_components/maintenance-banner/maintenance-banner.constants.ts").then(m => m.default || m)),
  "components-maintenance-maintenance-banner": defineAsyncComponent(() => import("/app/app/src/layouts/_components/maintenance-banner/maintenance-banner.vue").then(m => m.default || m)),
  "components-onboarding-banner": defineAsyncComponent(() => import("/app/app/src/layouts/_components/onboarding-banner/index.ts").then(m => m.default || m)),
  "components-onboarding-onboarding-banner": defineAsyncComponent(() => import("/app/app/src/layouts/_components/onboarding-banner/onboarding-banner.vue").then(m => m.default || m)),
  "components-right-sidebar-components-expandable-list-expandable-list-item": defineAsyncComponent(() => import("/app/app/src/layouts/_components/right-sidebar/_components/expandable-list/expandable-list-item.vue").then(m => m.default || m)),
  "components-right-sidebar-components-expandable-list-expandable-list": defineAsyncComponent(() => import("/app/app/src/layouts/_components/right-sidebar/_components/expandable-list/expandable-list.vue").then(m => m.default || m)),
  "components-right-sidebar-components-expandable-list": defineAsyncComponent(() => import("/app/app/src/layouts/_components/right-sidebar/_components/expandable-list/index.ts").then(m => m.default || m)),
  "components-right-sidebar": defineAsyncComponent(() => import("/app/app/src/layouts/_components/right-sidebar/index.ts").then(m => m.default || m)),
  "components-right-right-sidebar": defineAsyncComponent(() => import("/app/app/src/layouts/_components/right-sidebar/right-sidebar.vue").then(m => m.default || m)),
  "components-sidebar": defineAsyncComponent(() => import("/app/app/src/layouts/_components/sidebar/index.ts").then(m => m.default || m)),
  "components-sidebar-sidebar": defineAsyncComponent(() => import("/app/app/src/layouts/_components/sidebar/sidebar.vue").then(m => m.default || m)),
  "components-top-navigation-components-locale-switcher": defineAsyncComponent(() => import("/app/app/src/layouts/_components/top-navigation/_components/locale-switcher/index.ts").then(m => m.default || m)),
  "components-top-navigation-components-locale-switcher-locale-switcher": defineAsyncComponent(() => import("/app/app/src/layouts/_components/top-navigation/_components/locale-switcher/locale-switcher.vue").then(m => m.default || m)),
  "components-top-navigation": defineAsyncComponent(() => import("/app/app/src/layouts/_components/top-navigation/index.ts").then(m => m.default || m)),
  "components-top-top-navigation": defineAsyncComponent(() => import("/app/app/src/layouts/_components/top-navigation/top-navigation.vue").then(m => m.default || m)),
  centered: defineAsyncComponent(() => import("/app/app/src/layouts/centered.vue").then(m => m.default || m)),
  default: defineAsyncComponent(() => import("/app/app/src/layouts/default.vue").then(m => m.default || m))
}