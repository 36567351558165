<template>
  <component
    :is="props.icon"
    :width="props.size"
    :height="props.size"
    aria-hidden="true"
    class="inline-block"
  />
</template>

<script setup lang="ts">
import { IconSize } from '~/components/icon/icon.constants';

type Props = {
  icon: object;
  size?: IconSize;
};
const props = withDefaults(defineProps<Props>(), {
  size: IconSize.MD,
});
</script>
