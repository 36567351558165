import { useRuntimeConfig, defineNuxtPlugin } from '#imports';
import { init } from '@sentry/vue';
import { IGNORED_SENTRY_ERRORS } from '~/constants/sentry';

export default defineNuxtPlugin((nuxtApp) => {
  const config = useRuntimeConfig();

  if (!process.dev) {
    init({
      app: nuxtApp.vueApp,
      dsn: config.public.sentryDsn,
      tracesSampleRate: 1,
      environment: config.public.environment,
      release: config.public.appVersion,
      ignoreErrors: IGNORED_SENTRY_ERRORS,
    });
  }
});
