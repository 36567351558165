<template>
  <div>
    <ContentNotFound class="w-50 mx-auto mt-8" />
    <div class="font-semibold text-3xl mt-8 text-center text-on-background">
      {{ props.message || $t('common.no-results') }}
    </div>
  </div>
</template>

<script setup lang="ts">
import ContentNotFound from '~/assets/images/content-not-found.svg?component';

type Props = {
  message?: string;
  hideMessage?: boolean;
};
const props = defineProps<Props>();
</script>
