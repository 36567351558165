import { useOrganizationStore } from '~/store/organization';

export default defineNuxtRouteMiddleware(async () => {
  const { $i18n } = useNuxtApp();

  const { organization } = storeToRefs(useOrganizationStore());

  if (
    organization.value &&
    !organization.value.locale.available.includes($i18n.locale.value)
  ) {
    await $i18n.setLocale(organization.value.locale.default);
  }
});
