<template>
  <FormElement
    v-slot="{ isError }"
    :rules="props.rules"
    :input-value="props.modelValue"
    :hide-details="props.hideDetails"
  >
    <div ref="root" v-click-outside="clickOutsideConfig" class="relative">
      <BaseInput
        :label="props.label"
        :label-for-id="parsedId"
        :is-focused="isFocused"
        :disabled="props.disabled"
        role="button"
        aria-haspopup="true"
        :aria-expanded="isOpened.toString()"
        :aria-owns="dropdownId"
        :is-error="isError"
        @click="openOptionArea"
        @keydown.esc.prevent="closeOptionArea"
        @keydown.down.prevent="focusNextOption"
        @keydown.up.prevent="focusPrevOption"
        @keydown.enter.prevent="handleEnter"
      >
        <input
          :id="parsedId"
          v-model="textInputValue"
          :placeholder="
            (props.multiple || !props.modelValue) && props.placeholder
          "
          :readonly="!props.autocomplete"
          :disabled="props.disabled"
          @focus="onFocus"
          @blur="onBlur"
          @input="debouncedAutocompleteEmit"
        />
        <template #append-inner>
          <Icon
            v-if="!isCloseIconDisplayed"
            :icon="ArrowDownSvg"
            @mouseenter="isHovered = true"
            @mouseleave="isHovered = false"
          />
          <Btn
            v-else
            :size="BtnSize.SM"
            icon
            @mouseenter="isHovered = true"
            @mouseleave="isHovered = false"
            @click="clearValue"
          >
            <Icon :icon="CloseSvg" :size="IconSize.SM" />
          </Btn>
        </template>
      </BaseInput>
      <Teleport to="#teleports">
        <div
          ref="dropdown"
          class="z-dropdown"
          :style="{
            'min-width': `${referenceWidth}px`,
          }"
        >
          <ul v-show="isOpened" class="options-area">
            <li
              v-for="(option, index) in props.options"
              :key="index"
              class="options-area-item"
              :class="{
                'option-selected': isOptionSelected(option),
                'option-focused': focusedOptionIndex === index,
              }"
              role="option"
              :aria-selected="isOptionSelected(option).toString()"
              @click="handleSelection(option)"
            >
              <slot name="option" :option="option">
                <checkbox
                  v-if="props.multiple"
                  :value="isOptionSelected(option)"
                  class="mr-2"
                />
                {{
                  props.optionLabelName ? option[props.optionLabelName] : option
                }}
              </slot>
            </li>
          </ul>
        </div>
      </Teleport>
    </div>
  </FormElement>
</template>

<script setup lang="ts" generic="T">
import { computed, ref, watch } from 'vue';
import { DEBOUNCE_DELAY_MS, getUid } from '@/utils/common';
import { FormElement } from '@/components/inputs/_components/form-element';
import { BaseInput } from '@/components/inputs/_components/base-input';
import { Icon, IconSize } from '@/components/icon';
import { Btn, BtnSize } from '@/components/btn';
import ArrowDownSvg from '@/assets/icons/arrow-down.svg?component';
import CloseSvg from '@/assets/icons/close.svg?component';
import debounce from 'lodash/debounce';
import { Checkbox } from '@/components/inputs/checkbox';
import vClickOutside from 'click-outside-vue3/src/v-click-outside';
import { PopperOptions, usePopper } from '~/composables/use-popper';
import { useElementSize } from '@vueuse/core';

type Props = {
  modelValue: T | T[keyof T];
  id?: string;
  label?: string;
  options: Array<T>;
  hideDetails?: boolean;
  rules?: string;
  optionValueName?: string;
  optionLabelName?: string;
  placeholder?: string;
  clearable?: boolean;
  autocomplete?: boolean;
  multiple?: boolean;
  disabled?: boolean;
};

const props = defineProps<Props>();

const emit = defineEmits<{
  (e: 'update:modelValue', value: string | null | string[]): void;
  (e: 'autocomplete', value: string | null): void;
}>();

const dropdown = ref<HTMLElement | null>(null);
const root = ref<HTMLElement | null>(null);

const { width: referenceWidth } = useElementSize(root, undefined, {
  box: 'border-box',
});

const popperOptions: PopperOptions = {
  placement: 'bottom',
  modifiers: [
    {
      name: 'offset',
      options: {
        offset: [0, 2],
      },
    },
  ],
};

const { isOpened } = usePopper({
  reference: root,
  popper: dropdown,
  options: popperOptions,
});

const getOptionValue = (option) => {
  return props.optionValueName ? option[props.optionValueName] : option;
};
const getOptionLabel = (option) => {
  return props.optionLabelName ? option[props.optionLabelName] : option;
};

const textInputValue = ref<string>('');

const debouncedAutocompleteEmit = debounce((event) => {
  emit('autocomplete', event.target.value);
}, DEBOUNCE_DELAY_MS);

watch(
  () => props.modelValue,
  (newVal) => {
    if (newVal === null) {
      textInputValue.value = '';
      return;
    }
    if (!props.multiple) {
      const option = props.options.find((option) => {
        return getOptionValue(option) === newVal;
      });
      if (option) {
        textInputValue.value = getOptionLabel(option);
      }
    }
  },
  { immediate: true },
);

const isFocused = ref<boolean>(false);
const isHovered = ref<boolean>(false);

const focusedOptionIndex = ref<number | null>(null);
const dropdownId = computed(() => `dropdown-${props.id ?? getUid()}`);
const parsedId = computed<string>(() => {
  return props.id ?? `input-${getUid()}`;
});
const isCloseIconDisplayed = computed<boolean>(() => {
  return Boolean(props.clearable && isHovered.value && props.modelValue);
});
const onFocus = () => {
  isFocused.value = true;
};
const onBlur = () => {
  isFocused.value = false;
};

const clearValue = () => {
  emit('update:modelValue', null);
};
const openOptionArea = () => {
  if (!props.disabled) {
    isOpened.value = true;
  }
};
const closeOptionArea = () => {
  isOpened.value = false;
  focusedOptionIndex.value = null;
};

const clickOutsideConfig = ref({
  handler: closeOptionArea,
  // vue-click-outside has an issue with usage of Teleport in mobile browsers
  middleware: (event: MouseEvent) => {
    if (!event.target) return true;
    const target = event.target as HTMLElement;
    const isInsideDropdown =
      dropdown.value?.contains(target) ||
      target.closest('.options-area') !== null;
    return !isInsideDropdown;
  },
});

const focusNextOption = () => {
  openOptionArea();
  if (focusedOptionIndex.value === null) {
    focusedOptionIndex.value = 0;
  } else if (focusedOptionIndex.value < props.options.length - 1) {
    focusedOptionIndex.value += 1;
  }
};
const focusPrevOption = () => {
  openOptionArea();
  if (focusedOptionIndex.value === null) {
    focusedOptionIndex.value = props.options.length - 1;
  } else if (focusedOptionIndex.value > 0) {
    focusedOptionIndex.value -= 1;
  }
};

const handleSelection = (option) => {
  const optionValue = getOptionValue(option);

  if (!props.multiple) {
    emit('update:modelValue', optionValue);
    closeOptionArea();
    return;
  }

  const alreadySelectedOption = props.modelValue.find((selectedOption) => {
    return selectedOption === optionValue;
  });

  if (alreadySelectedOption) {
    emit(
      'update:modelValue',
      props.modelValue.filter(
        (selectedOption) => selectedOption !== alreadySelectedOption,
      ),
    );
  } else {
    emit('update:modelValue', [...props.modelValue, optionValue]);
  }
};
const handleEnter = () => {
  if (focusedOptionIndex.value !== null) {
    handleSelection(props.options[focusedOptionIndex.value]);
  }
};
const isOptionSelected = (option) => {
  const optionValue = getOptionValue(option);

  if (props.multiple) {
    return props.modelValue.includes(optionValue);
  }

  if (props.modelValue === null) {
    return false;
  }
  return props.modelValue === getOptionValue(option);
};
</script>

<style scoped>
.options-area {
  @apply absolute max-h-40 w-full overflow-y-auto bg-surface border rounded p-2 z-10;
  &-item {
    @apply py-1 px-2 rounded my-1 cursor-pointer flex items-center;
    &.option-selected {
      @apply backdrop-brightness-75;
    }
    &.option-focused {
      @apply backdrop-brightness-50;
    }
    &:hover {
      @apply backdrop-brightness-50;
    }
  }
}
</style>
