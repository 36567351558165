import { RouteLocationNormalized } from 'vue-router';
import { getFirstQueryParam } from '~/utils/common';
import { navigateTo } from '#imports';

export const socialStateRedirect = (to: RouteLocationNormalized) => {
  const state = getFirstQueryParam(to.query.state);

  if (!state) {
    return navigateTo('/');
  }

  const socialRedirectUrlHex = state.slice(state.indexOf('-') + 1);

  const socialRedirectUrl = Buffer.from(socialRedirectUrlHex, 'hex').toString();

  try {
    const url = new URL(socialRedirectUrl);

    const queryParams = to.fullPath.slice(to.fullPath.indexOf('?') + 1);

    return navigateTo(
      `${url.origin}${to.path.replace('oauth2', 'auth')}?${queryParams}`,
      {
        external: true,
      },
    );
  } catch {
    return navigateTo('/');
  }
};
