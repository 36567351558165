import { ref, watch, onMounted, onUnmounted } from '#imports';
import type { Ref } from 'vue';
import {
  createPopper,
  VirtualElement,
  OptionsGeneric,
  Modifier,
  Instance,
} from '@popperjs/core';

export type PopperOptions = Partial<
  OptionsGeneric<Partial<Modifier<any, any>>>
> & {
  openedByDefault?: boolean;
};

export const usePopper = ({
  reference,
  popper,
  options,
}: {
  reference: Ref<Element | VirtualElement | null>;
  popper: Ref<HTMLElement | null>;
  options?: PopperOptions;
}) => {
  const popperInstance = ref<Instance | null>(null);
  const isOpened = ref<boolean>(options?.openedByDefault || false);

  onMounted(() => {
    if (reference.value && popper.value) {
      popperInstance.value = createPopper(
        reference.value,
        popper.value,
        options,
      );
    }
  });

  onUnmounted(() => {
    popperInstance.value?.destroy();
  });

  watch(isOpened, (value) => {
    if (value) {
      popperInstance.value?.update();
    }
  });

  return { popperInstance, isOpened };
};
