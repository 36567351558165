import { useOrganizationStore } from '~/store/organization';
import { useAccountStore } from '~/store/account';
import { useBusinessesStore } from '~/store/businesses';

export default defineNuxtRouteMiddleware(async (to) => {
  if (to.name === 'auth-logout' || to.name === 'auth-login') {
    return;
  }

  const organizationStore = useOrganizationStore();
  const { organization } = storeToRefs(organizationStore);
  const accountStore = useAccountStore();
  const { currentAccount } = storeToRefs(accountStore);

  if (
    to.name !== 'welcome-screen' &&
    organization.value?.isWelcomeScreenEnabled &&
    currentAccount.value?.welcomeScreenClosedAt === null
  ) {
    return navigateTo('/welcome-screen');
  } else if (
    to.name === 'welcome-screen' &&
    currentAccount.value?.welcomeScreenClosedAt
  ) {
    return navigateTo('/');
  }

  if (!currentAccount.value || !organization.value?.isCoOp) {
    return;
  }

  const businessesStore = useBusinessesStore();

  const { selectedBusiness, businessesCount, isOnboardingEnforced } =
    storeToRefs(businessesStore);

  await businessesStore.initialize();

  if (businessesCount.value === 0 && to.name === 'business-selection') {
    return navigateTo('/');
  }

  if (businessesCount.value === 1 && to.name === 'business-selection') {
    if (isOnboardingEnforced.value) {
      return navigateTo('/onboarding');
    }
    return navigateTo('/');
  }

  if (businessesCount.value === 0 || to.name === 'business-selection') {
    return;
  }

  if (
    to.name !== 'business-selection' &&
    !selectedBusiness.value &&
    businessesCount.value > 1
  ) {
    return navigateTo('/business-selection');
  }

  if (
    to.name !== 'onboarding' &&
    selectedBusiness.value?.tierConfig &&
    (!selectedBusiness.value.coOpOnboarding ||
      (selectedBusiness.value.tierConfig.isOnboardingRequired &&
        !selectedBusiness.value?.coOpOnboarding?.isCompleted))
  ) {
    return navigateTo('/onboarding');
  }

  if (
    to.name === 'onboarding' &&
    (!selectedBusiness.value?.tierConfig ||
      selectedBusiness.value?.coOpOnboarding?.isCompleted)
  ) {
    return navigateTo('/');
  }
});
