import { useApi } from '~/api';
import { components } from '~~/api-types';

export type UserDto = components['schemas']['UserResponseDto'];

export enum SelfRegisterType {
  SIGN_IN = 'sign-in',
  ENTER_PASSWORD = 'enter-password',
  REQUEST_INVITE = 'request-invite',
  RESEND_WELCOME_INVITE = 'resend-welcome-invite',
  RESEND_CONFIRMATION_INVITE = 'resend-confirmation-invite',
  REGISTER_YOURSELF = 'register-yourself',
}

export enum EmailStatus {
  UNKNOWN = 'unknown',
  PENDING = 'pending',
  REGISTERED = 'registered',
  NEW = 'new',
}

export enum InvitationCodeStatus {
  VALID = 'valid',
  INVALID = 'invalid',
}
export type RegisterUserWithExternalProviderBodyDTO =
  components['schemas']['LoginBodyDto'];

export type ForgotPasswordBodyDto =
  components['schemas']['ForgotPasswordBodyDto'];

export const apiSendPasswordResetEmail = (body: ForgotPasswordBodyDto) => {
  const api = useApi();

  return api(`/auth/forgot-password`, {
    method: 'POST',
    body,
  });
};

export type RegisterUserBodyDto = components['schemas']['RegisterUserBodyDto'];

export const apiRegisterUser = (
  body: RegisterUserBodyDto,
): Promise<UserDto> => {
  const api = useApi();

  return api(`/auth/register`, {
    method: 'POST',
    body,
  });
};

export const apiGetLoggedInUserInfo = (): Promise<UserDto> => {
  const api = useApi();

  return api(`/auth/me`, {
    method: 'GET',
  });
};

export type UpdateUserBodyDto = components['schemas']['UpdateAuthUserBodyDto'];

export const apiUpdateAuthUser = (
  body: UpdateUserBodyDto,
): Promise<UserDto> => {
  const api = useApi();

  return api(`/auth/me`, {
    method: 'PATCH',
    body,
  });
};

export type CheckEmailDto = components['schemas']['CheckEmailResponseDto'];
export type CheckEmailBodyDto = components['schemas']['CheckEmailBodyDto'];

export const apiCheckEmail = (
  body: CheckEmailBodyDto,
): Promise<CheckEmailDto> => {
  const api = useApi();

  return api(`/auth/check-email`, {
    method: 'POST',
    body,
  });
};

export type ResendInvitationBodyDto =
  components['schemas']['ResendInvitationBodyDto'];

export const apiResendInvitation = async (
  body: ResendInvitationBodyDto,
): Promise<void> => {
  const api = useApi();

  await api(`/auth/resend-invitation`, {
    method: 'POST',
    body,
  });
};

export type SelfRegistrationUserBodyDto =
  components['schemas']['SelfRegistrationUserBodyDto'];

export const apiSelfRegistration = async (
  body: SelfRegistrationUserBodyDto,
): Promise<void> => {
  const api = useApi();

  await api(`/auth/self-registration`, {
    method: 'POST',
    body,
  });
};

export type ActivateUserBodyDto = components['schemas']['ActivateUserBodyDto'];

export const apiActivateUser = async (
  body: ActivateUserBodyDto,
): Promise<void> => {
  const api = useApi();

  await api(`/auth/activate-user`, {
    method: 'POST',
    body,
  });
};

export type CheckInvitationCodeBodyDto =
  components['schemas']['CheckInvitationCodeBodyDto'];
export type CheckInvitationCodeResponseDto =
  components['schemas']['CheckInvitationCodeResponseDto'];

export const apiCheckInvitationCode = (
  body: CheckInvitationCodeBodyDto,
): Promise<CheckInvitationCodeResponseDto> => {
  const api = useApi();

  return api(`/auth/check-invitation-code-status`, {
    method: 'POST',
    body,
  });
};

export const apiLogin = (
  idToken: string,
  organizationSlug: string,
  rawUserInfo?: Record<string, unknown>,
): Promise<components['schemas']['LoginResponseDto']> => {
  const api = useApi();

  return api(`/auth/login`, {
    method: 'POST',
    body: { idToken, rawUserInfo },
    headers: { organizationSlug },
  });
};
