
// @ts-nocheck


export const localeCodes =  [
  "en-US",
  "fr-FR",
  "fr-CA",
  "es-ES",
  "pt-PT",
  "de-DE",
  "ja-JP"
]

export const localeLoaders = {
  "en-US": [
    {
      key: "locale_en_46json_7b4667e1",
      load: () => import("#nuxt-i18n/7b4667e1" /* webpackChunkName: "locale_en_46json_7b4667e1" */),
      cache: true
    }
  ],
  "fr-FR": [
    {
      key: "locale_fr_46json_393165c7",
      load: () => import("#nuxt-i18n/393165c7" /* webpackChunkName: "locale_fr_46json_393165c7" */),
      cache: true
    }
  ],
  "fr-CA": [
    {
      key: "locale_fr_45CA_46json_a582bab8",
      load: () => import("#nuxt-i18n/a582bab8" /* webpackChunkName: "locale_fr_45CA_46json_a582bab8" */),
      cache: true
    }
  ],
  "es-ES": [
    {
      key: "locale_es_46json_5c0a1b26",
      load: () => import("#nuxt-i18n/5c0a1b26" /* webpackChunkName: "locale_es_46json_5c0a1b26" */),
      cache: true
    }
  ],
  "pt-PT": [
    {
      key: "locale_pt_46json_db9065eb",
      load: () => import("#nuxt-i18n/db9065eb" /* webpackChunkName: "locale_pt_46json_db9065eb" */),
      cache: true
    }
  ],
  "de-DE": [
    {
      key: "locale_de_46json_ee70439b",
      load: () => import("#nuxt-i18n/ee70439b" /* webpackChunkName: "locale_de_46json_ee70439b" */),
      cache: true
    }
  ],
  "ja-JP": [
    {
      key: "locale_ja_45JP_46json_838bf89e",
      load: () => import("#nuxt-i18n/838bf89e" /* webpackChunkName: "locale_ja_45JP_46json_838bf89e" */),
      cache: true
    }
  ]
}

export const vueI18nConfigs = []

export const nuxtI18nOptions = {
  restructureDir: "src",
  experimental: {
    localeDetector: "",
    switchLocalePathLinkSSR: false,
    autoImportTranslationFunctions: false,
    typedPages: true,
    typedOptionsAndMessages: false,
    generatedLocaleFilePathFormat: "absolute",
    alternateLinkCanonicalQueries: false,
    hmr: true
  },
  bundle: {
    compositionOnly: true,
    runtimeOnly: false,
    fullInstall: true,
    dropMessageCompiler: false,
    optimizeTranslationDirective: true
  },
  compilation: {
    jit: true,
    strictMessage: true,
    escapeHtml: false
  },
  customBlocks: {
    defaultSFCLang: "json",
    globalSFCScope: false
  },
  locales: [
    {
      code: "en-US",
      language: "en-US",
      name: "English",
      files: [
        "/app/app/src/locales/en.json"
      ]
    },
    {
      code: "fr-FR",
      language: "fr-FR",
      name: "Français",
      files: [
        "/app/app/src/locales/fr.json"
      ]
    },
    {
      code: "fr-CA",
      language: "fr-CA",
      name: "Français (Canada)",
      files: [
        "/app/app/src/locales/fr-CA.json"
      ]
    },
    {
      code: "es-ES",
      language: "es-ES",
      name: "Español",
      files: [
        "/app/app/src/locales/es.json"
      ]
    },
    {
      code: "pt-PT",
      language: "pt-PT",
      name: "Português",
      files: [
        "/app/app/src/locales/pt.json"
      ]
    },
    {
      code: "de-DE",
      language: "de-DE",
      name: "Deutsch",
      files: [
        "/app/app/src/locales/de.json"
      ]
    },
    {
      code: "ja-JP",
      language: "ja-JP",
      name: "日本語",
      files: [
        "/app/app/src/locales/ja-JP.json"
      ]
    }
  ],
  defaultLocale: "en-US",
  defaultDirection: "ltr",
  routesNameSeparator: "___",
  trailingSlash: false,
  defaultLocaleRouteNameSuffix: "default",
  strategy: "no_prefix",
  lazy: true,
  langDir: "locales",
  rootRedirect: undefined,
  detectBrowserLanguage: {
    alwaysRedirect: false,
    cookieCrossOrigin: false,
    cookieDomain: null,
    cookieKey: "i18n_redirected",
    cookieSecure: false,
    fallbackLocale: "",
    redirectOn: "root",
    useCookie: true
  },
  differentDomains: false,
  baseUrl: "",
  customRoutes: "page",
  pages: {},
  skipSettingLocaleOnNavigate: false,
  types: "composition",
  debug: false,
  parallelPlugin: false,
  multiDomainLocales: false,
  i18nModules: []
}

export const normalizedLocales = [
  {
    code: "en-US",
    language: "en-US",
    name: "English",
    files: [
      {
        path: "/app/app/src/locales/en.json",
        cache: undefined
      }
    ]
  },
  {
    code: "fr-FR",
    language: "fr-FR",
    name: "Français",
    files: [
      {
        path: "/app/app/src/locales/fr.json",
        cache: undefined
      }
    ]
  },
  {
    code: "fr-CA",
    language: "fr-CA",
    name: "Français (Canada)",
    files: [
      {
        path: "/app/app/src/locales/fr-CA.json",
        cache: undefined
      }
    ]
  },
  {
    code: "es-ES",
    language: "es-ES",
    name: "Español",
    files: [
      {
        path: "/app/app/src/locales/es.json",
        cache: undefined
      }
    ]
  },
  {
    code: "pt-PT",
    language: "pt-PT",
    name: "Português",
    files: [
      {
        path: "/app/app/src/locales/pt.json",
        cache: undefined
      }
    ]
  },
  {
    code: "de-DE",
    language: "de-DE",
    name: "Deutsch",
    files: [
      {
        path: "/app/app/src/locales/de.json",
        cache: undefined
      }
    ]
  },
  {
    code: "ja-JP",
    language: "ja-JP",
    name: "日本語",
    files: [
      {
        path: "/app/app/src/locales/ja-JP.json",
        cache: undefined
      }
    ]
  }
]

export const NUXT_I18N_MODULE_ID = "@nuxtjs/i18n"
export const parallelPlugin = false
export const isSSG = false
export const hasPages = true

export const DEFAULT_COOKIE_KEY = "i18n_redirected"
export const DEFAULT_DYNAMIC_PARAMS_KEY = "nuxtI18nInternal"
export const SWITCH_LOCALE_PATH_LINK_IDENTIFIER = "nuxt-i18n-slp"
/** client **/

/** client-end **/