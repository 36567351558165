<template>
  <Modal
    v-model:open="isActive"
    as-form
    :title="$t('variables-modal.title')"
    :submit-button-text="$t('common.continue')"
    @submit="onSubmit"
  >
    <template #content>
      <div class="py-6">
        <Alert class="mb-6">
          {{ $t('variables-modal.warning') }}
        </Alert>
        <TextInput
          v-if="store.variablesWithError.phoneNumber"
          v-model="formData.phoneNumberVariable"
          :label="$t(`profile.phoneNumber`)"
          :placeholder="$t(`profile.phoneNumber`)"
          :rules="[isRequired]"
        />
        <TextInput
          v-if="store.variablesWithError.company"
          v-model="formData.companyVariable"
          :label="$t(`profile.company`)"
          :placeholder="$t(`profile.company`)"
          :rules="[isRequired]"
        />
        <TextInput
          v-if="store.variablesWithError.website"
          v-model="formData.websiteVariable"
          :label="$t(`profile.website`)"
          :placeholder="$t(`profile.website`)"
          :rules="[isRequired]"
        />
      </div>
    </template>
  </Modal>
</template>

<script setup lang="ts">
import { useVariablesModalStore } from '~/store/variables-modal';
import { TextInput } from '~/components/inputs/text-input';
import { Alert } from '~/components/alert';
import { notify } from '@kyvg/vue3-notification';
import { NotificationType } from '~/components/notification';
import { useAccountStore } from '~/store/account';
import { useLoader } from '~/composables/use-loader';
import { Modal } from '~/components/modals/modal';

const { t } = useI18n();

const store = useVariablesModalStore();
const { isActive } = storeToRefs(store);
const accountStore = useAccountStore();

const formData = ref({
  phoneNumberVariable: '',
  companyVariable: '',
  websiteVariable: '',
});

const resetFormData = () => {
  formData.value = {
    phoneNumberVariable: '',
    companyVariable: '',
    websiteVariable: '',
  };
};

watch(isActive, () => {
  resetFormData();
});

const onSubmit = () =>
  useLoader({
    action: async () => {
      await accountStore.updateAccountVariables({
        phoneNumberVariable: formData.value.phoneNumberVariable || undefined,
        websiteVariable: formData.value.websiteVariable || undefined,
        companyVariable: formData.value.companyVariable || undefined,
      });

      store.closeVariablesModal();
      await store.onSubmit();
      notify({
        group: NotificationType.SUCCESS,
        title: t('variables-modal.success-message'),
        duration: 5000,
      });
    },
  });
</script>
