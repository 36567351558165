import { useApi } from '~/api';
import { components } from '~~/api-types';

export enum AccountType {
  ADMIN = 'admin',
  MANAGER = 'manager',
  STANDARD = 'standard',
}

export type RequestInvitationDto =
  components['schemas']['RequestInvitationDto'];

export type AccountWithOrganizationDto =
  components['schemas']['AccountWithOrganizationResponseDto'];

export type UpdateAdSectionStepDto =
  components['schemas']['UpdateAdSectionStepDto'];

export type CoOpAddressResponseDto =
  components['schemas']['CoOpAddressResponseDto'];

export type CoOpLogoResponseDto =
  components['schemas']['CoOpLogoResponseDto'][];

export type CreateOrUpdateCoOpLogoDto =
  components['schemas']['CreateOrUpdateCoOpLogoDto'][];

export type CampaignTargetingStepDto =
  components['schemas']['CampaignTargetingStepDto'];

export type AdSectionStepDto = components['schemas']['AdSectionStepDto'];

export type CreateAccountLogoBodyDto =
  components['schemas']['CreateAccountLogoBodyDto'];

export type AccountLogoDto = components['schemas']['AccountLogoResponseDto'];

export type AccountVariablesDto =
  components['schemas']['UpdateAccountVariablesBodyDto'];

export const apiRequestInvitation = async (
  body: RequestInvitationDto,
): Promise<void> => {
  const api = useApi();

  await api('/app/accounts/invitation', {
    method: 'POST',
    body,
  });
};

export const apiGetCurrentAccount = (): Promise<AccountWithOrganizationDto> => {
  const api = useApi();

  return api('/app/accounts/current', {
    method: 'GET',
  });
};

export const apiPatchAccountVariables = (
  variables: AccountVariablesDto,
): Promise<AccountWithOrganizationDto> => {
  const api = useApi();

  return api('/app/accounts/variables', {
    body: variables,
    method: 'PATCh',
  });
};

export const apiGetAccountLogos = (): Promise<AccountLogoDto[]> => {
  const api = useApi();

  return api(`/app/accounts/logos`, {
    method: 'GET',
  });
};

export const apiCreateAccountLogo = (
  body: CreateAccountLogoBodyDto,
): Promise<AccountLogoDto> => {
  const api = useApi();

  return api(`/app/accounts/logos`, {
    method: 'POST',
    body,
  });
};

export const apiDeleteAccountLogo = (
  logoId: number | string,
): Promise<void> => {
  const api = useApi();

  return api(`/app/accounts/logos/${logoId}`, {
    method: 'DELETE',
  });
};

export const apiCloseWelcomeScreen = (): Promise<void> => {
  const api = useApi();

  return api(`/app/accounts/close-welcome-screen`, {
    method: 'POST',
  });
};
