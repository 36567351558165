<template>
  <slot name="activator" :activator-props="activatorProps" />

  <Teleport v-if="isOpen" to="#teleports">
    <div
      class="fixed inset-0 z-modal cursor-default bg-[#000000]/20"
      aria-hidden="true"
      @click="onBackdropClick"
    />

    <component
      :is="props.asForm ? Form : 'div'"
      :id="id"
      ref="dialogRef"
      role="dialog"
      :class="modalClass"
      @keydown.esc="closeModal"
      @submit="onSubmit"
    >
      <div v-if="slots.header" :class="headerClass">
        <slot name="header" :close-modal="closeModal" />
      </div>
      <h2
        v-else
        :class="headerClass"
        class="text-center text-xl text-on-surface py-5 sm:py-7 font-bold leading-none border-b"
      >
        {{ props.title }}
      </h2>

      <div
        v-if="slots.content"
        class="flex-1 px-6 sm:px-8"
        :class="{ 'overflow-y-auto': props.makeContentScrollable }"
      >
        <slot name="content" />
      </div>

      <div v-if="slots.actions" :class="actionsClass">
        <slot name="actions" :close-modal="closeModal" />
      </div>
      <div
        v-else
        :class="actionsClass"
        class="flex justify-between py-5 px-4 sm:px-12 border-t"
      >
        <Btn :size="BtnSize.LG" @click="closeModal">
          {{ $t('common.cancel') }}
        </Btn>
        <Btn
          type="submit"
          :color="BtnColor.PRIMARY"
          :variant="BtnVariant.FILLED"
          :size="BtnSize.LG"
        >
          {{ props.submitButtonText }}
        </Btn>
      </div>
    </component>
  </Teleport>
</template>

<script setup lang="ts">
import { computed, onBeforeUnmount, nextTick, useTemplateRef } from 'vue';
import { useFocusTrap } from '@vueuse/integrations/useFocusTrap';
import { lock, unlock } from 'tua-body-scroll-lock';
import { Form } from 'vee-validate';
import { getUid } from '@/utils/common';
import { ModalPosition, ModalShape, ModalSize } from './modal.constants';
import { Btn, BtnColor, BtnSize, BtnVariant } from '~/components/btn';

type Props = {
  asForm?: boolean;
  closable?: boolean;
  persistent?: boolean;
  fullHeight?: boolean;
  withDividers?: boolean;
  disableFocusTrap?: boolean;
  makeContentScrollable?: boolean;
  size?: ModalSize;
  shape?: ModalShape;
  position?: ModalPosition;
  title?: string;
  submitButtonText?: string;
};

const props = withDefaults(defineProps<Props>(), {
  size: ModalSize.MD,
  shape: ModalShape.ROUNDED,
  position: ModalPosition.CENTER,
});

const emit = defineEmits<{
  close: [];
  submit: [];
}>();

const isOpen = defineModel<boolean>('open', { default: false });

const slots = defineSlots<{
  activator(props: { activatorProps: Record<string, unknown> }): unknown;
  header(props: { closeModal: () => void }): unknown;
  content(): unknown;
  actions(props: { closeModal: () => void }): unknown;
}>();

const dialogRef = useTemplateRef('dialogRef');

const focusTrap = useFocusTrap(dialogRef, {
  allowOutsideClick: true,
});

const id = `modal-${getUid()}`;

const activatorProps = computed(() => ({
  'aria-haspopup': 'true',
  'aria-expanded': isOpen.value,
  'aria-controls': id,
  onClick() {
    isOpen.value = true;
  },
}));

const classByModalPosition: Record<ModalPosition, string> = {
  [ModalPosition.LEFT]: 'left-0',
  [ModalPosition.CENTER]: 'left-1/2 -translate-x-1/2',
  [ModalPosition.RIGHT]: 'right-0',
};

const classByModalSize: Record<ModalSize, string> = {
  [ModalSize.SM]: 'w-[27.5rem]',
  [ModalSize.MD]: 'w-[36rem]',
  [ModalSize.LG]: 'w-[58.5rem]',
  [ModalSize.XL]: 'w-[70rem]',
};

const classByModalShape: Record<ModalShape, string> = {
  [ModalShape.ROUNDED]: 'rounded-none md:rounded-2xl',
  [ModalShape.SQUARE]: 'rounded-none',
};

const modalClass = computed(() => [
  'fixed z-modal top-1/2 -translate-y-1/2 bg-surface overflow-y-auto max-w-full md:max-w-[calc(100%-32px)] flex flex-col h-full',
  classByModalSize[props.size],
  classByModalPosition[props.position],
  classByModalShape[props.shape],
  { 'md:h-auto md:max-h-[calc(100%-32px)]': !props.fullHeight },
]);

const headerClass = 'sticky top-0 bg-inherit z-10';
const actionsClass = 'sticky bottom-0 bg-inherit z-10';

const closeModal = (): void => {
  isOpen.value = false;
  emit('close');
};

const onSubmit = () => {
  emit('submit');
  closeModal();
};

const onBackdropClick = (): void => {
  if (!props.persistent) {
    closeModal();
  }
};

watchEffect(() => {
  if (isOpen.value) {
    nextTick(() => {
      lock(dialogRef.value);
      if (!props.disableFocusTrap) {
        focusTrap.activate();
      }
    });
  } else {
    if (dialogRef.value) {
      unlock(dialogRef.value);
    }
    focusTrap.deactivate();
  }
});

onBeforeUnmount(() => {
  if (dialogRef.value) {
    unlock(dialogRef.value);
  }
  focusTrap.deactivate();
});
</script>
