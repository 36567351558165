import { useApi } from '~/api';
import type { PaginatedContentResponse } from './contents';
import { components } from '~~/api-types';

export type PriceTier = {
  quantity: number;
  pricePerUnit: number;
};

export type PrintMedium = {
  id: number;
  name: string;
  description: string;
  mediumType: string;
  dimensions: string;
  basePrice: number;
  priceTiers: PriceTier[];
};

export type PrintContentListResponse = {
  nodes: any[];
  totalCount: number;
};

export type PrintCheckoutSessionDto =
  components['schemas']['PrintCheckoutSessionDto'];

type Address = {
  fullName: string;
  addressLine1: string;
  addressLine2?: string;
  city: string;
  state: string;
  postalCode: string;
  country: string;
  phone: string;
};

export type CreatePrintCheckoutSessionBodyDto = {
  items: Array<{
    contentId: number;
    printProductId: number;
    quantity: number;
    price: number;
    previewUrl?: string;
  }>;
  shippingDetails: {
    fullName: string;
    addressLine1: string;
    addressLine2?: string;
    city: string;
    state: string;
    postalCode: string;
    country: string;
    phone: string;
  };
  billingDetails: {
    fullName: string;
    addressLine1: string;
    addressLine2?: string;
    city: string;
    state: string;
    postalCode: string;
    country: string;
    phone: string;
  };
  shippingMethod: {
    id: string;
    name: string;
    price: number;
    estimatedDays: string;
  };
};

export type PrintOrder = {
  id: number;
  status: string;
  totalAmount: number;
  createdAt: string;
  trackingNumber?: string;
  items: {
    id: number;
    quantity: number;
    unitPrice: number;
    subtotal: number;
    previewUrl: string;
    product?: {
      id: number;
      name: string;
      description: string;
      dimensions: string;
    };
  }[];
  shippingDetails?: {
    fullName: string;
    addressLine1: string;
    addressLine2?: string;
    city: string;
    state: string;
    postalCode: string;
    country: string;
    phone: string;
  };
};

export type PaginatedOrdersResponse = {
  items: PrintOrder[];
  totalCount: number;
};

export type AddressValidationResponse = {
  resolved: boolean;
  resolvedAddress?: {
    streetLines: string[];
    city: string;
    stateOrProvinceCode: string;
    postalCode: string;
  };
  notifications?: Array<{
    message: string;
  }>;
};

export type ShippingRate = {
  serviceType: string;
  serviceName: string;
  transitDays: string;
  price: number;
};

export const apiGetPrintMediums = (): Promise<PrintMedium[]> => {
  const api = useApi();
  return api('/app/print/mediums', {
    method: 'GET',
  });
};

export const apiGetPrintContentsList = (params?: {
  page?: number;
  perPage?: number;
  search?: string;
  printMediumIds?: number[];
  tagsIds?: number[];
}): Promise<PaginatedContentResponse> => {
  const api = useApi();

  const queryParams = {
    ...params,
    printMediumIds: params?.printMediumIds?.length
      ? params.printMediumIds.join(',')
      : undefined,
  };

  return api('/app/print/contents', {
    method: 'GET',
    params: queryParams,
  });
};

export const apiGetPrintHistory = (params?: {
  page?: number;
  perPage?: number;
}): Promise<PaginatedOrdersResponse> => {
  const api = useApi();
  return api('/app/print/history', {
    method: 'GET',
    params,
  });
};

export const apiCreatePrintCheckoutSession = (
  body: CreatePrintCheckoutSessionBodyDto,
): Promise<{ clientSecret: string }> => {
  const api = useApi();
  return api('/app/print/checkout', {
    method: 'POST',
    body,
  });
};

export const apiCheckPrintAvailability = (): Promise<{
  available: boolean;
}> => {
  const api = useApi();
  return api('/app/print/availability', {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  });
};

export const apiValidateAddress = (address: {
  line1: string;
  line2?: string;
  city: string;
  state: string;
  postalCode: string;
  country: string;
}): Promise<AddressValidationResponse> => {
  const api = useApi();
  return api('/api/shipping/validate-address', {
    method: 'POST',
    body: { address },
  });
};

export const apiGetShippingRates = (params: {
  toAddress: {
    postalCode: string;
    country: string;
  };
  weight: number;
  items?: Array<{ id: string; weight: number; quantity: number }>;
}): Promise<{ rates: ShippingRate[] }> => {
  const api = useApi();
  return api('/app/shipping/rates', {
    method: 'POST',
    body: params,
  });
};
