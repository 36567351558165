import { defineStore } from 'pinia';
import type { PrintableItem } from '~/pages/print/_types';
import { computed } from 'vue';

type CheckoutState = {
  step: number;
  shippingAddress: {
    name: string;
    email: string;
    phone: string;
    businessName: string;
    address: {
      line1: string;
      line2?: string;
      city: string;
      state: string;
      postal_code: string;
      country: string;
    };
  };
  shippingRate: {
    service: string;
    amount: number;
    estimatedDays: string;
  } | null;
  taxAmount: number;
};

type Bundle = {
  quantity: number;
  tier: {
    quantity: number;
    pricePerUnit: number;
  };
};

export const usePrintStore = defineStore('print', {
  state: () => ({
    selectedItems: {} as Record<string, PrintableItem>,
    quantities: {} as Record<string, number>,
    checkout: {
      step: 1,
      shippingAddress: {
        name: '',
        email: '',
        phone: '',
        businessName: '',
        address: {
          line1: '',
          line2: '',
          city: '',
          state: '',
          postal_code: '',
          country: 'US',
        },
      },
      shippingRate: null,
      taxAmount: 0,
    } as CheckoutState,
  }),

  getters: {
    hasItems: (state) => Object.values(state.quantities).some((qty) => qty > 0),
    itemCount: (state) => {
      return Object.values(state.quantities).reduce(
        (count, qty) => count + (qty > 0 ? 1 : 0),
        0,
      );
    },

    applicablePriceTiers: (state) => {
      const tiers: Record<string, PriceTier | null> = {};

      for (const [key, item] of Object.entries(state.selectedItems)) {
        const totalQuantity = state.quantities[key] || 0;
        if (!totalQuantity || !item?.printProduct.priceTiers?.length) {
          tiers[key] = null;
          continue;
        }

        const sortedTiers = [...item.printProduct.priceTiers].sort(
          (a, b) => b.quantity - a.quantity,
        );

        tiers[key] =
          sortedTiers.find((tier) => totalQuantity >= tier.quantity) ||
          sortedTiers.at(-1) ||
          null;
      }

      return tiers;
    },

    subtotal(state) {
      return Object.entries(state.selectedItems).reduce(
        (total, [key, item]) => {
          const quantity = state.quantities[key] || 0;
          if (!quantity) return total;

          const sortedTiers = [...(item.printProduct.priceTiers || [])].sort(
            (a, b) => b.quantity - a.quantity,
          );

          const applicableTier =
            sortedTiers.find((tier) => quantity >= tier.quantity) ||
            sortedTiers.at(-1);

          return total + (applicableTier?.pricePerUnit || 0) * quantity;
        },
        0,
      );
    },

    shippingCost: (state): number => {
      return Object.keys(state.selectedItems).length;
    },
    total: (state): number => {
      return state.subtotal + state.shippingCost;
    },

    orderTotals: computed(() => ({
      subtotal: usePrintStore().subtotal,
      salesTax: usePrintStore().subtotal * 0.1,
      shipping: usePrintStore().checkout.shippingRate?.amount || 0,
      total:
        usePrintStore().subtotal +
        usePrintStore().subtotal * 0.1 +
        (usePrintStore().checkout.shippingRate?.amount || 0),
    })),
  },

  actions: {
    setCheckoutData(
      items: Record<string, PrintableItem>,
      quantities: Record<string, number>,
    ) {
      this.selectedItems = items;
      this.quantities = quantities;
    },

    clearCheckoutData() {
      this.selectedItems = {};
      this.quantities = {};
    },

    updateQuantity(key: string, quantity: number) {
      if (quantity > 0) {
        this.quantities[key] = quantity;
      } else {
        Reflect.deleteProperty(this.quantities, key);
        Reflect.deleteProperty(this.selectedItems, key);
      }
    },

    addItem(key: string, item: PrintableItem, quantity: number) {
      if (quantity > 0) {
        this.selectedItems[key] = item;
        this.quantities[key] = quantity;
      } else {
        Reflect.deleteProperty(this.selectedItems, key);
        Reflect.deleteProperty(this.quantities, key);
      }
    },

    setCheckoutStep(step: number) {
      this.checkout.step = step;
    },

    setShippingAddress(address: CheckoutState['shippingAddress']) {
      this.checkout.shippingAddress = address;
    },

    resetCheckout() {
      this.checkout = {
        step: 1,
        shippingAddress: {
          name: '',
          email: '',
          phone: '',
          businessName: '',
          address: {
            line1: '',
            line2: '',
            city: '',
            state: '',
            postal_code: '',
            country: 'US',
          },
        },
        shippingRate: null,
        taxAmount: 0,
      };
    },

    setShippingRate(rate: CheckoutState['shippingRate']) {
      this.checkout.shippingRate = rate;
    },

    setTaxAmount(amount: number) {
      this.checkout.taxAmount = amount;
    },

    resetAll() {
      this.checkout = {
        step: 1,
        shippingAddress: {
          name: '',
          email: '',
          phone: '',
          businessName: '',
          address: {
            line1: '',
            line2: '',
            city: '',
            state: '',
            postal_code: '',
            country: 'US',
          },
        },
        shippingRate: null,
        taxAmount: 0,
      };
      this.selectedItems = {};
      this.quantities = {};
    },
  },
});
