<template>
  <div class="container mx-auto px-4 py-8">
    <div
      v-if="activeTab === TabType.AVAILABLE"
      class="absolute top-20 right-8 z-10"
    >
      <button
        class="relative bg-primary text-[color:var(--on-primary-color)] px-6 py-2 rounded-full font-medium hover:bg-[color:var(--primary-color,#3b68db)] hover:brightness-90 transition-colors shadow-md disabled:bg-gray-300 disabled:opacity-50 disabled:text-gray disabled:cursor-not-allowed"
        :disabled="!hasSelectedItems"
        @click="handleCheckout"
      >
        {{ t('print.checkout-button') }}
        <span
          v-if="selectedCardsCount > 0"
          class="absolute -top-2 -right-2 bg-[white] text-[#417dff] border border-[#417dff] rounded-full w-6 h-6 flex items-center justify-center text-sm font-bold"
        >
          {{ selectedCardsCount }}
        </span>
      </button>
    </div>

    <div class="relative overflow-hidden -mt-16">
      <div class="w-full py-16 text-center">
        <h1 class="text-primary text-4xl font-bold mb-4">Print Store</h1>
        <p class="text-secondary max-w-2xl mx-auto -mt-2">
          A curated collection of printable assets, designed just for you.
          Simply add items to your cart, complete your purchase, and we'll take
          care of the rest!
        </p>
      </div>
    </div>

    <div class="flex gap-8 border-b border-gray-200 mb-8">
      <button
        v-for="tab in tabs"
        :key="tab.value"
        class="pb-2 font-medium"
        :class="[
          activeTab === tab.value
            ? 'text-primary border-b-2 border-primary'
            : 'text-on-surface-variant',
        ]"
        @click="activeTab = tab.value"
      >
        {{ tab.label }}
      </button>
    </div>

    <PrintContentsList
      v-if="activeTab === TabType.AVAILABLE"
      :key="TabType.AVAILABLE"
      @update:quantities="updateQuantities"
      @update:items="updateItems"
    />
    <PrintHistoryList v-else :key="TabType.HISTORY" />
  </div>
</template>

<script setup lang="ts">
import { ref, computed } from 'vue';
import { useI18n } from 'vue-i18n';
import { PrintContentsList } from './_components/print-contents-list';
import { PrintHistoryList } from './_components/print-history-list';
import type { PrintableItem } from './_types';
import { usePrintStore } from '~/store/print';
import { useRoute } from 'vue-router';
import { navigateTo, definePageMeta } from '#imports';
import { authRequired } from '~/middleware/auth-required';

definePageMeta({
  layout: 'default',
  middleware: [authRequired],
});

const { t } = useI18n();
const route = useRoute();

enum TabType {
  AVAILABLE = 'available',
  HISTORY = 'history',
}

const tabs = computed(() => [
  { label: t('print.available'), value: TabType.AVAILABLE },
  { label: t('print.history'), value: TabType.HISTORY },
]);

const activeTab = ref<TabType>(
  route.query.tab === 'history' ? TabType.HISTORY : TabType.AVAILABLE,
);

const printableItems = ref<PrintableItem[]>([]);

const printStore = usePrintStore();

const selectedCardsCount = computed(() => printStore.itemCount);
const hasSelectedItems = computed(() => printStore.hasItems);

const handleCheckout = () => {
  if (!printableItems.value?.length) return;
  if (printStore.hasItems) {
    navigateTo('/checkout');
  }
};

const updateQuantities = (quantities: Record<string, number>) => {
  for (const [key, quantity] of Object.entries(quantities)) {
    const item = printableItems.value.find(
      (item) => `${item.id}-${item.printProduct.id}` === key,
    );

    if (item) {
      if (quantity === 0) {
        printStore.updateQuantity(key, 0);
      } else {
        printStore.addItem(key, item, quantity);
      }
    }
  }
};

const updateItems = (items: PrintableItem[]) => {
  printableItems.value = items || [];
};
</script>
