export enum BtnSize {
  XS = 'xs',
  SM = 'sm',
  MD = 'md',
  LG = 'lg',
}

export enum BtnVariant {
  FILLED = 'filled',
  OUTLINED = 'outlined',
  TEXT = 'text',
}

export enum BtnColor {
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
  TERTIARY = 'tertiary',
  SURFACE = 'surface',
  SUCCESS = 'success',
}

export enum BtnShape {
  ROUNDED = 'shape-rounded',
  CIRCLE = 'shape-circle',
}
