import { defineStore } from 'pinia';
import { ref, computed } from '#imports';
import { apiGetLoggedInUserInfo, UserDto } from '~/api/auth';
import { FetchError } from 'ofetch';
import { getErrorStatus } from '~/api';
import { OAuthCredential } from 'firebase/auth';

export const useAuthUserStore = defineStore('auth-user', () => {
  const token = useCookie<string | null>('xf-token', { default: () => null });
  const authUser = ref<UserDto | null>(null);
  const isLoggedIn = computed<boolean>(() => authUser.value !== null);
  const credentialToLinkOnLogin = ref<null | OAuthCredential>(null);

  const setCredentialToLinkOnLogin = (data: OAuthCredential | null): void => {
    credentialToLinkOnLogin.value = data;
  };

  const initials = computed<string>(() => {
    if (authUser.value === null) {
      return '';
    }
    const [firstName, lastName] = authUser.value.fullName.split(' ');

    if (!lastName) {
      return firstName[0];
    }
    return `${firstName[0]} ${lastName[0]}`;
  });

  const setUser = (user: UserDto) => {
    authUser.value = user;
  };

  const initializeAuthUser = async (): Promise<void> => {
    if (!token.value || authUser.value) {
      return;
    }

    try {
      authUser.value = await apiGetLoggedInUserInfo();
    } catch (err) {
      token.value = null;

      if (getErrorStatus(err) !== 403) {
        throw err;
      }
    }
  };

  const clearSession = () => {
    authUser.value = null;
    token.value = null;
    setCredentialToLinkOnLogin(null);
  };

  return {
    token,
    authUser,
    isLoggedIn,
    initials,
    initializeAuthUser,
    clearSession,
    setUser,
    credentialToLinkOnLogin,
    setCredentialToLinkOnLogin,
  };
});
