<template>
  <PreviewModal :size="ModalSize.XL">
    <template #activator="{ activatorProps }">
      <button v-bind="activatorProps" class="w-full">
        <img
          :src="optimizedImageUrl"
          class="w-full aspect-[4/3] object-cover"
          :alt="content.title"
        />
      </button>
    </template>

    <template #content="{ closeModal }">
      <div class="relative bg-white flex flex-col max-h-[95vh]">
        <button
          class="absolute right-2 top-2 z-10 text-gray hover:text-gray transition-colors bg-white/80 rounded-full p-1"
          @click="closeModal"
        >
          <Icon :icon="XMarkSvg" class="w-6 h-6" />
        </button>

        <div
          class="flex-1 flex items-center justify-center bg-white p-2 overflow-hidden"
        >
          <img
            :src="optimizedImageUrl"
            class="max-h-[80vh] max-w-full object-contain"
            :alt="content.title"
          />
        </div>

        <div class="py-2 px-4 text-center border-t">
          <h3 class="text-lg font-bold text-gray">
            {{ content.title }}
          </h3>
          <p class="text-sm text-gray mt-0.5 line-clamp-1">
            {{ content.description }}
          </p>
        </div>
      </div>
    </template>
  </PreviewModal>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import { PreviewModal } from '~/components/modals/preview-modal';
import { ModalSize } from '~/components/modals/modal/modal.constants';
import { Icon } from '~/components/icon';
import XMarkSvg from '~/assets/icons/xmark.svg?component';
import imageProxyBuilder, { ResizeType } from '@bitpatty/imgproxy-url-builder';
import { useRuntimeConfig } from '#imports';

const config = useRuntimeConfig();

const props = defineProps<{
  content: {
    title: string;
    description: string;
    imageUrl: string | null;
    previewUrl: string | null;
    customPreviewUrl: string | null;
  };
}>();

const optimizedImageUrl = computed(() => {
  const imageSource =
    props.content.customPreviewUrl ||
    props.content.previewUrl ||
    props.content.imageUrl ||
    '';

  return imageProxyBuilder()
    .resize({ type: ResizeType.FILL_DOWN, width: 1200, height: 800 })
    .format('webp')
    .build({
      baseUrl: config.public.imgProxyBaseUrl || '',
      path: imageSource,
      addExtension: true,
    });
});
</script>
