import { $fetch, Headers, FetchOptions, FetchError } from 'ofetch';
import { showError, useRoute, useRuntimeConfig, clearError } from '#imports';
import { useAuthUserStore } from '~/store/auth-user';
import { H3Error } from 'h3';
import { useOrganizationStore } from '~/store/organization';
import { navigateTo } from '#app';
import { consola } from 'consola/basic';

export const organizationSlugHeaderName = 'Organization-Slug';
export const organizationDomainHeaderName = 'Organization-Domain';

const setDefaultHeader = (
  options: FetchOptions,
  headerName: string,
  headerValue: string,
) => {
  if (!options.headers) {
    options.headers = {
      [headerName]: headerValue,
    };
    return;
  }
  if (options.headers instanceof Headers) {
    options.headers.append(headerName, headerValue);
  } else if (Array.isArray(options.headers)) {
    options.headers.push([headerName, headerValue]);
  } else {
    options.headers[headerName] = headerValue;
  }
};

export const useApi = (apiOptions?: { skipAuth?: true }) => {
  const config = useRuntimeConfig();
  const headers = useRequestHeaders(['cookie']);

  const authUser = useAuthUserStore();
  const { organization } = useOrganizationStore();

  return $fetch.create({
    retry: 0,
    baseURL: config.public.apiBaseUrl,
    credentials: 'include',
    headers,
    onRequest({ options }) {
      setDefaultHeader(options, 'request-id', crypto.randomUUID());

      if (organization?.slug !== undefined) {
        setDefaultHeader(
          options,
          organizationSlugHeaderName,
          organization?.slug,
        );
      }
      if (organization?.customDomain) {
        setDefaultHeader(
          options,
          organizationDomainHeaderName,
          organization?.customDomain,
        );
      }

      if (apiOptions?.skipAuth !== true && authUser.token) {
        setDefaultHeader(options, 'Authorization', `Bearer ${authUser.token}`);
      }
    },
  });
};

export const logError = (err: unknown) => {
  if (err instanceof FetchError) {
    consola.error({
      type: 'FetchError',
      message: err.message,
      data: err.data,
      status: err.response?.status,
    });
  } else {
    consola.error(err);
  }
  if (err instanceof Error) {
    consola.info(err.stack);
  }
};

const showUnknownError = (err: any): void => {
  showError(new Error('Unknown error', { cause: err }));
};

export const getErrorStatus = (err: unknown): number | null => {
  if (err instanceof H3Error) {
    return err.statusCode;
  }
  if (err instanceof FetchError && err.response) {
    return err.response.status;
  }

  return null;
};

export const handleApiError = async (err: unknown) => {
  logError(err);

  const errorStatus = getErrorStatus(err);

  if (errorStatus === 401 || errorStatus === 403) {
    const route = useRoute();
    const authUser = useAuthUserStore();

    authUser.clearSession();

    const redirect =
      route.path === '/auth/login'
        ? undefined
        : `/auth/login?redirect=${encodeURIComponent(route.fullPath)}`;

    return await navigateTo(redirect);
  }

  throw createError({
    statusCode: errorStatus ?? 500,
    fatal: true,
    cause: err,
  });
};

export const handleError = async (err: unknown): Promise<void> => {
  logError(err);
  const errorStatus = getErrorStatus(err);

  const authUser = useAuthUserStore();

  if (
    errorStatus === 401 ||
    errorStatus === 403 ||
    (errorStatus === 404 && !authUser.isLoggedIn)
  ) {
    const route = useRoute();

    authUser.clearSession();

    const redirect =
      route.path === '/auth/login'
        ? undefined
        : `/auth/login?redirect=${encodeURIComponent(route.fullPath)}`;

    await clearError({ redirect });
    return;
  }

  if (errorStatus === 404) {
    showError({ statusCode: 404 });
    return;
  }

  return showUnknownError(err);
};
