<template>
  <FormElement
    v-slot="{ isError, validationListeners }"
    :rules="props.rules"
    :input-value="props.modelValue"
    :hide-details="props.hideDetails"
  >
    <BaseInput
      :label="props.label"
      :label-for-id="parsedId"
      :is-error="isError"
      :disabled="props.disabled"
    >
      <input
        v-bind="$attrs"
        :id="parsedId"
        v-maska="mask"
        :placeholder="props.placeholder"
        :value="props.modelValue"
        :type="props.type"
        :disabled="props.disabled"
        v-on="validationListeners"
        @input="onInput"
        @blur="onBlur"
      />
      <template v-for="(_, name) in $slots" #[name]="slotData">
        <slot :name="name" v-bind="slotData" />
      </template>
    </BaseInput>
  </FormElement>
</template>

<script setup lang="ts">
import { ref, computed } from '#imports';
import debounceAction from 'lodash/debounce';
import { getUid } from '@/utils/common';
import { BaseInput } from '../_components/base-input';
import { FormElement } from '../_components/form-element';
import { vMaska } from 'maska/vue';

const props = defineProps({
  modelValue: null,
  id: String,
  label: String,
  placeholder: String,
  type: {
    type: String,
    default: 'text',
  },
  hideDetails: Boolean,
  rules: null,
  debounce: Number,
  disabled: Boolean,
  mask: String,
});
const emit = defineEmits(['update:modelValue']);

const debouncedEmitInput = ref(null);

const parsedId = computed(() => {
  return props.id ?? `input-${getUid()}`;
});

const onInput = (e) => {
  const { target } = e;

  if (!props.debounce) {
    emit('update:modelValue', target.value);
    return;
  }
  debouncedEmitInput.value?.cancel();

  debouncedEmitInput.value = debounceAction(() => {
    emit('update:modelValue', target.value);
  }, props.debounce);
  debouncedEmitInput.value();
};
const onBlur = () => {
  debouncedEmitInput.value?.flush();
};
</script>
