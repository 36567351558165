<template>
  <div>
    <div class="flex justify-between items-center mb-6">
      <div class="flex items-center gap-4">
        <!-- <SearchInput
          v-model="filters.search"
          class="max-w-80"
          :debounce="DEBOUNCE_DELAY_MS"
          hide-details
        /> -->
        <PrintFilters v-model="filters" />
      </div>

      <div class="flex items-center gap-4">
        <Pagination
          v-if="printableItems.length > 0"
          v-model="page"
          :per-page="perPage"
          :total-count="totalCount"
          @update:model-value="handlePageChange"
        />
      </div>
    </div>

    <div v-if="isLoading" class="flex justify-center py-8">
      <CircularSpinner />
    </div>
    <EmptyList
      v-else-if="printableItems.length === 0"
      :message="t('print.no-prints')"
    />
    <div
      v-else
      class="grid gap-6 grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4"
    >
      <PrintContentCard
        v-for="item in printableItems"
        :key="`${item.id}-${item.printProduct.id}`"
        :content="{
          id: item.id,
          title: item.title,
          description: item.description,
          mediumDescription: item.printProduct.name,
          contentTitle: item.contentTitle,
          dimensions: item.printProduct.dimensions,
          imageUrl: item.imageUrl,
          previewUrl: item.previewUrl,
          customPreviewUrl: item.customPreviewUrl,
          printProductId: item.printProduct.id,
          startDate: item.startDate,
          endDate: item.endDate,
          printProduct: {
            ...item.printProduct,
            priceTiers: item.printProduct.priceTiers || [],
          },
        }"
        class="h-full"
        @update:quantities="
          updateFileQuantities(item.id, item.printProduct.id, $event)
        "
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, computed, onMounted, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import { apiGetPrintContentsList, apiGetPrintMediums } from '~/api/print';
import { CircularSpinner } from '~/components/loaders';
import { PrintContentCard } from '../print-content-card';
import { SearchInput } from '~/components/inputs/search-input';
import { Pagination } from '~/components/pagination';
import type { ContentDto } from '~/api/contents';
import { PrintFilters } from '../print-filters';
import type {
  PrintFilters as PrintFiltersType,
  PrintableItem,
} from '../../_types';
import { EmptyList } from '~/pages/_components/empty-list';

const { t } = useI18n();
const contents = ref<ContentDto[]>([]);
const isLoading = ref(true);
const page = ref(1);
const perPage = 12;
const totalCount = ref(0);

const filters = ref<PrintFiltersType>({
  search: '',
  printMediumIds: [],
  tagsIds: [],
});

const fileQuantities = ref<Record<string, number>>({});

const emit = defineEmits<{
  (e: 'update:quantities', value: Record<string, number>): void;
  (e: 'update:items', value: PrintableItem[]): void;
}>();

const updateFileQuantities = (
  contentId: string,
  printProductId: number,
  newQuantities: Record<string, number>,
) => {
  fileQuantities.value = {
    ...fileQuantities.value,
    ...newQuantities,
  };

  emit('update:quantities', fileQuantities.value);
};

const handlePageChange = (newPage: number) => {
  page.value = newPage;
};

const loadContents = async () => {
  isLoading.value = true;
  try {
    const queryParams = {
      page: page.value,
      perPage,
      ...(filters.value.search ? { search: filters.value.search } : {}),
      ...(filters.value.printMediumIds?.length
        ? {
            printMediumIds: filters.value.printMediumIds,
          }
        : {}),
      ...(filters.value.tagsIds?.length
        ? { tagsIds: filters.value.tagsIds }
        : {}),
    };

    const response = await apiGetPrintContentsList(queryParams);

    contents.value = response.nodes || [];
    totalCount.value = response.totalCount || 0;
  } finally {
    isLoading.value = false;
  }
};

watch([page, filters], loadContents, { deep: true });

onMounted(async () => {
  await loadContents();
});

const printableItems = computed(() => {
  const currentDate = new Date();

  return contents.value.flatMap((content) => {
    if (content.status !== 'active') return [];

    const startDate = content.startDate ? new Date(content.startDate) : null;
    const endDate = content.endDate ? new Date(content.endDate) : null;

    if (startDate && startDate > currentDate) return [];
    if (endDate && endDate < currentDate) return [];

    return (content.printProducts || []).map((product) => ({
      id: content.id,
      title: content.title,
      description: content.description,
      contentTitle: content.contentTitle,
      imageUrl: content.imageUrl,
      previewUrl: content.previewUrl,
      customPreviewUrl: content.customPreviewUrl,
      contentType: 'FILES',
      printProduct: {
        ...product,
        priceTiers: product.priceTiers || [],
      },
      printProducts: content.printProducts || [],
      startDate: content.startDate,
      endDate: content.endDate,
      status: content.status,
    }));
  });
});

watch(
  printableItems,
  (items) => {
    emit('update:items', items);
  },
  { immediate: true },
);
</script>
