import { defineStore } from 'pinia';
import { ref } from '#imports';
import { apiGetSingleHub, HubDto } from '~/api/hubs';

export const useHubStore = defineStore('hub', () => {
  const hub = ref<HubDto | null>(null);

  const initializeHub = async (hubSlug?: string): Promise<void> => {
    if (!hubSlug) {
      hub.value = null;
      return;
    }
    if (hubSlug !== hub.value?.slug) {
      hub.value = await apiGetSingleHub(hubSlug);
    }
  };

  return {
    hub,
    initializeHub,
  };
});
