export enum ModalSize {
  SM = 'sm',
  MD = 'md',
  LG = 'lg',
  XL = 'xl',
}

export enum ModalPosition {
  LEFT = 'left',
  CENTER = 'center',
  RIGHT = 'right',
}

export enum ModalShape {
  SQUARE = 'square',
  ROUNDED = 'rounded',
}
