<template>
  <div class="w-full">
    <label
      v-if="props.label"
      :for="props.labelForId"
      class="text-xs font-semibold text-on-surface-variant"
    >
      {{ props.label }}
    </label>
    <div class="flex">
      <div class="icon">
        <slot name="prepend-outer"></slot>
      </div>
      <div
        class="input-area"
        :class="{
          focused: props.isFocused,
          textarea: props.isTextarea,
          error: props.isError,
          disabled: props.disabled,
        }"
      >
        <div class="icon">
          <slot name="prepend-inner"></slot>
        </div>
        <slot></slot>
        <div class="icon">
          <slot name="append-inner"></slot>
        </div>
      </div>
      <div class="icon">
        <slot name="append-outer"></slot>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { baseInputProps } from './base-input.constants';
const props = defineProps({
  ...baseInputProps,
});
</script>

<style>
.input-area {
  @apply w-full px-4 h-10 border rounded-full block text-sm flex bg-surface justify-center;
  input,
  textarea {
    @apply w-full h-full bg-surface;

    &:focus {
      @apply outline-none;
    }
  }

  textarea {
    resize: none;
    @apply py-2;
  }
}

.icon {
  @apply flex items-center;
}

.textarea {
  @apply h-30 rounded-2xl;
}

.error {
  @apply border-error;
}

.disabled {
  @apply bg-disabled;
  input,
  textarea {
    @apply bg-disabled;
  }
}
</style>
