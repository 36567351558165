import { ofetch, Headers } from 'ofetch';
import {
  organizationDomainHeaderName,
  organizationSlugHeaderName,
  useApi,
} from '~/api';
import { components, operations } from '~~/api-types';
import { useRuntimeConfig } from '#imports';

export type OrganizationDto =
  components['schemas']['OrganizationAppResponseDto'];

export type MenuItemDto = components['schemas']['MenuItemResponseDto'];

export const apiGetCurrentOrganization = (data: {
  organizationSlug?: string;
  organizationDomain?: string | null;
}): Promise<OrganizationDto> => {
  const config = useRuntimeConfig();

  const headers = new Headers();

  if (data.organizationSlug !== undefined) {
    headers.append(organizationSlugHeaderName, data.organizationSlug);
  }
  if (data.organizationDomain) {
    headers.append(organizationDomainHeaderName, data.organizationDomain);
  }

  return ofetch(`/app/organizations/current`, {
    retry: 0,
    baseURL: config.public.apiBaseUrl,
    headers,
  });
};

export type GetOrganizationsListQueryParams =
  operations['AppGetOrganizationsListEndpoint_handler']['parameters']['query'];
export type PaginatedOrganizationDto =
  components['schemas']['PaginatedAppOrganizationResponseDto'];

export const apiGetOrganizationList = (
  params?: GetOrganizationsListQueryParams,
): Promise<PaginatedOrganizationDto> => {
  const api = useApi();

  return api(`/app/organizations`, { params });
};
