import { defineStore } from 'pinia';
import { computed, ref, useOrganizationDomain } from '#imports';
import {
  apiGetCurrentOrganization,
  OrganizationDto,
} from '~/api/organizations';
import { getErrorStatus } from '~/api';

export const useOrganizationStore = defineStore('organization', () => {
  const organization = ref<OrganizationDto | null>(null);

  const isPublic = computed(() => organization.value?.isPublic ?? false);
  const displaySignIn = computed(
    () => organization.value?.displaySignInButton ?? true,
  );
  const currentOrganizationId = computed(() => organization.value?.id);
  const set = (currentOrganization: OrganizationDto) => {
    organization.value = currentOrganization;
  };

  const organizationDomain = useOrganizationDomain();

  const initializeOrganization = async (
    organizationSlug?: string,
  ): Promise<void> => {
    if (!organizationDomain && !organizationSlug) {
      organization.value = null;
      return;
    }
    if (
      (organizationSlug && organization.value?.slug !== organizationSlug) ||
      (organizationDomain &&
        organization.value?.customDomain !== organizationDomain)
    ) {
      try {
        organization.value = await apiGetCurrentOrganization({
          organizationSlug,
          organizationDomain,
        });
      } catch (err) {
        if (getErrorStatus(err) !== 404) {
          throw err;
        }
      }
    }
  };

  return {
    organization,
    isPublic,
    displaySignIn,
    currentOrganizationId,
    set,
    initializeOrganization,
  };
});
