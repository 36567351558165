import { required, email, numeric } from '@vee-validate/rules';
import type { Ref } from 'vue';
import { isBefore } from 'date-fns';

export const isRequired = (val: unknown) => {
  if (!required(val)) {
    return 'This field is required';
  }
  return true;
};

export const isEmail = (val: unknown) => {
  if (!email(val)) {
    return 'This field has to be an email';
  }
  return true;
};

export const isNumber = (val: unknown) => {
  if (!numeric(val)) {
    return 'This field has to be numeric';
  }
  return true;
};

export const isEqual = (valToCompare: Ref<unknown>) => (val: unknown) => {
  if (val !== valToCompare.value) {
    return 'Passwords must be equal';
  }
  return true;
};

export const minLength = (minLength: number) => (val: unknown) => {
  if (typeof val !== 'string' || val.length < minLength) {
    return `Field must have at least ${minLength} characters`;
  }
  return true;
};

export const maxLength =
  (maxLength: Ref<number> | number) => (val: unknown) => {
    const max = typeof maxLength === 'number' ? maxLength : maxLength.value;
    if (typeof val !== 'string' || val.length > max) {
      return `Field must have at most ${max} characters`;
    }
    return true;
  };

export const isHourPast = (chosenDay: Ref<string>) => (val: string) => {
  if (chosenDay.value) {
    const chosenDate = new Date(`${chosenDay.value} ${val}`);
    if (isBefore(chosenDate, new Date())) {
      return 'Please choose future time';
    }
  }
  return true;
};

export const isUrlValid = (value: string) => {
  try {
    const url = new URL(value);
    const hostWithoutPrefix = url.host.replace('www.', '');

    const isProtocolValid =
      url.origin.startsWith('http://') || url.origin.startsWith('https://');

    const areHostDotsValid =
      hostWithoutPrefix.includes('.') && !hostWithoutPrefix.endsWith('.');

    return isProtocolValid && areHostDotsValid;
  } catch {
    return false;
  }
};

export const isUrl = (val: string) => {
  if (val && !isUrlValid(val)) {
    return 'This field has to be a valid url';
  }

  return true;
};

export const isPhoneNumber = (val: string) => {
  return /^\+1\d{10}$/.test(val) || !val
    ? true
    : 'This fields has to ba a valid Phone Number';
};
