import { useRequestHeaders, useRuntimeConfig } from '#imports';

export const useOrganizationDomain = (): null | string => {
  const config = useRuntimeConfig();
  const { host } = useRequestHeaders(['host']);

  const enforcedDomain = config.public.localEnforcedOrganizationDomain;

  if (enforcedDomain) {
    return enforcedDomain === 'NONE' ? null : enforcedDomain;
  }

  const currentHost = host ?? globalThis.location.host;

  if (currentHost === config.public.appDomain) {
    return null;
  }

  const domainToUse = currentHost.startsWith('www.')
    ? currentHost.slice(4)
    : currentHost;
  return domainToUse;
};
