<template>
  <div
    class="relative bg-[white] border rounded-lg overflow-hidden transition-all duration-200 flex flex-col"
    :class="[
      selectedQuantity > 0
        ? 'border-[primary] shadow-[0_0_0_1px_#417dff]'
        : 'border-gray',
    ]"
  >
    <div class="relative p-2 pb-0">
      <div class="rounded-lg overflow-hidden">
        <PrintPreviewModal
          :content="{
            ...content,
            imageUrl: content.imageUrl,
            previewUrl: content.previewUrl,
            customPreviewUrl: content.customPreviewUrl,
          }"
        />
      </div>
    </div>

    <div class="p-4 flex-1 flex flex-col">
      <div class="mb-4">
        <h3 class="text-lg font-bold truncate" :title="content.title">
          {{ truncatedTitle }}
        </h3>
        <p class="text-base text-[gray]">
          {{ content.mediumDescription }}
        </p>
        <p class="text-base text-[gray]">({{ content.dimensions }})</p>
      </div>

      <div class="flex-grow"></div>

      <div class="h-6 mb-2">
        <div v-if="dateDisplay" class="flex items-center gap-1">
          <Icon :icon="CalendarSvg" class="w-5 h-5 text-[gray]" />
          <div class="text-2xs text-[gray]">
            {{ dateDisplay }}
          </div>
        </div>
      </div>

      <div class="w-full">
        <SelectInput
          v-model="selectedQuantity"
          :options="quantityOptions"
          placeholder="Qty"
          option-value-name="quantity"
          option-label-name="displayText"
          class="w-full print-select"
          @update:model-value="updateQuantity"
        >
          <template #option="{ option }">
            <div class="flex justify-between w-full">
              <span class="font-medium">
                {{ option.quantity ? option.displayText : 'Clear Selection' }}
              </span>
              <span v-if="option.quantity" class="text-primary">
                ${{ (option.quantity * option.pricePerUnit).toFixed(2) }}
              </span>
            </div>
          </template>
        </SelectInput>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, computed, watch, onMounted } from 'vue';
import { Icon } from '~/components/icon';
import { PrintPreviewModal } from './_components/print-preview-modal';
import CalendarSvg from '~/assets/icons/calendar.svg?component';
import { usePrintStore } from '~/store/print';
import type { PriceTier } from '~/api/print';
import { SelectInput } from '~/components/inputs/select-input';

type Props = {
  content: {
    id: number;
    title: string;
    description: string;
    mediumDescription: string;
    contentTitle: string;
    dimensions: string;
    imageUrl: string | null;
    previewUrl: string | null;
    customPreviewUrl: string | null;
    printProductId: number;
    printProduct: {
      priceTiers: PriceTier[];
      priceOverride?: number | null;
    };
    startDate?: string | null;
    endDate?: string | null;
  };
};

const props = defineProps<Props>();
const emit = defineEmits<{
  (e: 'update:quantities', value: Record<string, number>): void;
}>();

const printStore = usePrintStore();

const selectedQuantity = ref<number | null>(null);

const sortedPriceTiers = computed(() => {
  return [...(props.content.printProduct.priceTiers || [])].sort(
    (a, b) => a.quantity - b.quantity,
  );
});

const getCurrentPrice = computed(() => {
  if (!selectedQuantity.value) return 0;

  const tier = sortedPriceTiers.value.find(
    (tier) => tier.quantity === selectedQuantity.value,
  );

  return tier?.pricePerUnit || 0;
});

const quantityOptions = computed(() => {
  return [
    { quantity: null, displayText: 'Qty', pricePerUnit: 0 },
    ...sortedPriceTiers.value.map((tier) => ({
      ...tier,
      displayText: tier.quantity.toString(),
    })),
  ];
});

const updateQuantity = () => {
  const itemKey = `${props.content.id}-${props.content.printProductId}`;

  if (selectedQuantity.value) {
    printStore.addItem(
      itemKey,
      {
        id: props.content.id,
        title: props.content.title,
        description: props.content.description,
        contentTitle: props.content.contentTitle,
        previewUrl: props.content.previewUrl,
        customPreviewUrl: props.content.customPreviewUrl,
        printProduct: {
          id: props.content.printProductId,
          description: props.content.mediumDescription,
          dimensions: props.content.dimensions,
          basePrice: getCurrentPrice.value,
          priceOverride: props.content.printProduct.priceOverride,
        },
      },
      selectedQuantity.value,
    );
  } else {
    printStore.updateQuantity(itemKey, 0);
    selectedQuantity.value = null;
  }

  emit('update:quantities', {
    [`${props.content.id}-${props.content.printProductId}`]:
      selectedQuantity.value || 0,
  });
};

watch(
  () =>
    printStore.quantities[
      `${props.content.id}-${props.content.printProductId}`
    ],
  (newQuantity) => {
    selectedQuantity.value = newQuantity > 0 ? newQuantity : null;
  },
);

onMounted(() => {
  const itemKey = `${props.content.id}-${props.content.printProductId}`;
  const storeQuantity = printStore.quantities[itemKey];
  selectedQuantity.value = storeQuantity > 0 ? storeQuantity : null;
});

const formatDate = (dateString: string) => {
  return new Date(dateString).toLocaleDateString('en-US', {
    month: 'numeric',
    day: 'numeric',
  });
};

const dateDisplay = computed(() => {
  const { startDate, endDate } = props.content;

  if (endDate) {
    return `Available until ${formatDate(endDate)}`;
  }

  return '';
});

const truncatedTitle = computed(() => {
  const maxLength = 25;
  return props.content.title.length > maxLength
    ? `${props.content.title.slice(0, Math.max(0, maxLength))}...`
    : props.content.title;
});
</script>

<style scoped>
.print-card:hover {
  transform: translateY(-1px);
}

:deep(.print-select) {
  .input-area {
    @apply rounded-lg !important;
    @apply bg-[white] !important;
    @apply border-[gray] !important;
    @apply w-full !important;
  }

  input {
    @apply bg-[white] !important;
    @apply placeholder-[gray] !important;
    @apply text-[gray] !important;
    @apply w-full !important;
  }
}
</style>
