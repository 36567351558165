import { useAuthUserStore } from '~/store/auth-user';
import { navigateTo } from '#imports';

export const authRequired = defineNuxtRouteMiddleware((to) => {
  const authUserStore = useAuthUserStore();

  if (!authUserStore.isLoggedIn) {
    return navigateTo({
      path: '/auth/login',
      query: { redirect: to.fullPath },
    });
  }
});
