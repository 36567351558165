<template>
  <div role="alert" class="flex rounded-lg overflow-hidden">
    <div class="icon-area" :class="`type-${props.type}`">
      <icon :icon="iconTypeMap[props.type]" />
    </div>
    <div class="content-area">
      <slot />
    </div>
  </div>
</template>

<script setup lang="ts">
import { AlertType } from './alert.constants';
import { Icon } from '~/components/icon';
import SuccessSvg from '~/assets/icons/success.svg?component';
import InfoSvg from '~/assets/icons/info.svg?component';
import WarningSvg from '~/assets/icons/warning.svg?component';
import ErrorSvg from '~/assets/icons/error.svg?component';

type Props = {
  type?: AlertType;
};
const props = withDefaults(defineProps<Props>(), {
  type: AlertType.INFO,
});

const iconTypeMap: Record<AlertType, unknown> = {
  [AlertType.SUCCESS]: SuccessSvg,
  [AlertType.INFO]: InfoSvg,
  [AlertType.WARNING]: WarningSvg,
  [AlertType.ERROR]: ErrorSvg,
};
</script>

<style scoped>
.icon-area {
  @apply w-14 flex items-center justify-center text-surface;

  &.type- {
    &success {
      @apply bg-success;
    }
    &info {
      @apply bg-info;
    }
    &error {
      @apply bg-error;
    }
    &warning {
      @apply bg-warning;
    }
  }
}
.content-area {
  @apply flex-1 p-4 text-sm border bg-surface rounded-r-lg;
}
</style>
