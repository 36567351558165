<template>
  <div class="bg-white rounded-xl p-6">
    <div class="overflow-x-auto">
      <div v-if="isLoading" class="py-8 text-center">
        <CircularSpinner />
      </div>
      <div v-else-if="orders.length === 0" class="py-8 text-center">
        {{ t('print.no-orders') }}
      </div>
      <div v-else>
        <div v-if="isMobileOrTablet">
          <div class="flex justify-between items-center mb-4 px-2">
            <div class="text-gray font-medium">
              {{ t('print.print-ordered') }}
            </div>
            <div class="text-gray font-medium">
              {{ t('print.print-status') }}
            </div>
          </div>

          <expandable-table
            :rows="orders"
            :columns="mobilePrintHistoryColumns"
            row-id-field="id"
            :expanded-rows="expandedOrders"
            @update:expanded-rows="expandedOrders = $event"
          >
            <template #row:product="{ row }">
              <div class="font-medium">
                {{ row.items[0]?.product?.name || 'Unknown Product' }}
              </div>
            </template>

            <template #row:trackingStatus="{ row }">
              <div class="flex items-center gap-2">
                <span
                  class="status-badge"
                  :class="getStatusClass(row.trackingStatus)"
                >
                  {{ formatStatus(row.trackingStatus) }}
                </span>
              </div>
            </template>

            <template #expanded-rows="{ row, zebraClass }">
              <tr :class="['border-t border-gray-100', zebraClass]">
                <td colspan="3" class="p-4">
                  <div class="grid grid-cols-2 gap-y-3 text-sm">
                    <div class="text-gray">{{ t('print.date-ordered') }}</div>
                    <div>{{ formatDate(row.createdAt) }}</div>

                    <div class="text-gray">{{ t('print.order-id') }}</div>
                    <div>{{ row.orderNumber }}</div>

                    <div class="text-gray">{{ t('print.quantity') }}</div>
                    <div>{{ getTotalQuantity(row.items) }}</div>

                    <div class="text-gray">{{ t('print.total-cost') }}</div>
                    <div class="font-medium">
                      ${{ row.totalAmount.toFixed(2) }}
                    </div>

                    <div class="text-gray">{{ t('print.subtotal') }}</div>
                    <div>${{ calculateSubtotal(row.items).toFixed(2) }}</div>

                    <div class="text-gray">{{ t('print.shipping') }}</div>
                    <div>${{ row.shippingCost.toFixed(2) }}</div>

                    <div class="text-gray">{{ t('print.tax') }}</div>
                    <div>${{ row.taxAmount.toFixed(2) }}</div>

                    <div class="text-gray">{{ t('print.tracking') }}</div>
                    <div>
                      <template v-if="row.trackingNumber">
                        <a
                          :href="getTrackingUrl(row.trackingNumber)"
                          class="text-blue hover:underline"
                          target="_blank"
                          rel="noopener noreferrer"
                          @click.stop
                        >
                          {{ row.trackingNumber }}
                        </a>
                      </template>
                      <template v-else>
                        <span class="text-[#6b7280]">{{
                          t('print.tracking-not-available')
                        }}</span>
                      </template>
                    </div>
                  </div>

                  <div class="mt-6 space-y-4">
                    <div
                      v-for="item in row.items"
                      :key="item.id"
                      class="border-t border-gray-100 pt-4"
                    >
                      <div class="text-gray font-medium">
                        {{ item.product?.name || 'Unknown Product' }} (x{{
                          item.quantity
                        }})
                      </div>
                      <div class="font-medium mt-1">
                        ${{ (item.quantity * item.unitPrice).toFixed(2) }}
                      </div>
                    </div>
                  </div>
                </td>
              </tr>
            </template>
          </expandable-table>
        </div>

        <expandable-table
          v-else
          :rows="orders"
          :columns="printHistoryColumns"
          enable-horizontal-scroll
          row-id-field="id"
          :expanded-rows="expandedOrders"
          @update:expanded-rows="expandedOrders = $event"
        >
          <template #row:orderNumber="{ row }">
            <span class="font-medium text-[#1f2937]">{{
              row.orderNumber
            }}</span>
          </template>

          <template #row:createdAt="{ row }">
            {{ formatDate(row.createdAt) }}
          </template>

          <template #row:product="{ row }">
            <div class="flex items-center gap-2">
              <span class="font-medium">{{
                row.items[0]?.product?.name || 'Unknown Product'
              }}</span>
              <span v-if="row.items.length > 1" class="text-xs text-[#6b7280]">
                +{{ row.items.length - 1 }} more
              </span>
            </div>
          </template>

          <template #row:quantity="{ row }">
            <span class="font-medium">{{ getTotalQuantity(row.items) }}</span>
          </template>

          <template #row:totalAmount="{ row }">
            <div class="flex flex-col relative">
              <span class="font-medium">${{ row.totalAmount.toFixed(2) }}</span>
              <div class="text-xs h-[3.6em] opacity-0">
                Subtotal: ${{ calculateSubtotal(row.items).toFixed(2) }}
                <br />
                Shipping: ${{ row.shippingCost.toFixed(2) }}
                <br />
                Tax: ${{ row.taxAmount.toFixed(2) }}
              </div>
              <div
                v-if="expandedOrders.includes(row.id)"
                class="text-xs text-[#6b7280] absolute top-6 left-0"
              >
                Subtotal: ${{ calculateSubtotal(row.items).toFixed(2) }}
                <br />
                Shipping: ${{ row.shippingCost.toFixed(2) }}
                <br />
                Tax: ${{ row.taxAmount.toFixed(2) }}
              </div>
            </div>
          </template>

          <template #row:trackingStatus="{ row }">
            <div class="flex flex-col gap-1">
              <span
                class="status-badge"
                :class="getStatusClass(row.trackingStatus)"
              >
                {{ formatStatus(row.trackingStatus) }}
              </span>
            </div>
          </template>

          <template #row:trackingNumber="{ row }">
            <template v-if="row.trackingNumber">
              <a
                :href="getTrackingUrl(row.trackingNumber)"
                class="text-blue hover:underline"
                target="_blank"
                rel="noopener noreferrer"
                @click.stop
              >
                {{ row.trackingNumber }}
              </a>
            </template>
            <template v-else>
              <span class="text-[#6b7280]">{{
                t('print.tracking-not-available')
              }}</span>
            </template>
          </template>

          <template #expanded-rows="{ row, zebraClass }">
            <tr
              v-for="item in row.items"
              :key="item.id"
              :class="['border-t border-gray-100', zebraClass]"
            >
              <td class="p-4 text-sm text-[#6b7280]">
                {{ row.orderNumber }}
              </td>
              <td class="p-4 text-sm text-[#6b7280]">
                {{ formatDate(row.createdAt) }}
              </td>
              <td class="p-4 text-sm font-medium">
                {{ item.product?.name || 'Unknown Product' }}
              </td>
              <td class="p-4 text-sm">
                {{ item.quantity }}
              </td>
              <td class="p-4 text-sm">
                <div class="flex flex-col">
                  <span
                    >${{ (item.quantity * item.unitPrice).toFixed(2) }}</span
                  >
                </div>
              </td>
              <td class="p-4 text-sm">
                <div class="flex flex-col gap-1">
                  <span
                    class="status-badge"
                    :class="getStatusClass(row.trackingStatus)"
                  >
                    {{ formatStatus(row.trackingStatus) }}
                  </span>
                </div>
              </td>
              <td class="p-4 text-sm">
                <template v-if="row.trackingNumber">
                  <a
                    :href="getTrackingUrl(row.trackingNumber)"
                    class="text-blue hover:underline"
                    target="_blank"
                    rel="noopener noreferrer"
                    @click.stop
                  >
                    {{ row.trackingNumber }}
                  </a>
                </template>
                <template v-else>
                  <span class="text-[#6b7280]">{{
                    t('print.tracking-not-available')
                  }}</span>
                </template>
              </td>
              <td class="w-10 p-4"></td>
            </tr>
          </template>
        </expandable-table>
      </div>

      <div v-if="totalPages > 1" class="mt-6 flex justify-center">
        <Pagination
          v-model="page"
          :per-page="perPage"
          :total-count="totalCount"
          @update:page="handlePageChange"
        />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, onMounted, watch, computed } from 'vue';
import { useI18n } from 'vue-i18n';
import { format } from 'date-fns';
import { CircularSpinner } from '~/components/loaders';
import { Pagination } from '~/components/pagination';
import { apiGetPrintHistory } from '~/api/print';
import { usePageQueryParam } from '~/composables/use-query-param';
import { ExpandableTable } from '~/components/expandable-table';
import { useWindowSize } from '@vueuse/core';
import ChevronDownSvg from '~/assets/icons/chevron-down.svg?component';
import { Icon } from '~/components/icon';

type PrintOrderItem = {
  id: string;
  product?: {
    name: string;
  };
  quantity: number;
  unitPrice: number;
  subtotal: number;
};

type PrintOrder = {
  id: string;
  orderNumber: string;
  createdAt: string;
  items: PrintOrderItem[];
  totalAmount: number;
  shippingCost: number;
  taxAmount: number;
  trackingStatus: string;
  trackingNumber?: string;
};

const { t } = useI18n();
const { width } = useWindowSize();

const isMobileOrTablet = computed(() => width.value < 1024);

const orders = ref<PrintOrder[]>([]);
const isLoading = ref(true);
const page = usePageQueryParam();
const perPage = 10;
const totalCount = ref(0);
const totalPages = ref(1);
const expandedOrders = ref<(string | number)[]>([]);

// Mobile view columns
const mobilePrintHistoryColumns = [
  { headerName: t('print.print-ordered'), rowKey: 'product', width: '60%' },
  {
    headerName: t('print.print-status'),
    rowKey: 'trackingStatus',
    width: '40%',
  },
];

// Desktop view columns
const printHistoryColumns = [
  { headerName: t('print.order-id'), rowKey: 'orderNumber', width: '12%' },
  { headerName: t('print.date-ordered'), rowKey: 'createdAt', width: '12%' },
  { headerName: t('print.print-ordered'), rowKey: 'product', width: '20%' },
  { headerName: t('print.quantity'), rowKey: 'quantity', width: '8%' },
  { headerName: t('print.total-cost'), rowKey: 'totalAmount', width: '12%' },
  {
    headerName: t('print.print-status'),
    rowKey: 'trackingStatus',
    width: '16%',
  },
  { headerName: t('print.tracking'), rowKey: 'trackingNumber', width: '20%' },
];

const formatDate = (date: string) => {
  return format(new Date(date), 'MM/dd/yy');
};

const formatStatus = (status: string) => {
  const statusMap: Record<string, string> = {
    HL: 'Ready for Pickup',
    PU: 'Picked Up',
    OC: 'Order Created',
    AR: 'Arrived',
    DP: 'Departed',
    IT: 'In Transit',
    DL: 'Delivered',
    DE: 'Delivery Exception',
    SE: 'Shipping Exception',
    CA: 'Cancelled',
    PENDING: 'Order Created',
    PROCESSING: 'In Transit',
    SHIPPED: 'Departed',
    DELIVERED: 'Delivered',
    CANCELLED: 'Cancelled',
    PAID: 'Order Created',
  };
  return statusMap[status] || status;
};

const getStatusClass = (status: string) => {
  const classes: Record<string, string> = {
    HL: 'bg-[#e6f0ff] text-[#0040ff]',
    PU: 'bg-[#f3e6ff] text-[#6000ff]',
    OC: 'bg-[#fff7e6] text-[#ffa500]',
    AR: 'bg-[#e6ffe6] text-[#00b300]',
    DP: 'bg-[#e6f0ff] text-[#0040ff]',
    IT: 'bg-[#e6f0ff] text-[#0040ff]',
    DL: 'bg-[#e6ffe6] text-[#00b300]',
    DE: 'bg-[#ffe6e6] text-[#ff0000]',
    SE: 'bg-[#ffe6e6] text-[#ff0000]',
    CA: 'bg-[#ffe6e6] text-[#ff0000]',
    PENDING: 'bg-[#fff7e6] text-[#ffa500]',
    PROCESSING: 'bg-[#e6f0ff] text-[#0040ff]',
    SHIPPED: 'bg-[#e6f0ff] text-[#0040ff]',
    DELIVERED: 'bg-[#e6ffe6] text-[#00b300]',
    CANCELLED: 'bg-[#ffe6e6] text-[#ff0000]',
    PAID: 'bg-[#fff7e6] text-[#ffa500]',
  };
  return classes[status] || 'bg-[#f5f5f5] text-[gray]';
};

const getTrackingUrl = (trackingNumber: string) => {
  return `https://www.fedex.com/wtrk/track/?trknbr=${trackingNumber}`;
};

const loadOrders = async () => {
  isLoading.value = true;
  try {
    const response = await apiGetPrintHistory({
      page: page.value,
      perPage,
    });
    orders.value = response.items;
    totalCount.value = response.totalCount;
    totalPages.value = Math.ceil(response.totalCount / perPage);
  } catch {
    orders.value = [];
    totalCount.value = 0;
    totalPages.value = 1;
  } finally {
    isLoading.value = false;
  }
};

const handlePageChange = async (newPage: number) => {
  page.value = newPage;
  await loadOrders();
};

const getTotalQuantity = (items: PrintOrderItem[]) => {
  return items.reduce((total, item) => total + (item.quantity || 0), 0);
};

const calculateSubtotal = (items: PrintOrderItem[]) => {
  return items.reduce((total, item) => total + (item.subtotal || 0), 0);
};

onMounted(() => {
  loadOrders();
});

watch(page, () => {
  loadOrders();
});
</script>

<style scoped>
.status-badge {
  padding: 4px 8px;
  border-radius: 9999px;
  font-size: 0.75rem;
  display: inline-block;
  text-align: center;
  width: fit-content;
}

@media (max-width: 767px) {
  .overflow-x-auto {
    overflow-x: visible;
  }
}
</style>
