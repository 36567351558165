<template>
  <span class="inline-flex items-center leading-none">
    <span class="relative inline-flex items-center" :class="checkboxClass">
      <input
        :id="parsedId"
        :aria-checked="checkboxStatus.ariaChecked"
        type="checkbox"
        class="absolute w-full h-full opacity-0 cursor-pointer"
        @change="emit('update:modelValue', !props.modelValue)"
      />
      <icon :icon="checkboxStatus.icon" :size="IconSize.SM" />
    </span>
    <label v-if="props.label" :for="parsedId" class="text-sm font-normal ml-3">
      {{ props.label }}
    </label>
  </span>
</template>

<script setup lang="ts">
import { computed } from '#imports';
import CheckboxCheckedSvg from '@/assets/icons/checkbox-checked.svg?component';
import CheckboxIndeterminateSvg from '@/assets/icons/checkbox-indeterminate.svg?component';
import CheckboxOutlineSvg from '@/assets/icons/checkbox-outline.svg?component';
import { Icon, IconSize } from '~/components/icon';
import { getUid } from '~/utils/common';

type Props = {
  modelValue: boolean;
  id?: string;
  indeterminate?: boolean;
  label?: string;
};

const props = defineProps<Props>();

const emit = defineEmits(['update:modelValue']);

const parsedId = computed(() => {
  return props.id ?? `checkbox-${getUid()}`;
});

const checkboxStatus = computed(() => {
  if (props.indeterminate) {
    return { ariaChecked: 'mixed' as const, icon: CheckboxIndeterminateSvg };
  }

  if (props.modelValue) {
    return { ariaChecked: 'true' as const, icon: CheckboxCheckedSvg };
  }

  return { ariaChecked: 'false' as const, icon: CheckboxOutlineSvg };
});

const checkboxClass = computed(() => {
  return [
    'checkbox',
    checkboxStatus.value.ariaChecked === 'false'
      ? ['text-line', 'not-checked']
      : 'text-primary',
  ];
});
</script>

<style scoped>
.checkbox {
  &:active {
    transform: scale(0.95);
  }

  & input:focus-visible + svg {
    @apply rounded;
    box-shadow: 0 0 0.6rem 0.06rem rgba(0, 0, 0, 0.1);
  }
}

.not-checked {
  svg {
    @apply fill-surface;
  }
}
</style>
