import { defineStore } from 'pinia';
import { computed, ref } from '#imports';
import {
  AccountVariablesDto,
  AccountWithOrganizationDto,
  apiGetCurrentAccount,
  apiPatchAccountVariables,
} from '~/api/accounts';
import { Nullable } from '~/types/common';

export const useAccountStore = defineStore('account', () => {
  const currentAccount = ref<Nullable<AccountWithOrganizationDto>>(null);
  const accountVariables = computed(() => ({
    phoneNumber: currentAccount.value?.phoneNumberVariable,
    website: currentAccount.value?.websiteVariable,
    company: currentAccount.value?.companyVariable,
  }));

  const setCurrentAccount = async (): Promise<void> => {
    currentAccount.value = await apiGetCurrentAccount();
  };

  const refreshCurrentAccount = async (): Promise<void> => {
    currentAccount.value = await apiGetCurrentAccount();
  };

  const updateAccountVariables = async (
    variables: AccountVariablesDto,
  ): Promise<void> => {
    currentAccount.value = await apiPatchAccountVariables(variables);
  };

  return {
    setCurrentAccount,
    refreshCurrentAccount,
    updateAccountVariables,
    accountVariables,
    currentAccount,
  };
});
