import { LocationQueryValue } from 'vue-router';
import { FetchError } from 'ofetch';

export const getUid = () => Math.random().toString(36).slice(2, 7);

export const getFirstQueryParam = (
  param?: LocationQueryValue | LocationQueryValue[],
) => (Array.isArray(param) ? param[0] : param);

export const getApiErrorMessage = (err: unknown): string => {
  if (!err) {
    return '';
  }

  if (err instanceof FetchError) {
    return err.data?.message ?? 'Unknown error';
  }
  return 'Unknown error';
};

export const DEBOUNCE_DELAY_MS = 500;

export const sleep = (ms: number) =>
  new Promise((resolve) => {
    setTimeout(resolve, ms);
  });

export const isEnumValue = <T extends Record<string, string>>(
  enumObj: T,
  value: string,
): value is T[keyof T] => {
  return Object.values(enumObj).includes(value);
};
