import payload_plugin_BNPKVw66obyvXK_hHapqTHFTsNu_iOJBct_9HiKmR5k from "/app/node_modules/@pinia/nuxt/dist/runtime/payload-plugin.js";
import revive_payload_client_lyWX1wvL7rSItZC0SHHXlpXViCrvKJ1yqCDTSoqslF0 from "/app/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_PtamfB47yqQY_Rh4zjrimgYJkXOrkZ_s7Rhm1JWaAcQ from "/app/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_8hDy86_j6V8TqNyl7F4bftmUgTOJ_p_KIyB_zFbEOAI from "/app/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_X0_XABaJEN3mXNpLz5RSfZdoLq_diKzZIWPF4HinC5Q from "/app/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_ZJ5GCYdi5wuazfUSPOwEPRbbU_oPgLFgZbKGOei_dc0 from "/app/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_kRdP0hsyNSWRJESGlaqVgRQ1Bgm0NpNaCfqmEeSNd0I from "/app/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_x05C_tQGwZj13_VNB3Soz0TV8ALoiwkuOXzHHEW9mKk from "/app/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_9XdWcl3TKzrg9MGaF1HsLanask1MPj0NDHZ6tUMduE0 from "/app/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_z4hgvsiddfKkfXTP6M8M4zG5Cb7sGnDhcryKVM45Di4 from "/app/app/.nuxt/components.plugin.mjs";
import prefetch_client_xwHiCvtnqAuv25cPdV3VTRqlYkBwUqHZbymrolAkzB8 from "/app/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import switch_locale_path_ssr_Hk9vdM15aB_0VkdtA_Usx_xFCjk41oE2KO5i9C8RnMs from "/app/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.js";
import route_locale_detect_9xnjrvR2gs_Q4sLywtkwzH8IawzhEmfdVknj25um3og from "/app/node_modules/@nuxtjs/i18n/dist/runtime/plugins/route-locale-detect.js";
import i18n_aNMRN_VpxP5fGsesKfa8RtP7jI8L9qZpzOP_wk7xuAQ from "/app/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.js";
import add_router_error_handler_i50O0DK4QyXA8Z4ubivn_p1BS6Rp169KYK8QfcBX7Nw from "/app/app/src/plugins/add-router-error-handler.ts";
import add_vue_notification_h4ukYV1_voB1zgVdJdfVzvO2lR6BVpU3At5RL4tcyLY from "/app/app/src/plugins/add-vue-notification.ts";
import app_version_client_eMX_oLDeiNKYxwDgZ0zoVqPNMqTkERMdzYlaDrZC8Ik from "/app/app/src/plugins/app-version.client.ts";
import chunk_error_reload_client_iTeWh7oswybCGLCL7zeVFajlLMad9LyFHyt0Q4g8TEw from "/app/app/src/plugins/chunk-error-reload.client.ts";
import error_logger__EIzG_4pZJQ4PspzKyXZpam79WzMzo37aGj87I5RvEA from "/app/app/src/plugins/error-logger.ts";
import sentry_client_alKR4qwfOmNOPbQ_WTISchX5XdIUEtwpyIuPf735J_4 from "/app/app/src/plugins/sentry.client.ts";
import ssg_detect_UF4Nst39t0UMaSCKhdiI3ti9uHUzHwDPgMWmwbxO5Uw from "/app/node_modules/@nuxtjs/i18n/dist/runtime/plugins/ssg-detect.js";
export default [
  payload_plugin_BNPKVw66obyvXK_hHapqTHFTsNu_iOJBct_9HiKmR5k,
  revive_payload_client_lyWX1wvL7rSItZC0SHHXlpXViCrvKJ1yqCDTSoqslF0,
  unhead_PtamfB47yqQY_Rh4zjrimgYJkXOrkZ_s7Rhm1JWaAcQ,
  router_8hDy86_j6V8TqNyl7F4bftmUgTOJ_p_KIyB_zFbEOAI,
  payload_client_X0_XABaJEN3mXNpLz5RSfZdoLq_diKzZIWPF4HinC5Q,
  navigation_repaint_client_ZJ5GCYdi5wuazfUSPOwEPRbbU_oPgLFgZbKGOei_dc0,
  check_outdated_build_client_kRdP0hsyNSWRJESGlaqVgRQ1Bgm0NpNaCfqmEeSNd0I,
  chunk_reload_client_x05C_tQGwZj13_VNB3Soz0TV8ALoiwkuOXzHHEW9mKk,
  plugin_vue3_9XdWcl3TKzrg9MGaF1HsLanask1MPj0NDHZ6tUMduE0,
  components_plugin_z4hgvsiddfKkfXTP6M8M4zG5Cb7sGnDhcryKVM45Di4,
  prefetch_client_xwHiCvtnqAuv25cPdV3VTRqlYkBwUqHZbymrolAkzB8,
  switch_locale_path_ssr_Hk9vdM15aB_0VkdtA_Usx_xFCjk41oE2KO5i9C8RnMs,
  route_locale_detect_9xnjrvR2gs_Q4sLywtkwzH8IawzhEmfdVknj25um3og,
  i18n_aNMRN_VpxP5fGsesKfa8RtP7jI8L9qZpzOP_wk7xuAQ,
  add_router_error_handler_i50O0DK4QyXA8Z4ubivn_p1BS6Rp169KYK8QfcBX7Nw,
  add_vue_notification_h4ukYV1_voB1zgVdJdfVzvO2lR6BVpU3At5RL4tcyLY,
  app_version_client_eMX_oLDeiNKYxwDgZ0zoVqPNMqTkERMdzYlaDrZC8Ik,
  chunk_error_reload_client_iTeWh7oswybCGLCL7zeVFajlLMad9LyFHyt0Q4g8TEw,
  error_logger__EIzG_4pZJQ4PspzKyXZpam79WzMzo37aGj87I5RvEA,
  sentry_client_alKR4qwfOmNOPbQ_WTISchX5XdIUEtwpyIuPf735J_4,
  ssg_detect_UF4Nst39t0UMaSCKhdiI3ti9uHUzHwDPgMWmwbxO5Uw
]