import { defineStore } from 'pinia';
import { ref } from '#imports';

export const useLoaderStore = defineStore('loader', () => {
  const isLoading = ref<boolean>(false);

  const set = (value: boolean) => {
    isLoading.value = value;
  };

  return { isLoading, set };
});
