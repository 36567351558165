import { useAuthUserStore } from '~/store/auth-user';
import { navigateTo } from '#imports';

export const authForbidden = defineNuxtRouteMiddleware(() => {
  const authUser = useAuthUserStore();

  if (authUser.isLoggedIn) {
    return navigateTo('/');
  }
});
