<template>
  <div
    class="flex flex-col min-h-screen items-center justify-center text-center py-6 px-10 md:px-0"
  >
    <img v-if="!organizationDomain" :src="XfLogo" class="w-50 md:w-50" />
    <MaintenanceSvg class="my-15 h-70 md:h-auto md:my-20 md:w-70" />
    <div>
      <h1 class="font-bold text-4xl mb-5">We'll be back soon!</h1>
      <div class="">
        <div>
          We're making scheduled updates and will be done as quick as possible.
        </div>
        Need help? Please email
        <a class="text-primary underline" href="mailto:support@exfluential.com"
          >support@exfluential.com</a
        >
        in the meantime!
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import XfLogo from '@/assets/images/exfluential-logo.png';
import MaintenanceSvg from '@/assets/images/maintenance.svg?component';
import { useOrganizationDomain } from '~/composables/use-organization-domain';

const organizationDomain = useOrganizationDomain();
</script>
