<template>
  <nav arial-label="Pagination navigation">
    <ul class="inline-flex gap-1 leading-none">
      <li class="block">
        <Btn
          :size="BtnSize.SM"
          :shape="BtnShape.ROUNDED"
          :color="BtnColor.SECONDARY"
          :disabled="props.modelValue <= 1"
          icon
          aria-label="Go to previous page"
          @click="emit('update:modelValue', props.modelValue - 1)"
        >
          <Icon :icon="ArrowLeftSvg" />
        </Btn>
      </li>
      <li v-for="(page, pageIndex) in pageButtons" :key="pageIndex">
        <div
          v-if="page === DOTS_AREA"
          class="w-6 h-6 inline-flex justify-center pt-1"
        >
          …
        </div>
        <Btn
          v-else
          :size="BtnSize.SM"
          :shape="BtnShape.ROUNDED"
          :variant="BtnVariant.OUTLINED"
          :color="
            props.modelValue === page ? BtnColor.PRIMARY : BtnColor.SECONDARY
          "
          icon
          :aria-label="`Go to page ${page}`"
          :aria-current="props.modelValue === page ? 'true' : false"
          @click="emit('update:modelValue', page)"
        >
          {{ page }}
        </Btn>
      </li>
      <li>
        <Btn
          :size="BtnSize.SM"
          :shape="BtnShape.ROUNDED"
          :color="BtnColor.SECONDARY"
          :disabled="props.modelValue >= totalPages"
          icon
          aria-label="Go to next page"
          @click="emit('update:modelValue', props.modelValue + 1)"
        >
          <Icon :icon="ArrowRightSvg" />
        </Btn>
      </li>
    </ul>
  </nav>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import { Btn, BtnSize, BtnShape, BtnVariant, BtnColor } from '@/components/btn';
import { Icon } from '@/components/icon';
import ArrowLeftSvg from '@/assets/icons/arrow-left.svg?component';
import ArrowRightSvg from '@/assets/icons/arrow-right.svg?component';

type Props = {
  modelValue: number;
  perPage: number;
  totalCount: number;
};
const props = defineProps<Props>();

const emit = defineEmits<{
  (e: 'update:modelValue', value: number): void;
}>();

const totalPages = computed(() => {
  return Math.ceil(props.totalCount / props.perPage);
});

const DOTS_AREA = 'dots-area';

const pageButtons = computed<(number | typeof DOTS_AREA)[]>(() => {
  const pages = Array.from({ length: totalPages.value }, (_, i) => i + 1);

  if (totalPages.value <= 7) {
    return pages;
  }

  if (props.modelValue <= 4) {
    return [...pages.slice(0, 5), DOTS_AREA, totalPages.value];
  }

  if (props.modelValue > totalPages.value - 4) {
    return [1, DOTS_AREA, ...pages.slice(totalPages.value - 5)];
  }

  return [
    1,
    DOTS_AREA,
    props.modelValue - 1,
    props.modelValue,
    props.modelValue + 1,
    DOTS_AREA,
    totalPages.value,
  ];
});
</script>
