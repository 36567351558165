<template>
  <div class="flex min-h-screen flex-col">
    <div class="flex items-center justify-center flex-1 mt-8">
      <div class="text-center text-on-surface px-6">
        <template v-if="is404">
          <div class="font-bold text-4xl mt-13">Page Not Found</div>
          <div class="font-bold mt-7">
            The content you are looking for has been moved or is no longer
            available.
          </div>
          <Btn
            :variant="BtnVariant.FILLED"
            :size="BtnSize.LG"
            :color="BtnColor.PRIMARY"
            class="mt-13"
            @click="handleError"
          >
            Go to Dashboard
          </Btn>
        </template>
        <template v-else>
          <div class="font-bold text-5xl">500</div>
          <div class="font-bold text-4xl mt-13">
            Ooops! Internal server error
          </div>
          <div class="font-bold mt-7">
            Please try to refresh this page or feel free to contact us if the
            problem persists
          </div>
        </template>
      </div>
    </div>
    <Footer class="mt-8" />
  </div>
</template>

<script setup lang="ts">
import { Footer } from '~/components/footer';
import { clearError, computed } from '#imports';
import { Btn, BtnVariant, BtnColor, BtnSize } from '~/components/btn';

type Props = {
  error: Record<string, unknown>;
};
const props = defineProps<Props>();

const is404 = computed(() => props.error.statusCode === 404);

const handleError = () => clearError({ redirect: '/' });
</script>
