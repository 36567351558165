import { useApi } from '~/api';
import { components, operations } from '~~/api-types';

export type PaginatedHubDto = components['schemas']['PaginatedHubResponseDto'];
export type GetHubsListQueryParams =
  operations['AdminGetHubsListEndpoint_handler']['parameters']['query'];
export type HubDto = components['schemas']['HubResponseWithTermsDto'];
export type HubTagDto = components['schemas']['HubTagResponseDto'];
export type HubCategoryDto = components['schemas']['HubCategoryResponseDto'];
export type HubSubcategoryDto =
  components['schemas']['HubSubcategoryResponseDto'];
export type ContentSocialDto =
  components['schemas']['ContentSocialResponseDto'];

export const apiGetHubsList = (
  params?: GetHubsListQueryParams,
): Promise<PaginatedHubDto> => {
  const api = useApi();

  return api(`/app/hubs`, {
    params,
  });
};

export const apiGetSingleHub = (hubSlug: string): Promise<HubDto> => {
  const api = useApi();

  return api(`/app/hubs/${hubSlug}`);
};

export const apiAcceptHubTerms = (hubSlug: string): Promise<void> => {
  const api = useApi();

  return api(`/app/hubs/${hubSlug}/accept-terms`, {
    method: 'POST',
  });
};
