<template>
  <Modal
    full-height
    :position="ModalPosition.RIGHT"
    :shape="ModalShape.SQUARE"
    :size="ModalSize.SM"
  >
    <template #activator="{ activatorProps }">
      <Btn
        v-bind="activatorProps"
        icon
        :size="BtnSize.LG"
        :color="BtnColor.TERTIARY"
        :variant="BtnVariant.OUTLINED"
      >
        <Icon :icon="FilterSvg" :size="IconSize.SM" />
      </Btn>
    </template>

    <template #header>
      <div class="flex justify-between items-center pt-8 px-8">
        <h2 class="text-xl font-bold text-secondary">
          {{ $t('filters-modal.filters') }}
        </h2>
        <Btn
          :size="BtnSize.LG"
          :color="BtnColor.PRIMARY"
          @click="resetFilters()"
        >
          {{ $t('filters-modal.reset-filters') }}
        </Btn>
      </div>
    </template>

    <template #content>
      <div class="pt-5">
        <h3 class="font-semibold text-secondary mb-3">
          {{ $t('filters-modal.print-mediums') }}
        </h3>
        <div class="flex flex-col gap-3">
          <Checkbox
            v-for="medium in printMediums"
            :key="medium.id"
            :model-value="filtersToSave.printMediumIds?.includes(medium.id)"
            :label="medium.name"
            @update:model-value="togglePrintMedium(medium.id)"
          />
        </div>
      </div>
    </template>

    <template #actions="{ closeModal }">
      <div class="px-10">
        <div class="flex justify-between border-t pt-6 pb-8">
          <Btn
            :size="BtnSize.LG"
            :color="BtnColor.SECONDARY"
            @click="handleCloseFilters(closeModal)"
          >
            {{ $t('common.cancel') }}
          </Btn>
          <Btn
            :size="BtnSize.LG"
            :variant="BtnVariant.FILLED"
            :color="BtnColor.PRIMARY"
            @click="handleSubmit(closeModal)"
          >
            {{ $t('common.apply') }}
          </Btn>
        </div>
      </div>
    </template>
  </Modal>
</template>

<script setup lang="ts">
import { ref, onMounted, watch } from 'vue';
import { Btn, BtnColor, BtnSize, BtnVariant } from '~/components/btn';
import { Icon, IconSize } from '~/components/icon';
import FilterSvg from '~/assets/icons/filter.svg?component';
import { Checkbox } from '~/components/inputs/checkbox';
import {
  Modal,
  ModalShape,
  ModalSize,
  ModalPosition,
} from '~/components/modals/modal';
import type { PrintFilters } from '../../_types';
import cloneDeep from 'lodash/cloneDeep';
import { apiGetPrintMediums } from '~/api/print';

const filters = defineModel<PrintFilters>({ required: true });
const filtersToSave = ref<PrintFilters>(cloneDeep(filters.value));
const printMediums = ref<{ id: number; name: string }[]>([]);

onMounted(async () => {
  printMediums.value = await apiGetPrintMediums();
});

const togglePrintMedium = (mediumId: number) => {
  filtersToSave.value.printMediumIds ||= [];
  const index = filtersToSave.value.printMediumIds.indexOf(mediumId);

  if (index === -1) {
    filtersToSave.value.printMediumIds.push(mediumId);
  } else {
    filtersToSave.value.printMediumIds.splice(index, 1);
  }
};

const resetFilters = () => {
  filtersToSave.value = {
    search: '',
    printMediumIds: [],
    tagsIds: [],
  };
};

const handleCloseFilters = (closeModal: () => void) => {
  filtersToSave.value = cloneDeep(filters.value);
  closeModal();
};

const handleSubmit = (closeModal: () => void) => {
  filters.value = {
    ...cloneDeep(filtersToSave.value),
    printMediumIds: filtersToSave.value.printMediumIds || [],
    tagsIds: filtersToSave.value.tagsIds || [],
  };
  closeModal();
};

watch(
  () => filters.value,
  (newFilters) => {
    filtersToSave.value = cloneDeep({
      ...newFilters,
      printMediumIds: newFilters.printMediumIds || [],
      tagsIds: newFilters.tagsIds || [],
    });
  },
  { deep: true },
);

onMounted(() => {
  filtersToSave.value = cloneDeep({
    ...filters.value,
    printMediumIds: filters.value.printMediumIds || [],
    tagsIds: filters.value.tagsIds || [],
  });
});
</script>
