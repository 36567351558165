import { computed, Ref, nextTick } from 'vue';
import {
  type LocationQueryValue,
  type LocationQueryValueRaw,
  useRoute,
  useRouter,
} from 'vue-router';
import isEqual from 'lodash/isEqual';

type QueryParamTransformer<T> = {
  fromQuery: (val: LocationQueryValue | LocationQueryValue[]) => T | undefined;
  toQuery: (val: T) => LocationQueryValueRaw | LocationQueryValueRaw[];
};

type UseQueryParamOptions<TTransformed, TDefault> =
  QueryParamTransformer<TTransformed> & { defaultValue?: TDefault };

const useQueryParamsToUpdate = () => {
  return useState(
    'queryParamsToUpdate',
    () => new Map<string, LocationQueryValueRaw | LocationQueryValueRaw[]>(),
  );
};

const updateQueryParam = (
  key: string,
  value: LocationQueryValueRaw | LocationQueryValueRaw[],
  options: {
    route: ReturnType<typeof useRoute>;
    router: ReturnType<typeof useRouter>;
  },
): void => {
  const queryParamsToUpdate = useQueryParamsToUpdate();
  const { route, router } = options;

  queryParamsToUpdate.value.set(key, value);

  nextTick(() => {
    if (queryParamsToUpdate.value.size === 0) {
      return;
    }
    const pendingParams = Object.fromEntries(
      queryParamsToUpdate.value.entries(),
    );

    router.push({
      query: {
        ...route.query,
        ...pendingParams,
      },
    });
  });
};

export const useQueryParams = <
  TParams extends Record<string, UseQueryParamOptions<any, any>>,
>(
  params: TParams,
): Ref<{
  [K in keyof TParams]: undefined extends TParams[K]['defaultValue']
    ? ReturnType<TParams[K]['fromQuery']>
    :
        | NonNullable<ReturnType<TParams[K]['fromQuery']>>
        | TParams[K]['defaultValue'];
}> => {
  const route = useRoute();
  const router = useRouter();

  const parseValue = (
    options: UseQueryParamOptions<any, any>,
    value?: LocationQueryValue | LocationQueryValue[],
  ): unknown => {
    if (value === undefined) {
      return options.defaultValue;
    }

    const transformedVal = options.fromQuery(value);

    if (transformedVal === undefined) {
      return options.defaultValue;
    }

    return transformedVal;
  };

  const state = ref<Record<string, unknown>>({});

  for (const [key, options] of Object.entries(params)) {
    watch(
      () => route.query[key],
      (newVal) => {
        const parsedValue = parseValue(options, newVal);

        if (!isEqual(state.value[key], parsedValue)) {
          state.value[key] = parsedValue;
        }
      },
      { immediate: true },
    );

    watch(
      () => state.value[key],
      (newVal, oldValue) => {
        if (isEqual(newVal, oldValue)) {
          return;
        }

        const calculatedVal =
          newVal === options.defaultValue ? undefined : options.toQuery(newVal);

        updateQueryParam(key, calculatedVal, { route, router });
      },
    );
  }

  return state;
};

export const useQueryParam = <TTransformed, TDefault = undefined>(
  key: string,
  options: UseQueryParamOptions<TTransformed, TDefault>,
): Ref<TTransformed | TDefault> => {
  const param = useQueryParams({
    [key]: options,
  });

  return computed<TTransformed | TDefault>({
    get() {
      return param.value[key] as TTransformed | TDefault;
    },
    set(newVal) {
      param.value[key] = newVal as TTransformed;
    },
  });
};

export const arrayTransformer = <T>(
  transformer: QueryParamTransformer<T>,
): QueryParamTransformer<T[]> => ({
  fromQuery(val) {
    if (!Array.isArray(val)) {
      const parsedVal = transformer.fromQuery(val);

      return parsedVal === undefined ? undefined : [parsedVal];
    }

    const parsedValues = val.map((element) => transformer.fromQuery(element));

    return parsedValues.filter((value) => value !== undefined) as T[];
  },
  toQuery(val) {
    if (!val) {
      return;
    }
    return val
      .map((element) => transformer.toQuery(element))
      .filter((element) => element !== undefined) as string[];
  },
});

export const stringTransformer: QueryParamTransformer<string> = {
  fromQuery(val) {
    if (typeof val !== 'string') {
      return;
    }
    return val;
  },
  toQuery(val) {
    return val;
  },
};

export const booleanTransformer: QueryParamTransformer<boolean> = {
  fromQuery(val) {
    if (val === 'true') {
      return true;
    }
    if (val === 'false') {
      return false;
    }
  },
  toQuery(val) {
    return val ? 'true' : 'false';
  },
};

export const integerTransformer: QueryParamTransformer<number> = {
  fromQuery(val) {
    if (typeof val !== 'string' || val.length === 0) {
      return;
    }
    const numericVal = Number(val);

    if (Number.isInteger(numericVal)) {
      return numericVal;
    }
  },
  toQuery(val) {
    return val.toString();
  },
};

export const enumTransformer = <T extends Record<string, string>>(
  enumObject: T,
): QueryParamTransformer<T[keyof T]> => ({
  fromQuery(val) {
    const enumValues = Object.values(enumObject);

    if (typeof val === 'string' && enumValues.includes(val)) {
      return val as T[keyof T];
    }
  },
  toQuery(val) {
    return val;
  },
});

export const usePageQueryParam = () =>
  useQueryParam('page', { ...integerTransformer, defaultValue: 1 });
