<template>
  <footer class="w-full px-4 border-t max-w-[90rem] mx-auto">
    <div
      v-if="
        organization &&
        organization.isCustomFooterEnabled &&
        organization.customFooterContent
      "
      class="prose mt-6 max-w-full"
      v-html="organization.customFooterContent"
    />
    <div
      class="text-sm text-on-background-variant font-semibold text-center py-4"
    >
      {{ $t('footer.powered-by') }}
      <span class="text-on-background">Exfluential</span>
      <br />
      <span>support@exfluential.com</span>
    </div>
  </footer>
</template>

<script setup lang="ts">
import { useOrganizationStore } from '~/store/organization';

const { organization } = storeToRefs(useOrganizationStore());
</script>
