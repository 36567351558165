<template>
  <table
    class="data-table w-full"
    :class="[{ zebra: true }, { scroll: enableHorizontalScroll }]"
  >
    <thead>
      <tr>
        <th
          v-for="(column, columnIndex) in columns"
          :key="columnIndex"
          class="p-4 text-[#6b7280] font-medium text-sm text-left"
          :style="column.width ? { width: column.width } : {}"
        >
          {{ column.headerName }}
        </th>
        <th class="w-10 p-4"></th>
      </tr>
    </thead>
    <tbody>
      <tr v-if="isLoading">
        <td :colspan="columns.length + 1" class="py-8 text-center">
          <slot name="loading">
            <CircularSpinner />
          </slot>
        </td>
      </tr>
      <tr v-else-if="rows.length === 0" class="text-center">
        <td :colspan="columns.length + 1" class="py-8">
          <slot name="empty"> No data available </slot>
        </td>
      </tr>
      <template v-else>
        <template v-for="(row, index) in rows" :key="getRowId(row)">
          <tr
            class="transition-all cursor-pointer"
            :class="index % 2 === 0 ? 'bg-[#f8f9fa]' : 'bg-white'"
            @click="toggleRowExpansion(row)"
          >
            <td
              v-for="(column, columnIndex) in columns"
              :key="columnIndex"
              class="p-4 text-[#1f2937]"
            >
              <slot :name="`row:${column.rowKey}`" :row="row" :column="column">
                {{ getRowValue(row, column.rowKey) }}
              </slot>
            </td>
            <td class="w-10 p-4 text-center text-[#6b7280]">
              <Icon
                :icon="ChevronDownIcon"
                class="h-5 w-5 inline-block transition-transform"
                :class="{ 'rotate-180': isRowExpanded(row) }"
              />
            </td>
          </tr>

          <template v-if="isRowExpanded(row)">
            <slot
              name="expanded-rows"
              :row="row"
              :zebra-class="index % 2 === 0 ? 'bg-[#f8f9fa]' : 'bg-white'"
            ></slot>
          </template>
        </template>
      </template>
    </tbody>
  </table>
</template>

<script setup lang="ts">
import { ref, watch } from 'vue';
import { CircularSpinner } from '~/components/loaders';
import ChevronDownIcon from '@/assets/icons/chevron-down.svg?component';
import { Icon } from '~/components/icon';

type Column = {
  headerName: string;
  rowKey: string;
  width?: string;
};

type Props = {
  rows: any[];
  columns: Column[];
  isLoading?: boolean;
  rowIdField?: string;
  expandedRows?: (string | number)[];
  enableHorizontalScroll?: boolean;
};

const props = withDefaults(defineProps<Props>(), {
  isLoading: false,
  rowIdField: 'id',
  expandedRows: () => [],
  enableHorizontalScroll: false,
});

const emit = defineEmits([
  'update:expanded-rows',
  'row-expanded',
  'row-collapsed',
]);

const localExpandedRows = ref<(string | number)[]>([...props.expandedRows]);

watch(
  () => props.expandedRows,
  (newVal) => {
    localExpandedRows.value = [...newVal];
  },
  { deep: true },
);

const getRowId = (row: any): string | number => {
  return row[props.rowIdField];
};

const getRowValue = (row: any, key: string) => {
  if (!key) return;

  const keys = key.split('.');
  let result = row;

  for (const k of keys) {
    if (result === null || result === undefined) {
      return;
    }
    result = result[k];
  }

  return result;
};

const isRowExpanded = (row: any) => {
  return localExpandedRows.value.includes(getRowId(row));
};

const toggleRowExpansion = (row: any) => {
  const rowId = getRowId(row);
  const index = localExpandedRows.value.indexOf(rowId);

  if (index === -1) {
    localExpandedRows.value.push(rowId);
    emit('update:expanded-rows', localExpandedRows.value);
    emit('row-expanded', row);
  } else {
    localExpandedRows.value.splice(index, 1);
    emit('update:expanded-rows', localExpandedRows.value);
    emit('row-collapsed', row);
  }
};
</script>

<style>
.data-table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
  font-size: 15px;
  background-color: white;
  border-radius: 0.75rem;
  table-layout: fixed;
}

.data-table thead tr {
  height: 48px;
  font-size: 15px;
  color: #4b5563;
  font-weight: 600;
  background-color: #f5f5f7;
  border-top-left-radius: 0.75rem;
  border-top-right-radius: 0.75rem;
}

.data-table thead th:first-child {
  border-top-left-radius: 0.75rem;
}

.data-table thead th:last-child {
  border-top-right-radius: 0.75rem;
}

.data-table td,
.data-table th {
  padding: 18px 16px;
  height: auto;
  text-align: left;
  vertical-align: top;
}

.data-table tbody tr {
  height: 70px;
  border-bottom: 1px solid #e5e7eb;
}

.data-table.zebra tbody tr:nth-child(odd) {
  background-color: #f8f9fa;
}

.data-table.zebra tbody tr:nth-child(even) {
  background-color: #ffffff;
}

.data-table tbody tr:hover {
  background-color: #eef2ff !important;
}

.data-table.scroll {
  display: block;
  white-space: nowrap;
  overflow-x: auto;
}

@media (max-width: 767px) {
  .data-table.scroll {
    display: block;
    overflow-x: auto;
    white-space: nowrap;
    -webkit-overflow-scrolling: touch;
  }

  .data-table td,
  .data-table th {
    padding: 12px 10px;
  }

  .data-table tbody tr {
    height: auto;
  }
}
</style>
