import { defineStore } from 'pinia';
import { ref, watch } from 'vue';
import {
  BusinessResponseDto,
  getBusinessList,
  selectActiveBusiness,
} from '~/api/businesses';
import { useAccountStore } from './account';

export const useBusinessesStore = defineStore('businesses', () => {
  const accountsStore = useAccountStore();
  const { currentAccount } = storeToRefs(accountsStore);

  const selectedBusiness = computed<BusinessResponseDto | null>(
    () => currentAccount.value?.currentlySelectedBusiness ?? null,
  );

  const nonNullableSelectedBusiness = computed<BusinessResponseDto>(() => {
    if (selectedBusiness.value === null) {
      throw new Error('Selected business must not be null');
    }
    return selectedBusiness.value;
  });

  const selectBusiness = async (businessId: string | number) => {
    await selectActiveBusiness(businessId);
    await accountsStore.refreshCurrentAccount();
  };

  const businessesCount = ref(-1);

  watch(currentAccount, (newVal) => {
    if (newVal === null) {
      businessesCount.value = -1;
    }
  });

  const initialize = async () => {
    if (businessesCount.value >= 0) {
      return;
    }

    const { nodes, totalCount } = await getBusinessList({ perPage: 1 });
    businessesCount.value = totalCount;

    const firstBusiness = nodes.at(0);

    if (totalCount === 1 && !selectedBusiness.value && firstBusiness) {
      await selectBusiness(firstBusiness.id);
    }
  };

  const isOnboardingEnforced = computed<boolean>(() => {
    if (!selectedBusiness.value) {
      return false;
    }

    if (!selectedBusiness.value.coOpOnboarding) {
      return true;
    }

    return false;
  });

  return {
    businessesCount,
    selectedBusiness,
    nonNullableSelectedBusiness,
    initialize,
    selectBusiness,
    isOnboardingEnforced,
  };
});
