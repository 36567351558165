import { defineStore } from 'pinia';
import { Ref, ref } from 'vue';
import { Nullable } from '~/types/common';

export type ErrorState = Record<string, string>;
export const useVariablesModalStore = defineStore('variables-modal', () => {
  const isActive = ref(false);
  const submitCallback = ref<Nullable<() => Promise<void> | void>>(null);
  const variablesWithError: Ref<ErrorState> = ref({});

  const activateVariablesModal = (variables: ErrorState) => {
    variablesWithError.value = variables;
    isActive.value = true;
  };

  const closeVariablesModal = () => {
    isActive.value = false;
  };

  const setSubmitCallback = (callback: () => Promise<void> | void) => {
    submitCallback.value = callback;
  };

  const onSubmit = async () => {
    if (!submitCallback.value) {
      return;
    }

    await submitCallback.value();
    submitCallback.value = null;
  };

  return {
    isActive,
    activateVariablesModal,
    onSubmit,
    setSubmitCallback,
    closeVariablesModal,
    variablesWithError,
  };
});
