<template>
  <div>
    <slot
      :is-error="errorMessage !== undefined"
      :validation-listeners="validationListeners"
    />
    <div v-if="!props.hideDetails" class="details-area">
      {{ props.error || errorMessage }}
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, watch } from '#imports';
import { useField } from 'vee-validate';
import { getUid } from '~/utils/common';

const props = defineProps({
  hideDetails: Boolean,
  rules: null,
  inputValue: null,
  error: String,
});

const {
  value: validationField,
  errorMessage,
  handleBlur,
  validate,
} = useField(
  getUid(),
  computed(() => props.rules),
  { initialValue: props.inputValue, validateOnValueUpdate: false },
);

const validationListeners = {
  blur(event: Event) {
    handleBlur(event, true);
  },
};

watch(
  () => props.inputValue,
  (newVal) => {
    validationField.value = newVal;
    if (errorMessage.value) {
      validate();
    }
  },
);
</script>

<style scoped>
.details-area {
  @apply text-xs leading-none mt-1 text-error font-semibold ml-3;
  min-height: 0.75rem;
}
</style>
