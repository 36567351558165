import { useOrganizationStore } from '~/store/organization';
import { useHubStore } from '~/store/hub';
import { useRuntimeConfig } from '#imports';
import { useAuthUserStore } from '~/store/auth-user';
import { useAccountStore } from '~/store/account';
import { getErrorStatus } from '~/api';

export default defineNuxtRouteMiddleware(async (to) => {
  const error = useError();
  const config = useRuntimeConfig();

  if (config.public.isMaintenanceEnabled) {
    return;
  }

  const authUserStore = useAuthUserStore();
  const { isLoggedIn } = storeToRefs(authUserStore);
  const organizationStore = useOrganizationStore();
  const { organization } = storeToRefs(organizationStore);
  const accountStore = useAccountStore();
  const hubStore = useHubStore();
  const { hub } = storeToRefs(hubStore);

  const organizationSlug = to.params.organizationslug as string | undefined;

  const hubSlug = to.params.hubslug as string | undefined;

  await Promise.all([
    authUserStore.initializeAuthUser(),
    organizationStore.initializeOrganization(organizationSlug),
  ]);

  if (error.value) {
    return;
  }

  const promises: Promise<void>[] = [hubStore.initializeHub(hubSlug)];

  if (isLoggedIn.value && organization.value !== null) {
    promises.push(accountStore.setCurrentAccount());
  }

  const [hubResult] = await Promise.allSettled(promises);

  if (hubResult.status === 'rejected') {
    if (getErrorStatus(hubResult.reason) === 403) {
      const redirect =
        to.path === '/auth/login'
          ? undefined
          : `/auth/login?redirect=${encodeURIComponent(to.fullPath)}`;

      return navigateTo(redirect);
    }
    throw hubResult.reason;
  }
});
