import { useLoaderStore } from '~/store/loader';

export const useLoader = async ({
  action,
  onError,
}: {
  action: () => unknown;
  onError?: (err: unknown) => unknown;
}) => {
  const loader = useLoaderStore();
  try {
    loader.set(true);
    return await action();
  } catch (err: unknown) {
    if (onError) {
      onError(err);
    } else {
      throw err;
    }
  } finally {
    loader.set(false);
  }
};
